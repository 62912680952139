import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    CTA,
    FocusLayer,
    FocusLayerSize,
    Infobox,
    InfoboxAppearance,
    LayerWrapper,
    ShimLayer,
    styled,
    Text,
    TextAppearance,
    TextColor,
    TextTag
} from "@volkswagen-onehub/components-core";
import Dropdown from "../Dropdown/Dropdown";
import {API, Language, LocalStorage} from "../../constants/PRNConstants";
import React, {useState} from "react";
import history from '../../history';

require('./SelectLoginLanguagePopup.css');

export const LayerZWrapper = styled.div`
  & > div {
    z-index: 99;
  }
`;

const SelectLoginLanguagePopup = ()=>{
    const[selectedLanguage,setSelectedLanguage] = useState(null);
    const[infoBarText,setInfoBarText] = useState("Please select at least one preferred language.");

    const handleDropdownChange=(data)=>{
        setSaveButtonState(false);
        setSelectedLanguage({label: data.label, value: data.label});
    }
    const onClickSave = ()=>{
      fetch(API.USER_SERVICE_ENDPOINT + `/api/prn-user/user/updateFEUserData/` + selectedLanguage.label.toLowerCase(),{
        method:'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res)=>{
        if(res.ok){
          res.json().then(data=>{
            setInfoBoxTag(InfoboxAppearance.Success);
            setSaveButtonState(true)
            setcloseButtonState(false);
            setInfoBarText("Your preferred language has been changed.");
            localStorage.setItem(LocalStorage.SELECTED_LANGUAGE, data.preferredLang);
          })

        }
      })
  };

    const onClickClose = () =>{
        localStorage.removeItem(LocalStorage.IS_LOGIN_LANG_NEEDED);
        history.push('/home');
    }
    const [closeButtonState,setcloseButtonState] = useState(true);
    const [saveButtonState,setSaveButtonState] = useState(true);
    const [infoBoxTag,setInfoBoxTag]= useState();

    return(
        <LayerZWrapper>
            <LayerWrapper contentCentered>
                {[
                    <ShimLayer key="shim" />,
                    <FocusLayer active size={FocusLayerSize.NONE}>
                        <Container gutter = {ContainerGutter.dynamic0100}
                                   padding={{top: ContainerPadding.dynamic0100, left: ContainerPadding.dynamic0150, right: ContainerPadding.dynamic0150}}
                                   wrap={ContainerWrap.always}>
                            <Infobox fullWidth appearance= {infoBoxTag}>
                                <Text tag = {TextTag.p}>
                                        {infoBarText}
                                </Text>
                            </Infobox>
                            <Text bold appearance={TextAppearance.headline350}>
                                Select Language
                            </Text>
                            <Text bold>
                                *Select your preferred language
                            </Text>
                                <div className={"language-edit-version"}>
                                    <Dropdown
                                        placeholder="Language"
                                        Model={Language}
                                        label={"Language" + "*"}
                                        labelBold={true}
                                        labelAppearance={TextAppearance.copy150}
                                        labelColor={TextColor.inherit}
                                        handleDropdownChange={(data)=>{
                                            handleDropdownChange(data);
                                        }}
                                        dropdownValue={selectedLanguage}
                                        a={2}
                                    />
                                </div>


                            <Container wrap={ContainerWrap.never} gutter={ContainerGutter.dynamic0150}
                                       padding={{top: ContainerPadding.dynamic0100, bottom: ContainerPadding.dynamic0350}}>
                                <CTA tag="button" emphasis="primary" disabled={saveButtonState} onClick={onClickSave}>Save</CTA>
                                <CTA tag="button" emphasis="secondary" disabled={closeButtonState} onClick={onClickClose}>
                                    Close
                                </CTA>
                            </Container>
                        </Container>
                    </FocusLayer>
                ]}
            </LayerWrapper>
        </LayerZWrapper>
    );
}
export default SelectLoginLanguagePopup;