import {LOCALES} from '../locales'

export default {
    [LOCALES.ENGLISH] : {
        'LOGOUT' : 'Logout',
        'LOGIN' : 'Login',
        "ALLPRNUMBERS" : 'All PR numbers',
        "SEARCH": "Search PR number and title...",
        "SHOW":"Show",
        "ENTRIES": "entries",
        "SORT": "Sort",
        "RECENTLYADDED": "Recently added PR numbers",
        "CATALOGS": 'Catalogs',
        'DOWNLOADOPTIONS' : 'Download Options',
        "CUSTOMCATALOG": 'Customized catalogs created by you',
        "COOKIEPOLICY": 'Cookie guidelines',
        "MODEL": 'Model',
        "MODELYEAR": 'Model Year',
        "CATEGORY": 'Category',
        "AUFUND": "Superstructures and modifications",
        "TERMSOFUSE" : 'Terms of Use',
        "PRIVACYPOLICY": "Privacy Policy",
        "SITENOTICE":"Site notice",
        "THIRDPARTY":"Third-party licences",
        "SPRACHE": "Language",
        "PREVIEW": "Preview",
        "FNF": "Function and Features",
        "ADVANTAGES": "Advantages",
        "NOTE": "Note",
        "STARTNEWPRN": 'Start / New PR number',
        "PRNUMBER": 'PR number',
        "MBVTEXT": 'MBV Text',
        "TITLE": 'Title',
        'TARGETGROUP': "Target group",
        "ENTWURF": "Draft",
        "IN_FREIGABE_DE": "Being released DE",
        "INUBERSETZUNG": "Translation in progress",
        "UBERSETZT": "Translated",
        "VEROFFENLICHT": "Published",
        "ARCHIVIERT": "Archived",
        "SPEICHERN": "Save",
        "GENINFO": "General information",
        "PREVIOUS": "Previous",
        "ADDDESC": "Add description",
        "SUMMARY": "Summary",
        "HOMEPAGE":"Homepage",
        "PRLIST": "PR Number List",
        "ADMINISTRATION": "Administration",
        "AUTH":"Authorisations",
        "CHANGEMODELYEAR": "Change Model Year",
        "BACK": "Back",
        "CLOSEMENU":"Close Menu",
        "DESCRIPTION": "Description",
        "ADDIMAGES": "Add Images",
        "REMOVEIMAGE": "Remove Image",
        "SHOWALL": "Show All",
        "CLICKON": "Click on",
        "BUTTONTOCREATE": "button to create new PR Number",
        "NOTIFICATIONS": "Notifications",
        "QUICKLINKS": "Quick Links",
        "ADMINROLE": "Administration role",
        "MEDIALIBRARY": "Media Library",
        "CLICKONMODELYEAR":"• Click on change model year below \n• Select the model you want to make changes to \n• Change the existing model year to the desired",
        "ASSIGNSOURCES": "• Assign resources to the different roles and carlines \n• Access make changes & delete the existing roles by clicking",
        "ADDEDIT": "• Add edit delete and name images and videos which later can be added to a PR number",
        "RECEIVEDNEWPRN": "You have recieved new PR Numbers by: ",
        "SAVEFORMYSELF": "Save for myself",
        "REJECT": "Reject",
        "SENDDEAPP": "Send DE for Approval",
        "SENDDETRANS": "Send DE for Translations",
        "SENDTRANSAPP": "Send Translations for Approval",
        "RELEASE": "Release",
        "RESETDRAFT": "Reset to draft",
        "DOWLOADZIPFILE": "Download Zip File for translation",
        "UPLOADTRANSLATEDFILES": "Upload translated files",
        "DRAGANDDROP": "your translated files here",
        "VIEWPDF": "View as PDF",
        "ASSIGNROLE": "Assign Role",
        "INSTRUCTIONS": "Product line managers can create PR numbers themselves and release German versions of PR numbers.",
        "ROLE": "Role",
        "USER": "User",
        "PRODUCTLINERESPONSIBLE": "Product Line Responsible",
        "KEYUSER": "Key User",
        "EDITOR": "Editor",
        "TRANSLATOR": "Translator",
        "USEROVERVIEW": "Overview of User roles",
        "ADDUSER": "Add User",
        "DISCARD": "Discard",
        "DOWNLOADSELECTEDCATALOG": "Select language(s) to download",
        "DOWNLOADCATALOG": "Download Selected",
        "SENDREQUEST": "Send Request",
        "REQUESTMODELYEARCHANGE": "Request model year change",
        "RELATEDMBVDATA": "This model year change request is specific to MBV data",
        "I_ACCEPT_THE": "I accept the",
        "AND": "and",
        "AGREE_AND_CONTINUE": "Agree and continue",
        "We are here to help you": "We are here to help you",
        "HEADERTITLE": "Commercial Vehicles",
        "CONTACTUS": "Contact Us",
        "COMPLETECATALOG": "Complete Catalog",
        "CONVERSIONCATALOG": "Conversion",
        "PRNO": "PR-No.",
        "within 24 hours": "within 24 hours",
        "1 week": "1 week",
        "1 month": "1 month",
        "1 year": "1 year",
        "DATE": "Date",
        "BUILDANDCONV": "Construction and Conversion",
        "ENTRIES_PER_PAGE": "Entries per page",
        "DOWNLOAD_SELECTED": "Download Selected",
        "COMPLETE_CATALOG": "Complete Catalog",
        "CATALOGUE_NOT_CREATED": "No catalog created for {model_name} in {language}",
        "CONSTRUCTION_CONVERSION_NOT_CREATED":"No catalog available for special bodies and conversions",
        "REQ_NOT_PROCESSED": "The request could not be carried out due to an error",
        "DISCLAIMER": "This software product contains software components that are subject to open source licenses.\n" +
            "              Listed below, you will find the open source software components that are used together with the copyright\n" +
            "              notices, the applicable licenses and the original license texts, which may include warranty disclaimers\n" +
            "              and an exclusion of liability (third party license documentation). The source code and other required\n" +
            "              materials for particular open source software components incorporated in this software product may be\n" +
            "              obtained from the software supplier.\n" +
            "              The software supplier will provide you with the respective source code and materials for a charge covering\n" +
            "              the cost of performing such distribution (such as the cost of media, and shipping) in accordance with the\n" +
            "              terms and conditions of the applicable licenses. You will receive more information from the\n" +
            "              [PRN-Wiki: VWAG R: HAN, PR-NR Wiki pr-nr.wiki.vwag.r.han@volkswagen.de] provided above.",
        "NO_PAGE_FOUND" : "No Page Found",
        "NOT_AUTHORIZED": "You are not authorized to access this application",
    }
}
