import React, {Component} from "react";
import Select from "react-select";
import {ChevronDown,} from "@volkswagen-onehub/icons-core";
import {Text, TextAppearance} from "@volkswagen-onehub/components-core";

require("./FilterSelectOption.css");

export class FilterSelectOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      selectedOption : ''
    };
  }

  handleChangeInput = (e) => {
    const name = this.props.value;
    this.props.handleDropdownChange(e.value, name) 
  }

  toggleMenu() {
    if (!this.state.showMenu) {
      this.filterOption.onMenuOpen();
    } else this.filterOption.onMenuClose();

    this.setState({
      showMenu: !this.state.showMenu,
    });
  }

  render() {
    const optionValue = {label : this.props.filterValue, value: this.props.filterValue} 
    return (
      <span onClick={() => this.toggleMenu()} className={"dropdown-label-select "+ this.props.value}>
        <Text appearance={TextAppearance.label0150}> {this.props.label} <ChevronDown variant="small"/></Text>
        <Select
          id={this.props.label === "Category"? "category-fiter" : "filter-prn"}
          value={optionValue}
          ref={(filterOption) => (this.filterOption = filterOption)}
          options={this.props.options?this.props.options:undefined}
          onChange={(e) => this.handleChangeInput(e)}
        />
      </span>
    );
  }
}

export default FilterSelectOption;
