import { Component } from "react";
import {DownloadFile} from "../Helper/Helper";
import { CTA } from "@volkswagen-onehub/components-core";
import { API, LocalStorage, Language } from "../../constants/PRNConstants";
import { Download } from "@volkswagen-onehub/icons-core";

class DownloadPdfButton extends Component
{
    renderPDF(
        prNumber,
        model,
        modelYear,
        category,
        title,
        languageVersion,
        functions,
        advantages,
        notes,
        prnImages
      ) {
        this.setState(
          {
            isViewPdfLoading: true,
          },
          () => {
            const prnReport = {
              prNumber: prNumber,
              model: model,
              modelYear: modelYear,
              category: category,
              title: title,
              languageVersion: languageVersion,
              functions: functions,
              advantages: advantages,
              notes: notes,
              prnImages: prnImages,
            };
            fetch(API.IMAGE_SERVICE_ENDPOINT + `/api/prn-wiki-image/pdf-generate`+ `?dealerAppFlag=`+true, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(prnReport),
            })
              .then((response) => {
                if (response.ok) {
                  DownloadFile(
                    response,
                    "Report_" + prNumber + "_" + model + "_" + modelYear + ".pdf",
                    "application/pdf"
                  );
                }
              })
              .finally(() => {
                this.setState({
                  isViewPdfLoading: false,
                });
              });
          }
        );
      }
      getPRNDetailsandDownloadPDF(prNumber, modelName, modelYear) {
        fetch(
          API.EDITOR_SERVICE_ENDPOINT +
            `/api/prn-editor/pr-number/published/${prNumber}/${modelName}/${modelYear}`
        )
          .then((res) => res.json())
          .then((prn) =>
            this.renderPDF(
              prn.prNumber,
              prn.modelName,
              prn.modelYear,
              prn.category,
              prn.title,
              this.getLanguageVersion(),
              prn.funtionsAndFeatures,
              prn.advantages,
              prn.notes,
              prn.prnImages
            )
          );
      }
      getLanguageVersion() {
        const val = Language.filter((l) => {
          return l.label
            .toLocaleUpperCase()
            .includes(
              localStorage.getItem(LocalStorage.SELECTED_LANGUAGE).toUpperCase()
            );
        });
        return val[0].value;
      }


    render()
    {
        return(
            <CTA
            tag="a"
            href="javascript:void(0)"
            emphasis="secondary"
            onClick={(event) =>{
              event.stopPropagation();
              this.getPRNDetailsandDownloadPDF(
                this.props.prNumber,
                this.props.modelName,
                this.props.modelYear
              )
            }
            }
            ariaLabel="aria label"
            icon={<Download variant="small" />}
          />
        );

    }

}

export default DownloadPdfButton;