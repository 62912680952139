import React from "react";
import {ContextDevTool} from 'react-context-devtool';
import {Language, LocalStorage} from '../constants/PRNConstants'

const { Provider, Consumer } = React.createContext(
);

class ApplicationContextProvider extends React.Component {

  setUser = value => {
    this.setState({ user: value });
  };

  setPrnImages = value => {
    this.setState({ prnImages: value });
  };

  setPRNumber = value => {
    this.setState({ prNumber: value });
  };

  setTitle = value => {
    this.setState({ title: value });
  };

  setDescription = value => {
    this.setState({ description: value });
  };

  setModel = value => {
    this.setState({ model: value });
  };

  setModelYear = value => {
    this.setState({ modelYear: value });
  };

  setLanguageVersion = value => {
    this.setState({ languageVersion: value })
  }

  setContentHtml = value => {
    this.setState({ contentHtml: value });
  }

  setCategory = (value, languageVersion) => {
    this.setState(
      { category: { ...this.state.category, [languageVersion]: value } });
  };

  setCheckbox = value => {
    this.setState({
      equipmentcheckbox: value
    })
  };

  setButtonStatus = value => {
    this.setState({
      buttonStatus: value
    })
  };

  setContextAPI = (response) => {
    this.setState({
      prNumber: response.prNumber,
      title: response.title,
      prnImages: response.prnImages,
      description: response.description,
      equipmentcheckbox: response.abh,
      model: response.modelName,
      modelYear: response.modelYear,
      category: response.category,
      advantages: response.advantages,
      functions: response.funtionsAndFeatures,
      notes: response.notes,
      dropdownIsDisabled: true,
      status: response.status,
      targetGroup: response.targetGroup,
      approvalStatus: response.approvalStatus,
      isContentEditable: false
    })
  }

  setUserContext = (response) => {
    this.setState({
      userName: response.name,
      userId: response.userId
    })
  }
  setTargetGroup = (values, languageVersion) => {
    let targetGroup = [...this.state.targetGroup];
    let targetGroupUpdated = [];
    values.forEach(element => {
      targetGroupUpdated.push({ [languageVersion]: element.value })
    });

    targetGroup = targetGroupUpdated;
    this.setState({ targetGroup });
  };

  setModelForUser = (values) => {
    let modelForUser = [...this.state.modelForUser];
    let modelForUserUpdated = [];
    if (values) {
      values.forEach(element => {
        modelForUserUpdated.push(element.value)
      });
    }
    modelForUser = modelForUserUpdated;
    this.setState({ modelForUser });

  };

  setRole = value => {
    this.setState({
      role: value
    })
  };

  getLanguageValue() {
    return Language.find(languageValue => (languageValue.label === (localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)?localStorage.getItem(LocalStorage.SELECTED_LANGUAGE).toUpperCase():'EN'))).value;
  }

  setAdvantages = (value, languageVersion) => {
    this.setState({ advantages: { ...this.state.advantages, [languageVersion]: value } });
  };

  setFunctions = (value, languageVersion) => {
    this.setState({ functions: { ...this.state.functions, [languageVersion]: value } });
  };

  setNote = (value, languageVersion) => {
    this.setState({ notes: { ...this.state.notes, [languageVersion]: value } });
  };

  setPageNumber = value => {
    this.setState({ pageNumber: value });
  };

  setTotalResults = value => {
    this.setTotalResults({ totalResults: value });
  };

  setStatus = value => {
    this.setStatus({ status: value });
  };

  setSavedPRNDraft = value => {
    this.setState({ savedPRNDraft: value });
  };

  setTitle = (value, languageVersion) => {
    this.setState({ title: { ...this.state.title, [languageVersion]: value } });
  };

  setUserName = (value) => {
    this.setState({ userName: value });
  };

  setUserId = (value) => {
    this.setState({ userId: value });
  };

  setApprovalStatus = (value) => {
    this.setState({ approvalStatus: value });
  };

  setComment = (value) => {
    this.setState({ comment: value });
  };

  setAppLanguage = (value) => {
    this.setState({
          appLanguage : value
        }
    )
  }

  state = {
    setUser: this.setUser,
    setButtonStatus: this.setButtonStatus,
    setModelForUser: this.setModelForUser,
    setRole: this.setRole,
    setTitle: this.setTitle,
    setPRNumber: this.setPRNumber,
    title: {
      germanVersion: '',
      frenchVersion: '',
      italianVersion: '',
      spanishVersion: '',
      portugeseVersion: '',
      englishVersion: ''
    },
    setDescription: this.setDescription,
    setModel: this.setModel,
    setModelYear: this.setModelYear,
    setContentHtml: this.setContentHtml,
    setCategory: this.setCategory,
    setPrnImages: this.setPrnImages,
    setCheckbox: this.setCheckbox,
    setTargetGroup: this.setTargetGroup,
    setAdvantages: this.setAdvantages,
    setFunctions: this.setFunctions,
    setNote: this.setNote,
    setPageNumber: this.setPageNumber,
    setTotalResults: this.setTotalResults,
    setContextAPI: this.setContextAPI,
    setUserContext: this.setUserContext,
    // savePRNData: this.savePRNData,
    setStatus: this.setStatus,
    setLanguageVersion: this.setLanguageVersion,
    setSavedPRNDraft: this.setSavedPRNDraft,
    setUserName: this.setUserName,
    setUserId: this.setUserId,
    setApprovalStatus: this.setApprovalStatus,
    setComment: this.setComment,
    setAppLanguage: this.setAppLanguage,
    category: {
      germanVersion: '',
      frenchVersion: '',
      italianVersion: '',
      spanishVersion: '',
      portugeseVersion: '',
      englishVersion: ''
    },
    advantages: {
      germanVersion: '',
      frenchVersion: '',
      italianVersion: '',
      spanishVersion: '',
      portugeseVersion: '',
      englishVersion: ''
    },
    functions: {
      germanVersion: '',
      frenchVersion: '',
      italianVersion: '',
      spanishVersion: '',
      portugeseVersion: '',
      englishVersion: ''
    },
    notes: {
      germanVersion: '',
      frenchVersion: '',
      italianVersion: '',
      spanishVersion: '',
      portugeseVersion: '',
      englishVersion: ''
    },
    targetGroup: [],
    equipmentcheckbox: false,
    model: '',
    modelYear: '',
    contentHtml: '',
    languageVersion: this.getLanguageValue(),
    // 'germanVersion',
    dropdownIsDisabled: false,
    isApplicableForTranslations: false,
    status: 'ENTWURF',
    savedPRNDraft: false,
    buttonStatus: false,
    modelForUser: [],
    userName: '',
    userId: '',
    approvalStatus: '',
    comment: '',
    isRetrievedPRN: false,
    isContentEditable: true,
    pageNumber: 1,
    appLanguage: ''
  };

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
        <ContextDevTool context={{ Provider, Consumer }} />
      </Provider>
    );
  }
}

export { ApplicationContextProvider, Consumer as ApplicationContextConsumer };
