import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Text,
    TextAppearance
} from "@volkswagen-onehub/components-core";
import React, {Component} from "react";
import translate from "../../i18n/translate";
import Catalog from "./Catalog";

export class CatalogPage extends Component {

    render() {
        return (
            <Container wrap={ContainerWrap.always} gutter={ContainerGutter.dynamic0100} padding={{bottom: ContainerPadding.dynamic0150}}>
                <Container padding={{left: ContainerPadding.dynamic0150, right: ContainerPadding.dynamic0150}}>
                    <Text bold appearance={TextAppearance.headline0400}>{translate("CATALOGS")}</Text>
                </Container>
                <Container padding={{left: ContainerPadding.dynamic0150, right: ContainerPadding.dynamic0150}}>
                    <Catalog isHomePage={false}/>
                </Container>
            </Container>
        );
    }
}

export default CatalogPage;
