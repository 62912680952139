import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ChevronLeft, ChevronRight} from '@volkswagen-onehub/icons-core'
import {
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerVerticalAlignment,
    CTA,
    Select,
    Text,
    TextAlignment,
    TextAppearance
} from '@volkswagen-onehub/components-core'
import {FormattedMessage} from 'react-intl';

require('./Pagination.css')

class Pagination extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paginationBracket: 4,
      hideAfter: 6,
      minPageNumber: 1,
      pageSize: props.defaultPageSize
    }
    this.switchPage = this.switchPage.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.changeTotalResults = this.changeTotalResults.bind(this)
  }

  changeTotalResults(e) {
    const totalResultsToShow = parseInt(e.target.value);
    this.setState({
      pageSize: totalResultsToShow
    });
    if (totalResultsToShow > 0) {
      this.props.changeTotalResults(totalResultsToShow)
    }
  }

  prevPage(e) {
    e.preventDefault()
    const current = parseInt(this.props.currentPage);
    if (this.state.minPageNumber < current) {
      this.props.changePage((current - 1))
    }
  }

  nextPage(e) {
    e.preventDefault()
    const current = parseInt(this.props.currentPage);
    const totalPages = this.getTotalPages();
    if (current < totalPages) {
      this.props.changePage((current + 1))
    }
  }

  getTotalPages(){
    return Math.ceil(this.props.totalCount/this.state.pageSize);
  }

  switchPage(e) {
    e.preventDefault()
    const pageNumber = parseInt(e.currentTarget.dataset.page);
    if (pageNumber === parseInt(this.props.currentPage)) {
      return
    }
    this.props.changePage(pageNumber)
    this.updateUrl(pageNumber)
  }
  updateUrl(currentPage) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('currentPage', currentPage)

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }
  generatePageNumbers() {
    const pages = [];
    const totalPages = this.getTotalPages();
    const currentPage = parseInt(this.props.currentPage)
    let addEllipsis = false;
    for (let idx = this.state.minPageNumber; idx <= totalPages; idx++) {
      if (idx === this.state.minPageNumber || ((idx >= currentPage - 2) && (idx <= currentPage + 2)) || idx === totalPages) {
        pages.push(
            <Text textAlign={TextAlignment.center}><div className={idx === currentPage?"selected-page-button":"not-selected-page-button"} onClick={this.switchPage} data-page={idx}>{idx}</div></Text>
        )
        addEllipsis = true
      } else if (addEllipsis === true) {
        pages.push(<span>...</span>)
        addEllipsis = false
      }
    }
    return pages
  }

  generatePagination() {
    const pagination = [];
    if (this.props.totalCount > 0) {
      pagination.push(<CTA emphasis="none" tag="a" onClick={this.prevPage} ><ChevronLeft variant="small"/></CTA>)
      pagination.push(this.generatePageNumbers())
      pagination.push(<CTA emphasis="none" tag="a" onClick={this.nextPage} ><ChevronRight variant="small"/></CTA>)
    }
    return pagination;
  }

  generateDropdown() {
    const dropdown = [];
    this.props.pageSizes.forEach((size)=>{
      dropdown.push(
          <option value={size}>{size}</option>
      )
    });
    return dropdown;
  }

  render() {
    return (
        <Container stretchContent verticalAlign={ContainerVerticalAlignment.center}>
          <Container verticalAlign={ContainerVerticalAlignment.center} gutter={ContainerGutter.static200}>
            <FormattedMessage id="SHOW">
              {label =>
                  <Text appearance={TextAppearance.label0150}>{label + ':'}</Text>
              }
            </FormattedMessage>
            <Select
                defaultValue={this.state.pageSize}
                onChange={this.changeTotalResults}>
              {this.generateDropdown()}
            </Select>
            <FormattedMessage id="ENTRIES_PER_PAGE">
              {label =>
                  <Text appearance={TextAppearance.label0150}>{label}</Text>
              }
            </FormattedMessage>
          </Container>
          <Container verticalAlign={ContainerVerticalAlignment.center} horizontalAlign={ContainerHorizontalAlignment.flexEnd} gutter={ContainerGutter.static200}>
              {this.generatePagination()}
          </Container>
        </Container>
    )
  }
}

Pagination.defaultProps = {
  totalCount: 0,
  currentPage: 0
}

Pagination.PropsTypes = {
  defaultPageSize: PropTypes.number.isRequired,
  pageSizes: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  changeTotalResults: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired
}

export default Pagination
