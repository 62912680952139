import React, {Component} from 'react';
import './App.css';
import Header from './components/Header/Header';
import history from './history';
import PRNOverviewTable from './components/PRNOverviewTable/PRNOverviewTable';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import FooterComponent from './components/Footer/FooterComponent';
import CookiePolicy from './common_components/LegalLinksPage/CookiePolicy/CookiePolicy';
import DataProtectionStatement
    from './common_components/LegalLinksPage/DataProtectionStatement/DataProtectionStatement';
import Imprint from './common_components/LegalLinksPage/Imprint/Imprint';
import TermsOfUse from './common_components/LegalLinksPage/TermsOfUse/TermsOfUse';
import ThirdPartyLicenseNotice
    from './common_components/LegalLinksPage/ThirdPartyLicenseNotice/ThirdPartyLicenseNotice';
import Auth from "./components/Auth/Auth";
import CatalogPage from './components/Catalog/CatalogPage';
import Form from './components/Form/Form';
import HomePage from './components/HomePage/HomePage';
import LoginPage from "./components/LoginPage/LoginPage";
import {LoginSuccess} from "./components/LoginSuccess/LoginSuccess";
import {Container, ContainerPadding, ThemeProvider} from "@volkswagen-onehub/components-core";
import {unregister} from "./common_components/Interceptor/Interceptor";
import {I18nProvider} from './i18n'
import TermsAndConditions from "./common_components/TermsandConditions/TermsandConditions";
import {Logout} from "./components/Logout/Logout";
import {API, LocalStorage, UserAuthCookies} from "./constants/PRNConstants";
import {ApplicationContextConsumer} from "./contextProvider/ApplicationContextProvider";
import translate from "./i18n/translate";
import {
    getUserDetailsObjectFromCookie,
    removeUserDetailsFromCookie,
    setUserNameCookie
} from "./utils/UserDetailsAuthUtil";

require('./fonts.css');
//require('./App.css')

var user = {};

export const mainPagePaddingLeftRight = {
    left: ContainerPadding.dynamic0150,
    right: ContainerPadding.dynamic0150
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      Auth.isAuthenticated ? <
        Component
      /> : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location }
          }}
        />
      )
    }
  />
);

const LegalLinksPrivateRoute = ({ component: Component, appLanguage, ...rest }) => (
    <Route
        {...rest}
        render={({ location }) =>
            Auth.isAuthenticated ? <
                Component appLanguage={appLanguage}
            /> : (
                <Redirect
                    to={{
                        pathname: "/",
                        state: { from: location }
                    }}
                />
            )
        }
    />
);

const PrivateLogin = ({props, loggedInStatus, handleLogin, component: Component, ...rest}) => (
    <Route
        {...rest}
        render={() =>
            !(Auth.isAuthenticated) ? (<
                Component  {...props} loggedInStatus={loggedInStatus} handleLogin={handleLogin}
            />) : (<Redirect to="/home"/>)
        }
    />
);

const NoMatch = () => (
    <div className="create-prn-link-section">
        <h2>{translate("NO_PAGE_FOUND")}</h2>
    </div>
);

const Error = () => (
    <div className="create-prn-link-section">
        <h2>{translate("NOT_AUTHORIZED")}</h2>
    </div>

);

const RestrictedPage = () => (
    <div className="create-prn-link-section">
        <h2>{translate("NOT_AUTHORIZED")}</h2>
    </div>

);

const readCookie = () => {
    const userIsPresent = getUserDetailsObjectFromCookie(UserAuthCookies.USERNAME);
    if (userIsPresent) {
        Auth.authenticate();
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appLanguage: localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)?localStorage.getItem(LocalStorage.SELECTED_LANGUAGE):'en',
            isTcAccepted: localStorage.getItem(LocalStorage.IS_TC_ACCEPTED)
        }
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        if (!localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)) {
            localStorage.setItem(LocalStorage.SELECTED_LANGUAGE, 'en');
        }
        this.acceptTerms = this.acceptTerms.bind(this);
    }

    componentWillMount() {
        readCookie();
    }

    onChangeLanguage(language){
        this.setState({
            appLanguage: language
        });
    }

  handleLogin(data) {
    Auth.authenticate();
    setUserNameCookie(data.firstName);
    localStorage.setItem(LocalStorage.EMAIL, data.email);
    localStorage.setItem(LocalStorage.LOGGED_IN_STATUS, "LOGGED_IN");
    localStorage.setItem(LocalStorage.IS_TC_ACCEPTED, data.tcAccepted);
    localStorage.setItem(LocalStorage.SELECTED_LANGUAGE, data.preferredLang);
    user = data;
    this.setState({
        appLanguage: data.preferredLang,
      loggedInStatus: 'LOGGED_IN',
      user: data,
        isTcAccepted: data.tcAccepted
    });
  }


    handleLogout() {
        Auth.signout();
        removeUserDetailsFromCookie();
        localStorage.removeItem(LocalStorage.EMAIL);
        localStorage.removeItem(LocalStorage.LOGGED_IN_STATUS);
        localStorage.removeItem(LocalStorage.IS_TC_ACCEPTED);
        user = {};
        this.setState({
            loggedInStatus: 'NOT_LOGGED_IN'
            // user: {}
        });

    if (process.env.REACT_APP_CURR_ENV === 'qa' || process.env.REACT_APP_CURR_ENV === 'prod') {
        window.location.href = API.GRP_LOGOUT_URI;
    }
    else
      history.push('/logout');
  }

    acceptTerms(){
        localStorage.setItem(LocalStorage.IS_TC_ACCEPTED, true);
        this.setState({
            isTcAccepted: true
        });
    }

  render() {
    return (
      <ThemeProvider theme={"main"}>
        <I18nProvider locale={this.state.appLanguage}>
            <Router history={history}>
                <ApplicationContextConsumer>
                    {({setAppLanguage}) =>
                        <Header isLoggedIn={Auth.isAuthenticated} userName={user.name}
                                handleLogout={this.handleLogout} onLanguageChange={this.onChangeLanguage} appLanguage={this.state.appLanguage} setAppLanguage={setAppLanguage}/>

                    }
                </ApplicationContextConsumer>
                <TermsAndConditions language={this.state.appLanguage} acceptTerms={this.acceptTerms} isTcAccepted={this.state.isTcAccepted}/>
                <Container stretchContent padding={{top: ContainerPadding.dynamic0300}}>
                    <ApplicationContextConsumer>
                        {({appLanguage}) =>
                          <Switch>
                            <PrivateLogin
                              path='/'
                              exact
                              component={LoginPage}
                              loggedInStatus={localStorage.getItem(LocalStorage.LOGGED_IN_STATUS)} handleLogin={this.handleLogin}
                            />
                            <PrivateRoute path='/home' exact component={HomePage} />
                            <PrivateRoute exact path='/pr-numbers' component={PRNOverviewTable} />
                            <Route exact
                              path='/prn-form/:prNumber/:modelName/:modelYear'
                              component={Form}
                            />
                            <PrivateRoute exact path='/nutzungsbedingungen' component={TermsOfUse} />
                            <PrivateRoute exact path='/datenschutz' component={DataProtectionStatement} />
                            <PrivateRoute exact path='/cookie' component={CookiePolicy} />
                            <PrivateRoute exact path='/catalog' component={CatalogPage} />
                            <PrivateRoute exact path='/lizenzen-dritter' component={ThirdPartyLicenseNotice} />
                            <Route exact
                              path='/logout'
                              component={Logout}
                            />
                            <Route exact
                              path='/login-success'
                              component={LoginSuccess}
                              handleLogin={this.handleLogin}
                            />
                            <PrivateRoute exact
                              path='/error'
                              component={Error}
                            />
                            <PrivateRoute exact
                              path='/restricted'
                              component={RestrictedPage}
                            />
                            <LegalLinksPrivateRoute exact path='/impressum' component={Imprint} appLanguage={appLanguage}/>
                            <PrivateRoute component={NoMatch} />
                          </Switch>
                        }
                    </ApplicationContextConsumer>
                </Container>
                <Container stretchContent>
                    <FooterComponent isLoggedIn={Auth.isAuthenticated}/>
                </Container>
            </Router>
        </I18nProvider>
      </ThemeProvider>
    );
  }

    componentWillUnmount() {
        unregister();
    }
}

export default App;
