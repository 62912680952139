import React, {Component} from 'react'
import history from '../../history'
import {API, Language, LocalStorage, UserAuthCookies} from '../../constants/PRNConstants';
import Auth from "../Auth/Auth";
import {Redirect} from "react-router-dom";
import {getUserDetailsObjectFromCookie, setUserNameCookie} from "../../utils/UserDetailsAuthUtil";

export class LoginSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      idpRole: "",
      userId: ""
    };
  }

  handleSuccessfullAuth(data) {
    this.handleLogin(data);
    history.push('/home');
  }

  handleLogin(data) {
    Auth.authenticate();
    setUserNameCookie(data.firstName);
    localStorage.setItem(LocalStorage.EMAIL, data.email);
    localStorage.setItem(LocalStorage.LOGGED_IN_STATUS, "LOGGED_IN");
    localStorage.setItem(LocalStorage.IS_TC_ACCEPTED, data.tcAccepted);
    if(data.preferredLang && data.preferredLang != 'na'){
      localStorage.setItem(LocalStorage.SELECTED_LANGUAGE, data.preferredLang);
    }else{
      localStorage.setItem(LocalStorage.IS_LOGIN_LANG_NEEDED, "true");
    }
  }

  getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

    const results = regex.exec(this.props.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getUpdatedUserProfileData(userProfile) {
    fetch(API.USER_SERVICE_ENDPOINT + `/api/prn-user/user/updateFEUserData/` + userProfile.preferredLang +`?countryName=`+userProfile.countryName, {
          method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
        }
    ).then((res) => {
      if(res.ok){
        res.json().then(data=>{
          this.handleSuccessfullAuth(data);
        });
      }
    });
  }

  setAccessToken(userProfile) {
      this.getUpdatedUserProfileData(userProfile);
  }

  render() {
    const userProfile = {
      name: this.getUrlParameter('name'),
      email: this.getUrlParameter('email'),
      registrationId: this.getUrlParameter('registrationId'),
      authName: this.getUrlParameter('authName'),
      tcAccepted: false,
      preferredLang: this.getUrlParameter("preferredLang"),
      countryName: this.getUrlParameter("countryName"),
    };

    if (userProfile.name && userProfile.name !== '')
      this.setAccessToken(userProfile)
    else
      return <Redirect to={{
        pathname: "/error"
      }} />

    return <></>;
  }
}
export default LoginSuccess
