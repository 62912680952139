import {LOCALES} from '../locales'

export default {
    [LOCALES.PORTUGAL] : {
        'LOGOUT' : 'Terminar sesssão',
        "LOGIN": "Conecte-se",
        "ALLPRNUMBERS" : 'Todos os números PR',
        "SEARCH": "Pesquisar número PR e título...",
        "SHOW":"Anúncios",
        "ENTRIES": "registos",
        "SORT": "Classificar",
        "RECENTLYADDED": "Últimos números PR adicionados",
        "CATALOGS": 'Catálogos',
        'DOWNLOADOPTIONS' : 'Opções de download',
        "CUSTOMCATALOG": 'Catálogos personalizados criados por si',
        "COOKIEPOLICY": 'Política de cookies',
        "MODEL": 'Modelo',
        "MODELYEAR": 'Ano modelo',
        "CATEGORY": 'Categoria',
        "AUFUND": "Montagens e modificações",
        "TERMSOFUSE" : 'Condições de utilização',
        "PRIVACYPOLICY": "Declaração de proteção de dados",
        "SITENOTICE":"Ficha técnica",
        "THIRDPARTY":"Licenças de terceiros",
        "SPRACHE": "Idioma",
        "PREVIEW": "Antevisão",
        "FNF": "Função e características",
        "ADVANTAGES": "Vantagens",
        "NOTE": "Indicação",
        "STARTNEWPRN": '',
        "PRNUMBER": '',
        "MBVTEXT": '',
        "TITLE": '',
        'TARGETGROUP': "",
        "ENTWURF": "",
        "IN_FREIGABE_DE": "",
        "INUBERSETZUNG": "",
        "UBERSETZT": "",
        "VEROFFENLICHT": "",
        "ARCHIVIERT": "",
        "SPEICHERN": "",
        "GENINFO": "",
        "PREVIOUS": "",
        "ADDDESC": "",
        "SUMMARY": "",
        "HOMEPAGE":"página inicial",
        "PRLIST": "Lista de números de PR",
        "ADMINISTRATION": "",
        "AUTH":"",
        "CHANGEMODELYEAR": "",
        "BACK": "",
        "CLOSEMENU":"Menu fechado",
        "DESCRIPTION": "",
        "ADDIMAGES": "",
        "REMOVEIMAGE": "",
        "SHOWALL": "Ver Tudo",
        "CLICKON": "Clique em",
        "BUTTONTOCREATE": "botão para criar um novo número PR",
        "NOTIFICATIONS": "Avisos",
        "QUICKLINKS": "Links rápidos",
        "ADMINROLE": "",
        "MEDIALIBRARY": "",
        "CLICKONMODELYEAR":"",
        "ASSIGNSOURCES":"",
        "ADDEDIT": "",
        "RECEIVEDNEWPRN": "",
        "SAVEFORMYSELF": "",
        "REJECT": "",
        "SENDDEAPP": "",
        "SENDDETRANS": "",
        "SENDTRANSAPP": "",
        "RELEASE": "",
        "RESETDRAFT": "",
        "DOWLOADZIPFILE": "",
        "UPLOADTRANSLATEDFILES": "",
        "DRAGANDDROP": "",
        "VIEWPDF": "Ver como PDF",
        "ASSIGNROLE": "",
        "INSTRUCTIONS": "",
        "ROLE": "",
        "USER": "",
        "PRODUCTLINERESPONSIBLE": "",
        "KEYUSER": "",
        "EDITOR": "",
        "TRANSLATOR": "",
        "USEROVERVIEW": "",
        "ADDUSER": "",
        "DISCARD": "",
        "DOWNLOADSELECTEDCATALOG": "Selecione o(s) idioma(s) para baixar",
        "DOWNLOADCATALOG": "Seleção de descarregamento",
        "SENDREQUEST": "",
        "REQUESTMODELYEARCHANGE": "",
        "RELATEDMBVDATA": "",
        "I_ACCEPT_THE": "Aceito os",
        "AND": "e",
        "AGREE_AND_CONTINUE": "Concordar e seguir em frente",
        "We are here to help you": "Estamos lá para ti.",
        "HEADERTITLE": "veículo comercial",
        "CONTACTUS": "Contacte-nos",
        "COMPLETECATALOG": "Catálogo completo",
        "CONVERSIONCATALOG": "Culturistas",
        "PRNO": "PR-NR",
        "within 24 hours": "Dentro de 24 horas",
        "1 week": "1 Semana",
        "1 month": "1 Mês",
        "1 year": "1 Ano",
        "DATE": "Data",
        "BUILDANDCONV": "Construção e conversão",
        "ENTRIES_PER_PAGE": "Entradas por página",
        "DOWNLOAD_SELECTED": "Seleção de descarregamento",
        "COMPLETE_CATALOG": "Catálogo completo",
        "CATALOGUE_NOT_CREATED": "Nenhum catálogo criado para {model_name} em {language}",
        "CONSTRUCTION_CONVERSION_NOT_CREATED":"Nenhum catálogo disponível para instalações e modificações",
        "REQ_NOT_PROCESSED": "En raison d’une erreur, la requête n’a pas pu être exécutée",
        "DISCLAIMER": "This software product contains software components that are subject to open source licenses.\n" +
            "              Listed below, you will find the open source software components that are used together with the copyright\n" +
            "              notices, the applicable licenses and the original license texts, which may include warranty disclaimers\n" +
            "              and an exclusion of liability (third party license documentation). The source code and other required\n" +
            "              materials for particular open source software components incorporated in this software product may be\n" +
            "              obtained from the software supplier.\n" +
            "              The software supplier will provide you with the respective source code and materials for a charge covering\n" +
            "              the cost of performing such distribution (such as the cost of media, and shipping) in accordance with the\n" +
            "              terms and conditions of the applicable licenses. You will receive more information from the\n" +
            "              [PRN-Wiki: VWAG R: HAN, PR-NR Wiki pr-nr.wiki.vwag.r.han@volkswagen.de] provided above.",
        "NO_PAGE_FOUND" : "Página não disponível",
        "NOT_AUTHORIZED": "Não está autorizado a aceder a esta aplicação",
    }
}
