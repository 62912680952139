import {LOCALES} from '../locales'

export default {
    [LOCALES.SPANISH] : {
        'LOGOUT' : 'Cerrar sesión',
        "LOGIN": "Acceso",
        "ALLPRNUMBERS" : 'Todos los números PR',
        "SEARCH": "Buscar núm. PR y título...",
        "SHOW":"Mostrar",
        "ENTRIES": "Ver entradas",
        "SORT": "Classificar",
        "RECENTLYADDED": "Últimos núm. PR añadidos",
        "CATALOGS": 'Catálogos',
        'DOWNLOADOPTIONS' : 'Opciones de descarga',
        "CUSTOMCATALOG": 'Catálogos personalizados creados por usted',
        "COOKIEPOLICY": 'Normativa de cookies',
        "MODEL": 'Modelo',
        "MODELYEAR": 'Año de modelos',
        "CATEGORY": 'Categoría',
        "AUFUND": "Estructuras carroceras y transformaciones",
        "TERMSOFUSE" : 'Condiciones de uso',
        "PRIVACYPOLICY": "Declaración sobre protección de datos",
        "SITENOTICE":"Texto legal",
        "THIRDPARTY":"Licencias de terceros",
        "SPRACHE": "Idioma",
        "PREVIEW": "Avance",
        "FNF": "Función y características",
        "ADVANTAGES": "Ventajas",
        "NOTE": "Nota",
        "STARTNEWPRN": '',
        "PRNUMBER": '',
        "MBVTEXT": '',
        "TITLE": '',
        'TARGETGROUP': "",
        "ENTWURF": "",
        "IN_FREIGABE_DE": "",
        "INUBERSETZUNG": "",
        "UBERSETZT": "",
        "VEROFFENLICHT": "",
        "ARCHIVIERT": "",
        "SPEICHERN": "",
        "GENINFO": "",
        "PREVIOUS": "",
        "ADDDESC": "",
        "SUMMARY": "",
        "HOMEPAGE":"página principal",
        "PRLIST": "Lista de números PR",
        "ADMINISTRATION": "",
        "AUTH":"",
        "CHANGEMODELYEAR": "",
        "BACK": "",
        "CLOSEMENU":"Menú Cerrar",
        "DESCRIPTION": "",
        "ADDIMAGES": "",
        "REMOVEIMAGE": "",
        "SHOWALL": "Ver todo",
        "CLICKON": "Haga clic en",
        "BUTTONTOCREATE": "botón para crear un nuevo número PR",
        "NOTIFICATIONS": "Notificaciones",
        "QUICKLINKS": "Enlaces rápidos",
        "ADMINROLE": "",
        "MEDIALIBRARY": "",
        "CLICKONMODELYEAR":"",
        "ASSIGNSOURCES":"",
        "ADDEDIT": "",
        "RECEIVEDNEWPRN": "",
        "SAVEFORMYSELF": "",
        "REJECT": "",
        "SENDDEAPP": "",
        "SENDDETRANS": "",
        "SENDTRANSAPP": "",
        "RELEASE": "",
        "RESETDRAFT": "",
        "DOWLOADZIPFILE": "",
        "UPLOADTRANSLATEDFILES": "",
        "DRAGANDDROP": "",
        "VIEWPDF": "Ver en PDF",
        "ASSIGNROLE": "",
        "INSTRUCTIONS": "",
        "ROLE": "",
        "USER": "",
        "PRODUCTLINERESPONSIBLE": "",
        "KEYUSER": "",
        "EDITOR": "",
        "TRANSLATOR": "",
        "USEROVERVIEW": "",
        "ADDUSER": "",
        "DISCARD": "",
        "DOWNLOADSELECTEDCATALOG": "Seleccione los idiomas que desea descargar",
        "DOWNLOADCATALOG": "Descargar selección",
        "SENDREQUEST": "",
        "REQUESTMODELYEARCHANGE": "",
        "RELATEDMBVDATA": "",
        "I_ACCEPT_THE": "Acepto los",
        "AND": "y la",
        "AGREE_AND_CONTINUE": "Acordar y seguir adelante",
        "We are here to help you": "Estamos ahí para usted.",
        "HEADERTITLE": "vehículo comercial",
        "CONTACTUS": "contáctenos",
        "COMPLETECATALOG": "Catálogo completo",
        "CONVERSIONCATALOG": "Culturistas",
        "PRNO": "PR-NR",
        "within 24 hours": "En un plazo de 24 horas",
        "1 week": "1 Semana",
        "1 month": "1 Mes",
        "1 year": "1 Año",
        "DATE": "Fecha",
        "BUILDANDCONV": "Construcción y Conversión",
        "ENTRIES_PER_PAGE": "Entradas por página",
        "DOWNLOAD_SELECTED": "Descargar selección",
        "COMPLETE_CATALOG": "Catálogo completo",
        "CATALOGUE_NOT_CREATED": "No se ha creado ningún catálogo para {model_name} en {language}",
        "CONSTRUCTION_CONVERSION_NOT_CREATED":"No hay ningún catálgo disponible para carrocerías y transformaciones",
        "REQ_NOT_PROCESSED": "Debido a un error, no se pudo ejecutar la solicitud",
        "DISCLAIMER": "This software product contains software components that are subject to open source licenses.\n" +
            "              Listed below, you will find the open source software components that are used together with the copyright\n" +
            "              notices, the applicable licenses and the original license texts, which may include warranty disclaimers\n" +
            "              and an exclusion of liability (third party license documentation). The source code and other required\n" +
            "              materials for particular open source software components incorporated in this software product may be\n" +
            "              obtained from the software supplier.\n" +
            "              The software supplier will provide you with the respective source code and materials for a charge covering\n" +
            "              the cost of performing such distribution (such as the cost of media, and shipping) in accordance with the\n" +
            "              terms and conditions of the applicable licenses. You will receive more information from the\n" +
            "              [PRN-Wiki: VWAG R: HAN, PR-NR Wiki pr-nr.wiki.vwag.r.han@volkswagen.de] provided above.",
        "NO_PAGE_FOUND" : "Página no disponible",
        "NOT_AUTHORIZED": "No está autorizado a acceder a esta aplicación",
    }
}
