import React, {Component} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {API} from "../../constants/PRNConstants";
import translate from "../../i18n/translate";
import {Text, TextAppearance} from '@volkswagen-onehub/components-core';
import {AlertNotification} from "../AlertNotification/AlertNotification";
import Modal from "react-bootstrap/Modal";
import TermsOfUse from "../LegalLinksPage/TermsOfUse/TermsOfUse";
import DataProtectionStatement from "../LegalLinksPage/DataProtectionStatement/DataProtectionStatement";

require('./TermsandConditions.css');

class TermsAndConditions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            contentToShow: "TERMS_OF_USE"
        }
    }

     handleLinkClick = (contentToShow) => {
        this.setState({
            showModal: true,
            contentToShow: contentToShow
        })
    }

    render() {
        if (this.props.isTcAccepted === "false") {
            return (
                <>
                    <div className="row footer align-items-center">
                        <div className="col text-center">
                            <Text appearance={TextAppearance.label0150}>
                                <div className="terms-and-conditions">
                                    {translate("I_ACCEPT_THE")}
                                    <a onClick={() => this.handleLinkClick("TERMS_OF_USE")} className="href"> {translate("TERMSOFUSE")} </a>
                                    {translate("AND")}
                                    <a onClick={() => this.handleLinkClick("PRIVACY_POLICY")} className="href"> {translate("PRIVACYPOLICY")} </a>
                                    <a className="btn accept-button" onClick={() => this.acceptTermsAndConditions()}><Text>{translate("AGREE_AND_CONTINUE")}</Text></a>
                                </div>
                            </Text>
                        </div>
                    </div>
                    <div className="row">
                        <Modal size="xl" scrollable={true}
                               aria-labelledby="contained-modal-title-vcenter"
                               centered show={this.state.showModal} onHide={() => this.setState({showModal: false})}>
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {this.state.contentToShow === 'TERMS_OF_USE' ? <TermsOfUse/> : <DataProtectionStatement/>}
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            );
        } else {
            return (<></>)
        }
    }

    acceptTermsAndConditions() {
        fetch(API.USER_SERVICE_ENDPOINT
            + `/api/prn-user/user/accept-terms-fe`,
            { method: 'PUT' }
        ).then((res) => {
            if (res.ok) {
                this.props.acceptTerms();
                AlertNotification("Success", "Terms and Conditions Accepted", "success", 5000);
                window.location.reload();
            }
        });
    }
}

export default TermsAndConditions
