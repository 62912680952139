import en from './en.js';
import de from './de.js';
import fr from './fr.js';
import es from './es.js'
import pt from './pt.js'
import it from './it.js'

export default {
    ...de,
    ...en,
    ...fr,
    ...it,
    ...es,
    ...pt,
}