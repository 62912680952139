import {CTA, Text, TextAppearance} from "@volkswagen-onehub/components-core";
import {BottomNav, Footer} from "@vwa/components-cms";
import React, {Component} from "react";
import {LegalLinks} from "../../constants/PRNConstants";

export class FooterComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      legalLinks: []
    }

    this.populateLegalLinks = this.populateLegalLinks.bind(this)
  }

  populateLegalLinks() {
    let legalLinks = (
      <BottomNav hideCopyright={false} bottomNavAriaLabel={"footer"}>
        {LegalLinks.map((legalLink) =>
          <Text key={legalLink.key} appearance={TextAppearance.copy0150}>
            <CTA tag="a" emphasis="none" href={legalLink.href}>{legalLink.title}
            </CTA>
          </Text>
        )
        }
      </BottomNav>
    )
    this.setState({ legalLinks })
  }

  componentDidMount() {
    this.populateLegalLinks()
  }
  render() {
      return (
              <Footer
                bottomNav={this.state.legalLinks}
                visible={true}>
              </Footer>
      );
  }
}

export default FooterComponent;
