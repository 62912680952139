import React, {Component} from 'react';
import {Link} from "react-router-dom";

export class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render(){
        return(<div className="create-prn-link-section">
  <h2>You are logged out successfully</h2>
            <h2><Link to="/home">Click here to go back to login</Link></h2>
  </div>);
    }
}
export default Logout;
