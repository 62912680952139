import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    CTA,
    Divider,
    FixedWidthContainer,
    Spinner,
    Text,
    TextAppearance
} from "@volkswagen-onehub/components-core";
import equal from 'fast-deep-equal';
import React, { Fragment } from 'react';
import {API, PreviewLabels} from "../../constants/PRNConstants";
import {ApplicationContextConsumer} from "../../contextProvider/ApplicationContextProvider";
import translate from "../../i18n/translate";
import PreviewImage from './Preview.Image.component';
import {DownloadFile} from "../../common_components/Helper/Helper";
import {Save} from "@volkswagen-onehub/icons-core";

require('./Preview.css');

class PreviewData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            header: "",
            body: "",
            content: "",
            isLoading: false
            
        };
        this.renderPDF = this.renderPDF.bind(this);
    }

    renderPDF() {
        this.setState({
            isLoading: true
        },()=>{
            const prnReport = {
                prNumber: this.props.prNumber,
                model: this.props.model,
                modelYear: this.props.modelYear,
                category: this.props.category,
                title: this.props.title,
                languageVersion: this.props.languageVersion,
                functions: this.props.functions,
                advantages: this.props.advantages,
                notes: this.props.notes,
                prnImages: this.props.prnImages,
            }

            fetch(API.IMAGE_SERVICE_ENDPOINT + `/api/prn-wiki-image/pdf-generate`+ `?dealerAppFlag=`+true, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(prnReport),
            }).then(response => {
                this.setState({
                    isLoading:false
                });
                DownloadFile(response,'Report_' + this.props.prNumber + '_' + this.props.model + '_' + this.props.modelYear + '.pdf','application/pdf');
            });
        })
    }

    renderHeaderContent = () => {
        return (
            <Container>
                <ApplicationContextConsumer>
                    {({ prNumber, model, modelYear, category, title, languageVersion }) =>
                        <Container wrap={ContainerWrap.always} gutter={ContainerGutter.dynamic0100}>
                            <Text bold appearance={TextAppearance.label0250}>
                                PR-No.&nbsp;{prNumber}
                            </Text>
                            <Container gutter={ContainerGutter.static100}>
                                <Text bold appearance={TextAppearance.label0200}>{model.value?model.value:model}&nbsp;&nbsp;|&nbsp;&nbsp;</Text>
                                <Text bold appearance={TextAppearance.label0200}>{modelYear.value?modelYear.value:modelYear}&nbsp;&nbsp;|&nbsp;&nbsp;</Text>
                                <Text bold appearance={TextAppearance.label0200}>{category[languageVersion]}</Text>
                            </Container>
                            <Text bold appearance={TextAppearance.label0200}>
                                {title[languageVersion]}
                            </Text>
                        </Container>
                    }
                </ApplicationContextConsumer>
            </Container>
        )
    }

    getModelName() {
        if (typeof (this.props.model) === "object") {
          return this.props.model.value;
        }
        return this.props.model;
    }
    
      getModelYear() {
        if (typeof (this.props.modelYear) === "object") {
          return this.props.modelYear.value;
        }
        return this.props.modelYear;
    }

    bodyContentDivider(){
        return (
            <Container gutter={ContainerGutter.dynamic0100}>
                <FixedWidthContainer columns={3}>
                    <Divider/>
                </FixedWidthContainer>
                <FixedWidthContainer columns={18}>
                    <Divider/>
                </FixedWidthContainer>
            </Container>
        )
    }

    renderBodyContent() {
        return (
            <Container wrap={ContainerWrap.always} >
                <hr style={{border: '1px solid', margin:0}}/>
                <Container stretchContent>
                    <ApplicationContextConsumer>
                        {({ prnImages, languageVersion }) =>
                            <PreviewImage
                                prnImages={prnImages} languageVersion={languageVersion}
                            />
                        }
                    </ApplicationContextConsumer>
                </Container>
                <Container gutter={ContainerGutter.dynamic0100} padding={{top:ContainerPadding.dynamic0100}}>
                    <FixedWidthContainer columns={3}>
                        <Text appearance={TextAppearance.label0200}>
                            {PreviewLabels.FNF[this.props.languageVersion]}
                        </Text>
                    </FixedWidthContainer>
                    <FixedWidthContainer columns={18}>
                        <ApplicationContextConsumer>
                            {({ functions, languageVersion }) =>
                                <Text appearance={TextAppearance.label0200}>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: functions[languageVersion] }}>
                                    </div>
                                </Text>
                            }
                        </ApplicationContextConsumer>
                    </FixedWidthContainer>
                </Container>
                {this.bodyContentDivider()}
                <Container gutter={ContainerGutter.dynamic0100} padding={{top:ContainerPadding.dynamic0100}}>
                <FixedWidthContainer columns={3}>
                    <Text appearance={TextAppearance.label0200}>
                        {PreviewLabels.ADVANTAGES[this.props.languageVersion]}
                    </Text>
                </FixedWidthContainer>
                    <FixedWidthContainer columns={18}>
                    <ApplicationContextConsumer>
                        {({ advantages, languageVersion }) =>
                            <Text appearance={TextAppearance.label0200}>
                            <div
                                dangerouslySetInnerHTML={{ __html: advantages[languageVersion] }}>
                            </div>
                            </Text>
                                }
                    </ApplicationContextConsumer>
                    </FixedWidthContainer>
                </Container>
                {this.bodyContentDivider()}
                <Container gutter={ContainerGutter.dynamic0100} padding={{top:ContainerPadding.dynamic0100}}>
                    <FixedWidthContainer columns={3}>
                        <Text appearance={TextAppearance.label0200}>
                            {PreviewLabels.NOTE[this.props.languageVersion]}
                        </Text>
                    </FixedWidthContainer>
                    <FixedWidthContainer columns={18}>
                    <ApplicationContextConsumer>
                        {({ notes, languageVersion }) =>
                            <Text appearance={TextAppearance.label0200}>
                            <div
                                dangerouslySetInnerHTML={{ __html: notes[languageVersion] }}>
                            </div>
                            </Text>
                                }
                    </ApplicationContextConsumer>
                    </FixedWidthContainer>
                </Container>
                {this.bodyContentDivider()}
                { this.props.packagePrNumbers.length ?
                <Fragment>
                <Container gutter={ContainerGutter.dynamic0100} padding={{top:ContainerPadding.dynamic0100}}>
                <FixedWidthContainer columns={3}>
                    <Text appearance={TextAppearance.label0200}>
                    PR Numbers
                    </Text>
                </FixedWidthContainer>
                    <ApplicationContextConsumer>
                        {({ languageVersion }) =>
                            <FixedWidthContainer columns={18}>
                                <Text appearance={TextAppearance.label0200}>
                                    {this.props.packagePrNumbers.map((prNo)=>(
                                        <div>
                                            {prNo.active ?
                                                <>
                                                    <b><CTA emphasis="none" tag="a" href={'/prn-form/' + prNo.prNo + '/' + this.getModelName() + '/' + this.getModelYear()} target="_blank">{prNo.prNo}</CTA></b>{' - ' + prNo.title[languageVersion]}
                                                </>:
                                                <><CTA emphasis="none"  className={"disabled-anchor"}>{prNo.prNo}</CTA>{prNo.title[languageVersion] ? ' - ' + prNo.title[languageVersion] : ""}</>}
                                            {this.props.packagePrNumbers.length > 1 && this.props.packagePrNumbers.indexOf(prNo) !== this.props.packagePrNumbers.length - 1 ? <br /> : ""}


                                        </div>
                                    ))}
                                </Text>
                            </FixedWidthContainer>
                        }
                    </ApplicationContextConsumer>
                    </Container>
                {this.bodyContentDivider()}
                </Fragment>
                :<></>
                }
                
            </Container>
        )
    }

    renderPreview() {
        return (
            <Container shrinkContent wrap={ContainerWrap.always}>
                {this.renderHeaderContent()}
                {this.renderBodyContent()}
            </Container>
        );
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: "auto" });
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.prnImages, prevProps.prnImages)) {
            this.props.setContentHtml(this.state.content)
        }
    }

    componentDidMount() {
        this.props.setContentHtml(this.state.content)
    }

    render() {
        return (
            <Container gutter={ContainerGutter.dynamic0100}>
                <Text bold appearance={TextAppearance.headline0400} className='prntitle'>{translate("PREVIEW")}</Text>
                    {this.renderPreview()}
                <CTA icon={this.state.isLoading?<Spinner/>:<Save/>} emphasis="secondary" onClick={() => this.renderPDF()}>
                    {translate("VIEWPDF")}
                </CTA>
            </Container>
        );
    }
}

PreviewData
    .defaultProps = {
    prNumber: '',
    model: '',
    modelYear: '',
    category: '',
    fnf: '',
    advantage: '',
    note: '',
    content: '',
    prnImages: []
}

export default PreviewData;
