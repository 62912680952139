import {
  Breakpoints,
  Container,
  ContainerPadding,
  ContainerWrap,
  Layout,
  styled,
  Text,
  TextAppearance,
  TextInput
} from "@volkswagen-onehub/components-core";
import {Magnifier} from "@volkswagen-onehub/icons-core";
import React, {Component} from "react";
import {API, LocalStorage} from "../../constants/PRNConstants";
import OverviewTableElement from "./OverviewTableElement/OverviewTableElement";
import SortPRNumbers from "../../common_components/SortPRNumbers/SortPRNumbers";
import FilterPRNumbers from "../../common_components/FilterPRNumbers/FilterPRNumbers";
import translate from "../../i18n/translate";
import {FormattedMessage} from 'react-intl';

require("./PRNOverviewTable.css");

export const TableBackground = styled.div`
  background-color: #F9FCFD;
`;

export class PRNOverviewTable extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      prNumbers: [],
      searchFilteredPRNData: [],
      totalPages: 1,
      currentPage: urlParams.get('currentPage') || '1',
      totalResults: 10,
      sortingOption: "prnumber-a-z",
      showFilters: false,
      filterSortDate: "",
      filterModel: "",
      filterModelYear: "",
      filterCategory: "",
      filterAbh: '',
      searchQuery: urlParams.get('searchQuery') || '',
      isLoading: false,
      totalCount: 0
    };

    this.changePage = this.changePage.bind(this);
    this.changeTotalResults = this.changeTotalResults.bind(this);
    this.changeSortOption = this.changeSortOption.bind(this);
    this.showFilters = this.showFilters.bind(this);
    this.changeFilterDropdowns = this.changeFilterDropdowns.bind(this)
    this.removeAllFilters = this.removeAllFilters.bind(this)
    this.changeSearchQuery = this.changeSearchQuery.bind(this)
    this.updateUrl()
  }

  getTotalPages(totalResultsToShow, totalResultsAvailable) {
    return Math.ceil(totalResultsAvailable / totalResultsToShow);
  }

  encodeQueryData(data) {
    const ret = []
    for (let d in data) {
      // console.log(data[d])
      if (data[d]) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
      }
    }
    return ret.join('&')
  }

  changePage(newPage) {
    this.setState(
        { currentPage: newPage},
        () => {
          this.getPRNumbers();
        }
    );
  }

  changeTotalResults(totalResultsToShow) {
    this.setState(
      {
        totalResults: totalResultsToShow,
        currentPage: 1,
      },
      () => {
        this.getPRNumbers();
      }
    );
  }

  changeSortOption(sortOptionForPRNumbers) {
    this.setState(
      {
        sortingOption: sortOptionForPRNumbers,
      },
      () => {
        this.getPRNumbers();
      }
    );
  }

  changeSearchQuery(e) {
    this.setState(
      {
        searchQuery: e.target.value,
        currentPage: 1
      }, () => {
        this.updateUrl();
        this.getPRNumbers();
      })
  }
  updateUrl() {
    const { searchQuery, currentPage } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    if(searchQuery.length>0){
      urlParams.set('searchQuery',searchQuery)
    }else{
      urlParams.delete('searchQuery')
    }
    urlParams.set('currentPage', currentPage)

    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }
  globalSearch = () => {
    let { searchQuery, prNumbers } = this.state;
    const languageMap = {
      "de": value => value.title?.germanVersion?.toLowerCase().includes(searchQuery.toLowerCase()),
      "en": value => value.title?.englishVersion?.toLowerCase().includes(searchQuery.toLowerCase()),
      "fr": value => value.title?.frenchVersion?.toLowerCase().includes(searchQuery.toLowerCase()),
      "it": value => value.title?.italianVersion?.toLowerCase().includes(searchQuery.toLowerCase()),
      "pt": value => value.title?.portugeseVersion?.toLowerCase().includes(searchQuery.toLowerCase()),
      "es": value => value.title?.spanishVersion?.toLowerCase().includes(searchQuery.toLowerCase())
    };
    const selectedLanguage = localStorage.getItem(LocalStorage.SELECTED_LANGUAGE);
    const filterFunction = languageMap[selectedLanguage];

    if (!filterFunction) {
      return;
    }
    const filteredData = prNumbers.filter(value => {
      return filterFunction(value) ||
          (value.prNumber?.toString().toLowerCase().includes(searchQuery.toLowerCase()));
    });

    this.setState({
      searchFilteredPRNData: filteredData,
      totalPages: filteredData.length > 0 ? this.getTotalPages(this.state.totalResults, filteredData.length) : this.getTotalPages(this.state.totalResults, prNumbers.length)
    });
  };

  changeFilterDropdowns(name, value) {
    this.setState(
      { [name]: value },
      () => {
        this.getPRNumbers();
      }
    );
  }

  removeAllFilters() {
    this.setState({
      filterSortDate: "",
      filterModel: "",
      filterModelYear: "",
      filterCategory: "",
      filterAbh: '',
    }, () => {
      this.getPRNumbers();
    })
  }

  getPRNumbers() {
    this.setState({
      isLoading: true
    }, ()=>{
      const filterParams = {
        currentPage: this.state.currentPage,
        currentPageCount: this.state.totalResults,
        sortBy: this.state.sortingOption,
        sortDate: this.state.filterSortDate,
        model: this.state.filterModel,
        modelYear: this.state.filterModelYear,
        category: this.state.filterCategory,
        abh: this.state.filterAbh,
        searchQuery: this.state.searchQuery,
        language: localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)

      };
      const filterString = this.encodeQueryData(filterParams);
      fetch(
          API.EDITOR_SERVICE_ENDPOINT + `/api/prn-editor/published-pr-numbers/?${filterString}`
      ).then((res) => {
            if(res.ok){
              res.json().then(data=>{
                this.setState({
                  prNumbers: data.prnWikiListDTO,
                  totalPages: this.getTotalPages(this.state.totalResults, data.totalCount),
                  totalCount: data.totalCount,
                  currentPage: this.state.currentPage
                });
                if(this.state.searchQuery.length > 0){
                  this.globalSearch();
                }
              });
            }
          }).finally(()=>{
            this.setState({
              isLoading: false
            });
      });
    })

  }

  showFilters() {
    this.setState({
      showFilters: !this.state.showFilters,
    });
  }

  componentDidMount() {
    this.getPRNumbers();
  }


  getPRNumbersList() {
    if (this.state.searchQuery.length > 0) {
      return this.state.searchFilteredPRNData
    }
    else {
      return this.state.prNumbers
    }
  }

  render() {
    return (
        <Container stretchContent wrap={ContainerWrap.always}>
          <Container wrap={ContainerWrap.always} padding={{left:ContainerPadding.dynamic0150,right:ContainerPadding.dynamic0150}}>
          <Text bold appearance={TextAppearance.headline0400}>{translate("ALLPRNUMBERS")}</Text>
            <Layout allowOverflowingContent appearance={{
              [Breakpoints.default]: [{
                name: 'searchInput',
                columns: 5
              }, {
                name: 'sort',
                columns: 19
              }]
            }}>
                <FormattedMessage id="SEARCH">
                  {placeholder=>
                      <TextInput
                          placeholder={placeholder}
                          icon={<Magnifier variant="default" />}
                          value={this.state.searchQuery}
                          onChange={this.changeSearchQuery}
                      />
                  }
                </FormattedMessage>
                <SortPRNumbers
                    option={this.state.sortingOption}
                    changeSortOption={this.changeSortOption}
                />
            </Layout>
                <div className="filter-prn-section">
                  <FilterPRNumbers changeFilterDropdowns={this.changeFilterDropdowns} removeAllFilters={this.removeAllFilters} />
                </div>
          </Container>
          <TableBackground>
            <Container padding={{top:ContainerPadding.dynamic0100, bottom:ContainerPadding.dynamic0100, left:ContainerPadding.dynamic0150,right:ContainerPadding.dynamic0150}} stretchContent>
                <OverviewTableElement
                    hasOnlyLatest={false}
                    isLoading={this.state.isLoading}
                    prNumbers={this.getPRNumbersList()}
                    currentPage={this.state.currentPage}
                    totalResults={this.state.totalResults}
                    totalCount={this.state.totalCount}
                    changePage={this.changePage}
                    changeTotalResults={this.changeTotalResults}
                />
            </Container>
          </TableBackground>
        </Container>
    );
  }
}

export default PRNOverviewTable;
