import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import 'bootstrap/dist/css/bootstrap.css';
import {
  Breakpoints,
  Container,
  ContainerPadding,
  ContainerVerticalAlignment, ContainerWrap,
  Layout,
  Text,
  TextAppearance,
  TextColor
} from "@volkswagen-onehub/components-core";

require('./Dropdown.css')

const animatedComponents = makeAnimated()

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
      options: []
    }
    this.generateCategoryOptions = this.generateCategoryOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.filterResults = this.filterResults.bind(this)
  }

  generateCategoryOptions() {
    return this.props.Model.map((category, key) => {
      return ({
        value: category.value,
        label: category.label
      })
    })
  }

  filterResults() {
    let array = []
    if (this.props.dropdownValue !== undefined && this.props.dropdownValue !== '' && this.props.dropdownValue !== null) {
      let str = this.props.dropdownValue.value
      if (this.props.name === 'model') {
        this.filterResultsForModel(str, array);
      }
      else if (this.props.name === 'modelYear') {
        for(let result of this.props.resultsAfterModelFilter)  {
          if (result.modelYear.toUpperCase() === str.toUpperCase()) {
            array.push(result)
          }
        }
        this.props.setState({
          resultsAfterModelYearFilter: array
        });
        this.props.setPRNumber("")
        //this.props.setTitle("")
        this.props.setDescription("")
      }
    }
  }


  filterResultsForModel(str, array){
    for(let result of this.props.totalResults)  {
      if (result.productLine.toUpperCase() === str.toUpperCase()) {
        array.push(result);
      }
    }
    this.props.setState({
      resultsAfterModelFilter: array
    });
    this.props.setModelYear("")
    this.props.setPRNumber("")
    this.props.setDescription("")
  }


  handleChange = (e) => {
    this.setState({
      selectedOption: e,
    }, () => { this.props.handleDropdownChange(e, this.props.name) }
    )
  }

  componentDidMount() {
    document.getElementById("dropdown-query").disabled = true;
  }

  renderFilterCategory() {
    return (
        <Layout allowOverflowingContent appearance={{
          [Breakpoints.default]: [{
            name: 'a',
            columns: 2
          }, {
            name: 'b',
            columns: 8
          }, {
            name: 'c',
            columns: 2
          }]}}>
          <Container verticalAlign={ContainerVerticalAlignment.center} padding={{top: ContainerPadding.static200}} wrap={ContainerWrap.never}>
            <Text color={this.props.labelColor?this.props.labelColor:TextColor.primary} bold={this.props.labelBold?this.props.labelBold:false} appearance={this.props.labelAppearance?this.props.labelAppearance:TextAppearance.label0100}>{this.props.label?this.props.label:this.props.placeholder}</Text>
          </Container>
          <>
          <Select className='select_option_filter dropdown_placeholder_entry_style'
            id="dropdown-query"
            closeMenuOnSelect={true}
            components={animatedComponents}
            isDisabled={this.props.dropdownIsDisabled}
            options={this.generateCategoryOptions()}
            placeholder={'Select ' + this.props.placeholder}
            onChange={this.handleChange}
            //defaultValue={this.props.dropdownValue}
            value={this.props.dropdownValue}
            defaultInputValue={this.props.defaultDropDownVal}
            onBlur={this.filterResults}
          />
        </>
      </ Layout>
    )
  }

  render() {
    return (
      <>
        {this.renderFilterCategory()}
      </>
    )
  }
}



Dropdown.defaultProps = {
  Model: [],
}

Dropdown.propTypes = {
  Model: PropTypes.array.isRequired
}

export default Dropdown
