import React from 'react';
import Modal from 'react-bootstrap/Modal'
import {
  Image
} from "@volkswagen-onehub/components-core";
import {generateImageURL} from '../../common_components/Helper/Helper'

require("./Preview.css");

class PreviewImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImageSrc: "#",
      show: false,
      imageBlobs: [],
    }
    this.changePreviewImage = this.changePreviewImage.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  changePreviewImage(e) {
    var url = e.target.src;
    this.setState({
      previewImageSrc: url,
      show: true
    })
  }

  getImageTitle(prnImage, languageVersion) {
    if (prnImage[languageVersion] && !prnImage[languageVersion] !== "") {
      return prnImage[languageVersion];
    }
    else
      return prnImage.title;
  }

  showImageCards() {
    var imageCards = [];
    let i = 0;
    if (this.props.prnImages !== null) {
      this.state.imageBlobs?.forEach(image=>{
        i++;
        imageCards.push(
            <div className="col-sm-3 outer-wrapper" id={i}>
              <div class="image_frame">
                <Image
                    src={image}
                    alt="Dummy"
                    objectFit="contain"
                    className="img-fluid cursor-pointer"
                    class='image-border-style'
                    data-toggle="modal" data-target="#imagePreview"
                    onClick={this.changePreviewImage} />
              </div>
            </div>
        );
      })
    }
    return imageCards;
  }

  async getData(images){
    const imageArr = [];
    var totalLinks = images.length;
    for(let i=0;i<totalLinks;i++){
      const url = generateImageURL(images[i].s3key)
      await fetch(url, {
        method: "GET",
      }).then(response =>
      {
        return response.blob()
      })
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]))
            imageArr.push(url)
          }).finally(() => {
            this.setState({
              imageBlobs: imageArr
            })
          })
    }
  }

  async componentDidUpdate(prevProps) {
    if(this.props.prnImages!=prevProps.prnImages){
      await this.getData(this.props.prnImages)
    }
  }


  render() {
    return (
      <div className="row">
        {this.showImageCards()}
        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Image
              src={this.state.previewImageSrc}
              className="img-fluid" />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
PreviewImage.defaultProps = {
  prnImages: []
}
export default PreviewImage;