import {
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    CTA,
    styled,
    Text,
    TextAlignment,
    TextAppearance
} from '@volkswagen-onehub/components-core'
import React, {Component} from 'react'
import translate from '../../i18n/translate'

require('./ContactUs.css')

const StyledDiv = styled.div`
    background: radial-gradient(circle, #00437a 0%, #001e50 100%);
`

const WhiteColorText = styled.div`
    color: white
`
export class ContactUs extends Component {
    render() {
        return (
            <StyledDiv>
                <Container gutter={ContainerGutter.dynamic0100} padding={ContainerPadding.dynamic0150} wrap={ContainerWrap.always} horizontalAlign={ContainerHorizontalAlignment.center}>
                    <Text appearance={TextAppearance.headline0400} textAlign={TextAlignment.center}>
                        <WhiteColorText>{translate('We are here to help you')}</WhiteColorText>
                    </Text>
                    <Text textAlign={TextAlignment.center}>
                    <CTA style={{borderColor: 'white'}} tag={"a"} emphasis="secondary" href="mailto:pr-nr.wiki.vwag.r.han@volkswagen.de?subject=PR-Nummern%20Wiki%20Anfrage%20/%20PR-Numbers%20Wiki%20request&body=Hallo Team / Hello team,%0D%0DDiese Mail wurde über die Kontaktmöglichkeit im PR-Nummern Wiki erstellt / This mail was created via contact address in PR-Numbers Wiki%0D%0DBitte schildern Sie Ihr Anliegen hier / Please describe your request here:"><WhiteColorText>{translate('CONTACTUS')}</WhiteColorText></CTA>
                    </Text>
                </Container>
            </StyledDiv>
        )
    }
}

export default ContactUs
