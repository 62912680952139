import {ThemeProvider} from "@volkswagen-onehub/components-core";
import React, {Component} from "react";

export class ThirdPartyLicenseNotice extends Component {
  constructor(props) {
    super(props)

    this.state = {
      htmlContent: ''
    }
  }

  fetchLicenseNotesData(){
    const pathForHtmlFile = `/LegalLinksFiles/LicenseNotes/LicenseNotes.html`
    fetch(pathForHtmlFile).then(res => {
      return res.text()
    }).then(res => {
      this.setState({
        htmlContent: res
      })
    })
  }

  componentDidMount(){
    this.fetchLicenseNotesData();
  }

  componentDidUpdate(prevProps, prevState,snapshot) {
    if (prevState.data !== this.state.data) {
      this.fetchLicenseNotesData();
    }
  }

  render() {
    return (
        <ThemeProvider theme="main">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }}></div>
          </div>
        </ThemeProvider>
    );
  }
}

export default ThirdPartyLicenseNotice;
