import translate from '../i18n/translate'

export const ModelYear = [
  { label: "2021", value: '2021' },
    { label: "2020", value: '2020' }
];

export const FirstLevelNavigation = [
  {key: "0" , url : "/", name: translate("HOMEPAGE")},
  {key: "1" , url : "/pr-numbers", name: translate("PRLIST")},
  {key: "2" , url : "/catalog", name: translate("CATALOGS")}
];

export const Category = {
    germanVersion: [{label: "Fahrwerk", value: 'Fahrwerk'},
    {label: "Funktionsausstattung", value: 'Funktionsausstattung'},
    {label: "Innenausstattung", value: 'Innenausstattung'},
    {label: "Karosserie", value: 'Karosserie'},
    {label: "Motor und Getriebe", value: 'Motor und Getriebe'},
    {label: "Sicherheitsausstattung", value: 'Sicherheitsausstattung'},
    {label: "Sonstige", value: 'Sonstige'}],
  spanishVersion : [
    { label: "Tren de rodaje", value: 'Tren de rodaje' },
    { label: "Equipamiento funcional", value: 'Equipamiento funcional' },
    { label: "Equipamiento interior", value: 'Equipamiento interior' },
    { label: "Carrocería", value: 'Carrocería' },
    { label: "Motor y caja de cambios", value: 'Motor y caja de cambios' },
    { label: "Equipamiento de seguridad", value: 'Equipamiento de seguridad' },
    { label: "Otros", value: 'Otros' }],
  englishVersion : [
    { label: "Chassis", value: 'Chassis' },
    { label: "Functional equipment", value: 'Functional equipment' },
    { label: "Interior", value: 'Interior' },
    { label: "Body", value: 'Body' },
    { label: "Engine & gearbox", value: 'Engine & gearbox' },
    { label: "Safety features", value: 'Safety features' },
    { label: "Other", value: 'Other' }],
  frenchVersion : [
    { label: "Train roulant", value: 'Train roulant' },
    { label: "Équipement fonctionnel", value: 'Équipement fonctionnel' },
    { label: "Équipement intérieur", value: 'Équipement intérieur' },
    { label: "Carrosserie", value: 'Carrosserie' },
    { label: "Moteur et boîte de vitesses", value: 'Moteur et boîte de vitesses' },
    { label: "Équipement de sécurité", value: 'Équipement de sécurité' },
    { label: "Divers", value: 'Divers' }],
  italianVersion : [
    { label: "Telaio", value: 'Telaio' },
    { label: "Equipaggiamento funzioni", value: 'Equipaggiamento funzioni' },
    { label: "Equipaggiamento interno", value: 'Equipaggiamento interno' },
    { label: "Carrozzeria", value: 'Carrozzeria' },
    { label: "Motore e cambio", value: 'Motore e cambio' },
    { label: "Equipaggiamento di sicurezza", value: 'Equipaggiamento di sicurezza' },
    { label: "Altro", value: 'Altro' }],
    portugeseVersion : [
    { label: "Chassis", value: 'Chassis' },
    { label: "Funcionalidades", value: 'Funcionalidades' },
    { label: "Equipamento interior", value: 'Equipamento interior' },
    { label: "Carroçaria", value: 'Carroçaria' },
    { label: "Motor e transmissão", value: 'Motor e transmissão' },
    { label: "Equipamento de segurança", value: 'Equipamento de segurança' },
    { label: "Outros", value: 'Outros' }]

};

export const filterByCategory = {
    germanVersion: [{label: "Fahrwerk", value: 'Fahrwerk'},
        {label: "Funktionsausstattung", value: 'Funktionsausstattung'},
        {label: "Innenausstattung", value: 'Innenausstattung'},
        {label: "Karosserie", value: 'Karosserie'},
        {label: "Motor und Getriebe", value: 'Motor und Getriebe'},
        {label: "Sicherheitsausstattung", value: 'Sicherheitsausstattung'},
        {label: "Sonstige", value: 'Sonstige'}],
    spanishVersion : [
        { label: "Tren de rodaje", value: 'Fahrwerk' },
        { label: "Equipamiento funcional", value: 'Funktionsausstattung' },
        { label: "Equipamiento interior", value: 'Innenausstattung' },
        { label: "Carrocería", value: 'Karosserie' },
        { label: "Motor y caja de cambios", value: 'Motor und Getriebe' },
        { label: "Equipamiento de seguridad", value: 'Sicherheitsausstattung' },
        { label: "Otros", value: 'Sonstige' }],
    englishVersion : [
        { label: "Chassis", value: 'Fahrwerk' },
        { label: "Functional equipment", value: 'Funktionsausstattung' },
        { label: "Interior", value: 'Innenausstattung' },
        { label: "Body", value: 'Karosserie' },
        { label: "Engine & gearbox", value: 'Motor und Getriebe' },
        { label: "Safety features", value: 'Sicherheitsausstattung' },
        { label: "Other", value: 'Sonstige' }],
    frenchVersion : [
        { label: "Train roulant", value: 'Fahrwerk' },
        { label: "Équipement fonctionnel", value: 'Funktionsausstattung' },
        { label: "Équipement intérieur", value: 'Innenausstattung' },
        { label: "Carrosserie", value: 'Karosserie' },
        { label: "Moteur et boîte de vitesses", value: 'Motor und Getriebe' },
        { label: "Équipement de sécurité", value: 'Sicherheitsausstattung' },
        { label: "Divers", value: 'Sonstige' }],
    italianVersion : [
        { label: "Telaio", value: 'Fahrwerk' },
        { label: "Equipaggiamento funzioni", value: 'Funktionsausstattung' },
        { label: "Equipaggiamento interno", value: 'Innenausstattung' },
        { label: "Carrozzeria", value: 'Karosserie' },
        { label: "Motore e cambio", value: 'Motor und Getriebe' },
        { label: "Equipaggiamento di sicurezza", value: 'Sicherheitsausstattung' },
        { label: "Altro", value: 'Sonstige' }],
    portugeseVersion : [
        { label: "Chassis", value: 'Fahrwerk' },
        { label: "Funcionalidades", value: 'Funktionsausstattung' },
        { label: "Equipamento interior", value: 'Innenausstattung' },
        { label: "Carroçaria", value: 'Karosserie' },
        { label: "Motor e transmissão", value: 'Motor und Getriebe'  },
        { label: "Equipamento de segurança", value: 'Sicherheitsausstattung' },
        { label: "Outros", value: 'Sonstige' }]

};

export const TranslatedByCategory = {
    de: filterByCategory.germanVersion,
    en: filterByCategory.englishVersion,
    es: filterByCategory.spanishVersion,
    fr: filterByCategory.frenchVersion,
    it: filterByCategory.italianVersion,
    pt: filterByCategory.portugeseVersion
}

export const TargetGroup =
{ germanVersion: [
  { label: "Bau und Forst", value: 'Bau und Forst' },
  { label: "Gewerbliche Personenbeförderung", value: 'Gewerbliche Personenbeförderung' },
  { label: "Handwerk", value: 'Handwerk' },
  { label: "Kurier und Auslieferung", value: 'Kurier und Auslieferung' },
  { label: "Medizinischer Transport und Rettungsdienste", value: 'Medizinischer Transport und Rettungsdienste' },
  { label: "Polizei und Militär", value: 'Polizei und Militär' },
  { label: "Service und mobile Werkstätten", value: 'Service und mobile Werkstätten' },
  { label: "Spezialanwendungen", value: 'Spezialanwendungen' }],
spanishVersion : [
  { label: "Construcción y forestal", value: 'Construcción y forestal' },
  { label: "Transporte comercial de viajeros", value: 'Transporte comercial de viajeros' },
  { label: "Gremio", value: 'Gremio' },
  { label: "Correo y distribución", value: 'Correo y distribución' },
  { label: "Transporte médico y servicios de salvamento", value: 'Transporte médico y servicios de salvamento' },
  { label: "Policía y militar", value: 'Policía y militar' },
  { label: "Servicios y talleres móviles", value: 'Servicios y talleres móviles' },
  { label: "Aplicaciones especiales", value: 'Aplicaciones especiales' }],
englishVersion : [
  { label: "Construction & Forestry", value: 'Construction & Forestry' },
  { label: "Commercial Passenger Transport", value: 'Commercial Passenger Transport' },
  { label: "Skilled Manual Labour", value: 'Skilled Manual Labour' },
  { label: "Courier & Logistics", value: 'Courier & Logistics' },
  { label: "Medical Transport & Rescue Services", value: 'Medical Transport & Rescue Services' },
  { label: "Police & Military", value: 'Police & Military' },
  { label: "Service & Mobile Workshops", value: 'Service & Mobile Workshops' },
  { label: "Special Applications", value: 'Special Applications' }],
frenchVersion : [
  { label: "Construction et industrie forestière", value: 'Construction et industrie forestière' },
  { label: "Transport professionnel de passagers ", value: 'Transport professionnel de passagers' },
  { label: "Artisanat", value: 'Artisanat' },
  { label: "Coursiers et livreurs", value: 'Coursiers et livreurs' },
  { label: "Transports médicaux et ambulances", value: 'Transports médicaux et ambulances' },
  { label: "Police et armée", value: 'Police et armée' },
  { label: "Service et garages mobiles", value: 'Service et garages mobiles' },
  { label: "Applications spéciales", value: 'Applications spéciales' }],
italianVersion : [
  { label: "Edilizia e settore forestale", value: 'Edilizia e settore forestale' },
  { label: "Trasporto persone commerciale", value: 'Trasporto persone commerciale' },
  { label: "Artigianiato", value: 'Artigianiato' },
  { label: "Corriere e consegna", value: 'Corriere e consegna' },
  { label: "Trasporto medico e servizi di soccorso", value: 'Trasporto medico e servizi di soccorso' },
  { label: "Polizia e corpo militare", value: 'Polizia e corpo militare' },
  { label: "Assistenza e officine mobili", value: 'Assistenza e officine mobili' },
  { label: "Applicazioni speciali", value: 'Applicazioni speciali' }],
portugeseVersion : [
  { label: "Construção civil e floresta", value: 'Construção civil e floresta' },
  { label: "Transporte comercial de passageiros", value: 'Transporte comercial de passageiros' },
  { label: "Uso profissional", value: 'Uso profissional' },
    {label: "Correio e logística", value: 'Correio e logística'},
    {label: "Transporte de produtos médicos e serviços de emergência", value: 'Transporte de produtos médicos e serviços de emergência'},
    {label: "Polícia e militar", value: 'Polícia e militar' },
  { label: "Assistência técnica e oficinas móveis", value: 'Assistência técnica e oficinas móveis' },
  { label: "Aplicações especiais", value: 'Aplicações especiais' },
]
};

export const LegalLinks = [
  {key: "0" , href : "/nutzungsbedingungen", title: translate("TERMSOFUSE")},
  {key: "1" , href : "/datenschutz", title: translate("PRIVACYPOLICY")},
  {key: "2" , href : "/cookie", title: translate("COOKIEPOLICY")},
  {key: "3" , href : "/impressum", title: translate("SITENOTICE")},
  {key: "4" , href : "/lizenzen-dritter", title: translate("THIRDPARTY")},
]

export const Status = [
    {label: 'ENTWURF', value: 'Entwurf'},
    {label: 'IN FREIGABE DE', value: 'In Freigabe DE'},
    {label: 'IN UBERSETZUNG', value: 'In Übersetzung'},
    {label: 'UBERSETZT', value: 'Übersetzt'},
    {label: 'VEROFFENLICHT', value: 'Veröffentlicht'}
];

export const ButtonStatus = [
  {value: 'Send DE for Approval'},
  {value: 'Save for Myself'},
  {value: 'Reject'},
  {value: 'Send DE for Translations'},
  {value: 'Send Translations for Approval'},
  {value: 'Send for Release'},
  {value: 'Release'}
];

export const Translations = [
    {label: "Translations1", value: 'Translations1'},
    {label: "Translations2", value: 'Translations2'},
    {label: "Translations3", value: 'Translations3'}
];

export const RadioButtons = [
    {value: 'option1', label: 'Draft'},
    {value: 'option2', label: 'Awaiting approval on DE version'},
    {value: 'option3', label: 'DE Released/ awaiting translations'},
    {value: 'option4', label: 'Awaiting approval on translations'},
    {value: 'option5', label: 'Final Release'}
];

export const Role = [
    {label: "Product line responsible", value: 'Product line responsible'},
    {label: "Product owner", value: 'Product owner'},
    {label: "Editor", value: 'Editor'},
    {label: "Translator", value: 'Translator'},

];

export const GermanLanguageRole = [
    {label: "Produktlinienverantwortliche", value: 'Produktlinienverantwortliche'},
    {label: "Key User", value: 'Key User'},
    {label: "Redakteure", value: 'Redakteure'},
    {label: "Übersetzer", value: 'Übersetzer'}
];

export const API = {
    PRN_DATA: "/api/prn-editor/all-models",
    USER_DATA: "/api/prn-user/all-users",
    USER_ROLEWISE_DATA: "/api/prn-user/all-users/role",
    DELETE_USER: "/api/prn-user/user/",
    ASSIGN_ROLE_TO_USER: "/api/prn-user/user-profile",
    EDITOR_SERVICE_ENDPOINT: process.env.REACT_APP_EDITOR_SERVICE_APP_API_URL,
    USER_SERVICE_ENDPOINT: process.env.REACT_APP_USER_SERVICE_APP_API_URL,
    IMAGE_SERVICE_ENDPOINT: process.env.REACT_APP_IMAGE_SERVICE_APP_API_URL,
    GRP_LOGIN_ENDPOINT: process.env.REACT_APP_GRP_LOGIN_APP_API_URL,
    IDP_LOGIN_ENDPOINT: process.env.REACT_APP_IDP_LOGIN_APP_API_URL,
    GRP_LOGOUT_URI: process.env.REACT_APP_UI_GRP_LOGOUT_URI
}

export const LocalStorage = {
    SELECTED_LANGUAGE: "prnWikiAppPreferredLanguage",
    ROLE: "prnWikiAppRole",
    LOGGED_IN_STATUS: "prnWikiAppLoggedInStatus",
    IDP_ROLE: "prnWikiAppIdpRole",
    USERNAME: "prnWikiAppUsername",
    USER_ID: "prnWikiAppUserId",
    IS_TC_ACCEPTED: "prnWikiAppIsTcAccepted",
    PRODUCT_LINES: "prnWikiAppProductLines",
    ACCESS_TOKEN: "prnWikiAppAccessToken",
    EMAIL: "prnWikiAppEmail",
    IS_LOGIN_LANG_NEEDED: "prnWikiIsLoginLangNeeded"
}

export const UserAuthCookies = {
    USERNAME: "prnWikiAppUsername",
    USER_ID: "prnWikiAppUserId",
    ACCESS_TOKEN: "prnWikiAppAccessToken",
}

export const Language = [
  { label: "DE", value: 'germanVersion' },
  { label: "EN", value: 'englishVersion' },
  { label: "PT", value: 'portugeseVersion' },
  { label: "FR", value: 'frenchVersion' },
  { label: "IT", value: 'italianVersion' },
  { label: "ES", value: 'spanishVersion' }
];

export const LanguageMap = {
    de: 'germanVersion',
    en: 'englishVersion',
    pt: 'portugeseVersion',
    fr: 'frenchVersion',
    it: 'italianVersion',
    es: 'spanishVersion'
};

export const filterByDate= [
  { label: translate("within 24 hours"), value: 'WITHIN24HRS' },
  { label: translate("1 week"), value: 'ONEWEEK' },
  { label: translate("1 month"), value: 'ONEMONTH' },
  { label: translate("1 year"), value: 'ONEYEAR' },
]

export const PreviewLabels = {
    FNF : {
        germanVersion: "Funktion und Merkmale",
        englishVersion: "Function and Features",
        portugeseVersion: "Função e características",
        frenchVersion: "Fonction et caractéristiques",
        italianVersion: "Funzione e caratteristiche",
        spanishVersion: "Función y características"
    },
    ADVANTAGES : {
        germanVersion: "Vorteile",
        englishVersion: "Advantages",
        portugeseVersion: "Vantagens",
        frenchVersion: "Advantages",
        italianVersion: "Vantaggi",
        spanishVersion: "Ventajas"
    },
    NOTE : {
        germanVersion: "Hinweis",
        englishVersion: "Note",
        portugeseVersion: "Indicação",
        frenchVersion: "Remarque",
        italianVersion: "Nota",
        spanishVersion: "Nota"
    }
}

export const CloseNavigationMenu = translate("CLOSEMENU")
export const NavigationBackLevel = "Zurück"
export const SITE_NOTICE_URL = "https://consent.vwgroup.io/consent/v1/texts/consent/de"

export function titleCase(str) {
    return str.toLowerCase().split(" ").reduce( (el1, el2) =>
        el1 +""+(el2.charAt(0).toUpperCase() + el2.slice(1) +" "), '');
}