import React, {Component} from 'react';
import {API} from '../../constants/PRNConstants';
import history from '../../history';
import "./LoginPage.css";

export class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      password: ""
    };
  }

  handleSuccessfulAuth(data) {
    this.props.handleLogin(data);
    history.push('/home');
  }

  getUpdatedUserProfileData() {
    fetch(API.USER_SERVICE_ENDPOINT + `/api/prn-user/user/updateFEUserData/`+navigator.language[0]+navigator.language[1], {
          method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
        }
    ).then((res) => {
      if(res.ok){
        res.json().then(data=>{
          this.handleSuccessfulAuth(data);
        });
      }
    });
  }

  componentDidMount() {
    //For cloud
    if (process.env.REACT_APP_CURR_ENV === 'qa' || process.env.REACT_APP_CURR_ENV === 'prod')
      window.location.href = API.GRP_LOGIN_ENDPOINT + `/oauth2/authorization/custom`;
    else {
      fetch(API.USER_SERVICE_ENDPOINT + `/api/prn-user/user/profile`, {
        method: 'GET'
      }).then((res) => {
        if(res.ok){
          res.json().then(data=>{
            this.getUpdatedUserProfileData(data);
          })
        }
      });
    }
  }


  render() {
    return <></>;

  }
}

export default LoginPage
