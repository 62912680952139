import React, { Component } from 'react';
import {LocalStorage, SITE_NOTICE_URL} from "../../../constants/PRNConstants";

require('./Imprint.css')


export class Imprint extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            imprintHtmlContent : ''
        }
    }

    fetchSiteNoticeData(){
        let appLanguage = localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)?localStorage.getItem(LocalStorage.SELECTED_LANGUAGE):'en';
        if(this.props.appLanguage){
            appLanguage = this.props.appLanguage;
        }
        const urlForImprintDE =`${SITE_NOTICE_URL}/${appLanguage}/volkswagen-imprint/latest/html`

        fetch(urlForImprintDE).then(res => {
            return res.text()
        }).then(res => {
            this.setState({
                imprintHtmlContent: res
            })
        })
    }

    componentDidMount(){
        this.fetchSiteNoticeData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.appLanguage !== this.props.appLanguage)
            this.fetchSiteNoticeData();
    }

    render() {
        return (
            <div
             className="container"
             >
                <div className="imprint" dangerouslySetInnerHTML={{ __html: this.state.imprintHtmlContent }}></div>
            </div>
        )
    }
}

export default Imprint
