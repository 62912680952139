import fetchIntercept from 'fetch-intercept';
import {AlertNotification} from '../AlertNotification/AlertNotification';
import {API, LocalStorage, SITE_NOTICE_URL,UserAuthCookies} from "../../constants/PRNConstants";
import jwt from "jsonwebtoken";
import translate from "../../i18n/translate";
import {getUserDetailsObjectFromCookie} from "../../utils/UserDetailsAuthUtil";

function checkExpiry() {
    let token = getUserDetailsObjectFromCookie(UserAuthCookies.ACCESS_TOKEN);
    if (token && token !== '') {
        let decoded = jwt.decode(token, { complete: true });
        if (decoded.payload.exp > (new Date().getTime() + 1) / 1000) {
            return true;
        }
        else
            return false;
    }
}
function addHeader(config) {
    if (config && config.headers && (!config.headers.Authorization || config.headers.Authorization === '')) {
        config.headers.Authorization = 'Bearer ' + getUserDetailsObjectFromCookie(UserAuthCookies.ACCESS_TOKEN);
    } else if (config && !config.headers) {
        config.headers = {};
        config.headers.Authorization = 'Bearer ' + getUserDetailsObjectFromCookie(UserAuthCookies.ACCESS_TOKEN);
    } else if (!config) {
        config = {};
        config.headers = {};
        config.headers.Authorization = 'Bearer ' + getUserDetailsObjectFromCookie(UserAuthCookies.ACCESS_TOKEN);
    }
    return config;
}

function addCredentials(config){
    if(config && !config.credentials){
        config.credentials = {};
        config.credentials = 'include';
    }else if(config && config.credentials){
        config.credentials = 'include';
    } else if (!config) {
        config = {};
        config.credentials = {};
        config.credentials = 'include';
    }
    return config;
}

export const unregister = fetchIntercept.register({
    request: function (url, config) {
        if (localStorage.getItem(LocalStorage.IS_TC_ACCEPTED) === "false" && !url.includes('/api/prn-user/user/accept-terms-fe') && !url.includes('image-service/api/prn-wiki-image/terms-of-use') && !url.includes('image-service/api/prn-wiki-image/privacy-policy') && !url.includes('/api/prn-user/user/updateFEUserData/')) {
            return Promise.reject("Please accept terms and Conditions");
        } else {
            if ((process.env.REACT_APP_CURR_ENV === 'qa' || process.env.REACT_APP_CURR_ENV === 'prod') && !url.includes(SITE_NOTICE_URL)) {
                if(!url.includes('/api/prn-user/user/updateFEUserData/') && !url.includes('/api/prn-user/user/accept-terms-fe')){
                    config = addCredentials(config);
                }
                if (checkExpiry())
                    config = addHeader(config);
                else {
                    window.location.href = API.GRP_LOGIN_ENDPOINT + `/oauth2/authorization/custom`;
                }
            }
            return [url, config];
        }
    },

    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error);
    },

    response: function (response) {
        // Modify the response object
        if (!response.ok) {
            response.json().then((data) => {
                AlertNotification("Error", translate(data.message, data.data), "danger", 30000);
            });
        }
        return response;
    },
    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error);
    }
});

