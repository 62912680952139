import React, {Component} from 'react'
import {CTA, ThemeProvider} from '@volkswagen-onehub/components-core'
import translate from '../../../i18n/translate'
import {LocalStorage} from "../../../constants/PRNConstants";

require('../LegalPage.css')

export class DataProtectionStatement extends Component {

  fetchPageForDE = () => {
    return (
      <>
          <div className="legal-page-title">Datenschutzerklärung für das PR-NR Wiki von Volkswagen Nutzfahrzeuge Group
              Retail Portal ("GRP”)
          </div>
          <br/>
          <div className="legal-page-headline">A. Verantwortlicher</div>
          <br/>
          <div className="legal-page-content">Wir freuen uns, dass Sie das PR-Nr Wiki der Volkswagen AG, Berliner Ring
              2, 38440 Wolfsburg, eingetragen im Handelsregister des Amtsgerichts Braunschweig unter der Nr. HRB 100484
              ("Volkswagen AG") besuchen. Im Folgenden informieren wir Sie über die Verarbeitung Ihrer personenbezogenen
              Daten durch die Volkswagen AG im Zusammenhang mit Ihrem Besuch des Wikis.
          </div>
          <br/>
          <div className="legal-page-headline">B. Verarbeitung Ihrer personenbezogenen Daten</div>
          <br/>
          <div className="legal-page-sub-headline">I. Verarbeitung von Protokolldateien</div>
          <br/>
          <div className="legal-page-content">Bei Ihrem Besuch auf unserer Webseite verarbeiten wir die folgenden
              Protokolldateien von Ihnen:<br/><br/>
              1. Eine anonyme Cookie-ID, die keinen Rückschluss auf Ihre IP-Adresse ermöglicht,<br/><br/>
              2. das von Ihnen genutzte Betriebssystem, den von Ihnen genutzten Web-Browser und die von Ihnen
              eingestellte Bildschirmauflösung,<br/><br/>
              3. das Datum und die Uhrzeit Ihres Besuchs,<br/><br/>
              4. die Websites, die Sie bei uns besucht haben und<br/><br/>
              5. die Website, von der Sie uns besuchen.<br/><br/>
              Die Verarbeitung dieser Daten erfolgt gemäß Artikel 6 Abs. 1 lit. f DSGVO aufgrund unseres berechtigten
              Interesses, Ihnen die Webseite ordnungsgemäß anzeigen zu können. Diese Protokolldaten werden nach 42 Tagen
              automatisiert im Rahmen einer Routine gelöscht. <br/><br/>
              Für die Redakteure werden zusätzlich die Workflowdaten (Statuswechsel, Datum, Uhrzeit, User) im
              Redaktionstool neben den o.g. Protokolldateien verarbeitet.
          </div>
          <br/>
          <div className="legal-page-sub-headline">II. Registrierung auf dem Group Retail Portal ("GRP") und
              Redaktionstool inklusive Stammdatenmanagement
          </div>
          <br/>
          <div className="legal-page-content">Das Group Retail Portal (im Folgenden "GRP") ist eine cloud- und
              webbasierte Anwendung, die registrierten Nutzern ("User") Zugang zu Applikationen der Volkswagen Gruppe
              ermöglicht. Bei Registrierung werden folgende personenbezogene Daten von Ihnen durch die Volkswagen AG
              erhoben: <b>Vorname, Nachname, Volkswagen User-ID und geschäftliche E-Mail-Adresse</b>("Stammdaten"). Nach
              bestätigter Registrierung können Sie sich durch Angabe Ihrer E-Mail-Adresse bzw. User-ID und Ihres selbst
              festgelegten Passwortes im GRP anmelden, um das PR-NR Wiki zu nutzen. Registrierte Mitarbeiter des
              Volkswagen Konzerns können sich alternativ auch mit ihrer PKI-Karte anmelden. <br/><br/>
              Die Verarbeitung dieser personenbezogenen Daten zu Zwecken der Registrierung auf dem GRP (inklusive
              Speicherung und Verwaltung Ihrer Stammdaten) erfolgt gemäß Artikel 6 Absatz 1 Buchstabe b DSGVO
              (erforderliche Verarbeitung zur Vertragserfüllung). Ohne die Verarbeitung dieser Daten ist eine
              ordnungsgemäße Registrierung auf und anschließende Nutzung des GRP nicht möglich. Bei Usern, die
              Mitarbeiter der Volkswagen AG sind, erfolgt die Verarbeitung dieser personenbezogenen Daten zu Zwecken der
              Registrierung auf dem GRP (inklusive Speicherung und Verwaltung der Stammdaten) gemäß § 26 Absatz 1
              Bundesdatenschutzgesetz (BDSG; zur Durchführung des Beschäftigungsverhältnisses). <br/><br/>
              Für die Redakteure des PR-NR Wikis werden die genannten "Stammdaten" ebenfalls erhoben. Nach bestätigter
              Registrierung können Sie sich durch Angabe Ihrer E-Mail-Adresse bzw. User-ID und Ihres selbst festgelegten
              Passwortes im Redaktionstool anmelden. Registrierte Mitarbeiter des Volkswagen Konzerns können sich
              alternativ auch mit ihrer PKI-Karte anmelden.
          </div>
          <br/>
          <div className="legal-page-sub-headline">III. Nutzung des GRP und des Redaktionstools und User (Rights)
              Management durch den jeweiligen Arbeitskontext
          </div>
          <br/>
          <div className="legal-page-content">Nach erfolgreicher Registrierung und Anmeldung auf dem GRP und/ oder
              Redaktionstool können Sie im Rahmen Ihres zugewiesenen "Arbeitskontextes" entsprechend Ihren zugewiesenen
              Rollen (z. B. Sales Person, Workshop Manager, Mechanic) arbeiten und kontext- sowie rollenspezifische
              Applikationen nutzen. Der Arbeitskontext entspricht in aller Regel Ihrem Arbeitgeber, z. B. dem Betreiber
              eines Autohaus oder einer Werkstatt, einem Importeur, einer Volkswagen Markengesellschaft, der Volkswagen
              AG.<br/><br/>
              Die konkrete Rollen- und Rechtevergabe an User (User (Rights) Management) erfolgt durch lokale
              Administratoren ("Local Admins") auf Ebene des jeweiligen Arbeitskontextes. Zur Ermöglichung dieses
              kontextspezifischen User (Rights) Managements übergibt die Volkswagen AG Ihr Nutzerprofil innerhalb des
              GRP in die datenschutzrechtliche Verantwortung des jeweiligen Arbeitskontextes. <br/><br/>
              Diese Übergabe erfolgt zur Ermöglichung der ordnungsgemäßen Nutzung des GRP auf Grundlage von Artikel 6
              Absatz 1 Buchstabe b DSGVO (Verarbeitung zur Vertragserfüllung). Bei Usern, die Mitarbeiter der Volkswagen
              AG sind, erfolgt diese Übergabe gemäß § 26 Absatz 1 BDSG zur Durchführung des
              Beschäftigungsverhältnisses. <br/><br/>
              Sobald Sie über das GRP auf angeschlossene Applikationen zugreifen, werden Ihre oben genannten
              personenbezogenen Daten zum Zwecke der Authentifizierung und Autorisierung verschlüsselt an die
              entsprechenden Anbieter dieser Applikationen weitergegeben. Bei diesen Anbietern kann es sich um die
              Volkswagen AG, Markengesellschaften und/oder weitere Dritte handeln. Für diese Weitergabe Ihrer
              personenbezogenen Daten ist ebenfalls ihr jeweiliger Arbeitskontext datenschutzrechtlich verantwortlich.
          </div>
          <br/>
          <div className="legal-page-sub-headline">IV. Weitergabe von Kontaktdaten von GRP-Administratoren</div>
          <br/>
          <div className="legal-page-content">Für User mit administrativen Rechten im GRP (Administratoren) gilt
              zusätzlich, dass ihre hinterlegten Kontaktdaten (Vorname, Nachname, geschäftliche E-Mail-Adresse) an
              Administratoren höherer Administrationsstufen (z. B. an Wholesale-, OEM- oder Group-Administratoren)
              innerhalb des Volkswagen-Vertriebsnetzwerkes zwecks Rollouts neuer Versionen oder Funktionen des GRP oder
              zur Behebung technischer Störungen – sofern erforderlich – weitergegeben werden können. Diese Weitergabe
              bzw. Verarbeitung der personenbezogenen Daten erfolgt zur ordnungsgemäßen Bereitstellung, Instandhaltung
              und bedarfsgerechten Weiterentwicklung des GRP auf Grundlage von Artikel 6 Absatz 1 Buchstabe b DSGVO
              (Verarbeitung zur Vertragserfüllung). Bei Administratoren, die Mitarbeiter der Volkswagen AG sind, erfolgt
              diese Weitergabe bzw. Verarbeitung gemäß § 26 Absatz 1 BDSG zur Durchführung des
              Beschäftigungsverhältnisses.
          </div>
          <br/>
          <div className="legal-page-sub-headline">V. Deaktivierung Ihres Nutzerprofils und Löschung Ihrer Daten</div>
          <br/>
          <div className="legal-page-content">
              Mit Deaktivierung Ihres Nutzerprofils im GRP verlieren Sie den Zugang zu sämtlichen Funktionen des GRP und
              den nachgelagerten Applikationen. Ihre Daten löschen wir in folgenden Fällen, sofern eine weitere
              Verarbeitung aufgrund gesetzlicher Aufbewahrungsfristen nicht erforderlich ist:<br/><br/>
              - 14 Tage nach Deaktivierung Ihres Nutzerprofils;<br/>
              - 2 Tage nach Registrierung, sofern bis dahin keine Verifizierung Ihres Nutzerprofils stattgefunden
              hat;<br/>
              - 14 Tage nach Registrierung, sofern Ihnen bis dahin kein Arbeitskontext zugewiesen wurde;<br/>
              - 183 Tage nach Entzug Ihres letzten Arbeitskontextes, sofern Ihnen bis dahin kein Arbeitskontext erneut
              zugewiesen wurde;<br/>
              - Wenn Sie sich länger als 365 Tage nicht auf dem GRP eingeloggt haben.
          </div>
          <br/>
          <div className="legal-page-sub-headline">VI. Hosting des GRP und Einsatz von Auftragsverarbeitern</div>
          <br/>
          <div className="legal-page-content">Das GRP wird im Auftrag der Volkswagen AG von Amazon Web Services, Inc.
              (AWS) in Deutschland und Irland cloudbasiert gehostet. Die in dieser Datenschutzerklärung genannten
              Verarbeitungstätigkeiten der Volkswagen AG werden entweder direkt durch die Volkswagen AG oder durch die
              von der Volkswagen AG beauftragten Dienstleister IBM Deutschland GmbH, Hexad GmbH, FME AG, GOD mbH,
              re:think Innovations GmbH und die Volkswagen Gebrauchtfahrzeughandels und Service GmbH (VGSG)
              durchgeführt. Mit diesen eingesetzten Dienstleistern bzw. Auftragsverarbeitern wurden die erforderlichen
              Datenschutzverträge abgeschlossen.
          </div>
          <br/>
          <div className="legal-page-headline"> C. Einsatz von Cookies und Tracking Tools</div>
          <br/>
          <div className="legal-page-sub-headline">I. Allgemeine Informationen</div>
          <br/>
          <div className="legal-page-content">Die Volkswagen AG setzt auf ihren Webseiten verschiedene Cookies ein.
              Cookies sind kleine Dateien mit Konfigurationsinformationen, die auf Ihrem Endgerät gespeichert werden.
              Cookies lassen sich grundsätzlich in drei Kategorien unterteilen. Es gibt Cookies, die für die
              Funktionalität der Webseite unerlässlich sind (sog. Funktionscookies), Cookies, die den Komfort eines
              Webseitenbesuchs erhöhen und z. B. Ihre Spracheinstellungen speichern (sog. Komfortcookies) und Cookies,
              aufgrund derer ein pseudonymisiertes Nutzungsprofil erstellt wird (sog. Trackingcookies). <br/><br/>
              Die Volkswagen AG setzt auf ihren Webseiten verschiedene Cookies ein. Cookies sind kleine Dateien mit
              Konfigurationsinformationen, die auf Ihrem Endgerät gespeichert werden. Cookies lassen sich grundsätzlich
              in drei Kategorien unterteilen. Es gibt Cookies, die für die Funktionalität der Webseite unerlässlich sind
              (sog. Funktionscookies), Cookies, die den Komfort eines Webseitenbesuchs erhöhen und z. B. Ihre
              Spracheinstellungen speichern (sog. Komfortcookies) und Cookies, aufgrund derer ein pseudonymisiertes
              Nutzungsprofil erstellt wird (sog. Trackingcookies). <br/><br/>
              Neben Funktionscookies werden keine weiteren Cookies (insbesondere Komfort- und Trackingcookies) auf dem
              GRP eingesetzt. <br/><br/>
              Weitere Informationen zu unseren Cookies können der Volkswagen Cookie-Richtlinie unter <CTA
                  emphasis="none" tag="a" href={'https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html'}
                  isInline={true}>
                  https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html</CTA> sowie dem folgenden
              Abschnitt II. entnommen werden.
          </div>
          <br/>
          <div className="legal-page-sub-headline">II. Matomo (Piwik)</div>
          <br/>
          <div className="legal-page-content">Diese Webseite nutzt die Software des Webanalyse-Anbieters Matomo, um die
              Nutzerzugriffe auf das GRP anonymisiert auszuwerten. Bei jedem Seitenaufruf wird Ihre IP-Adresse an einen
              Server mit der Webanalyse-Software Matomo weitergeleitet. Der Server wird vom IT-Dienstleister makandra
              GmbH, Augsburg, im Auftrag der Volkswagen AG betrieben. Auf dem Server mit der Webanalyse-Software wird
              Ihre IP-Adresse unmittelbar nach der Übertragung anonymisiert. Dazu wird Ihre IP-Adresse um die letzten 16
              Bits oder 2 Oktetten bei der Verwendung des Internet Protokolls Version 4 (IPv4) bzw. um 80 Bits oder 10
              Oktetten bei der Verwendung des Internet Protokoll Version 6 (IPv6) gekürzt.<br/><br/>
              Eine Personenbeziehbarkeit der anonymisierten IP-Adresse ist damit ausgeschlossen. Die Webanalyse-Software
              verarbeitet anschließend die anonymisierte IP-Adresse zur statistischen Auswertung der Zugriffe.<br/><br/>
              Die Anonymisierung Ihrer IP-Adresse zum Zweck der statistischen Auswertung der Zugriffe erfolgt auf der
              Grundlage unseres berechtigten Interesses, die Nutzung der Webseite auszuwerten, um die Inhalte
              entsprechend den Anforderungen der User der Webseite zu optimieren und weiterzuentwickeln (Artikel 6
              Absatz 1 Buchstabe f DSGVO).<br/><br/>
              Widerspruch zur Anonymisierung Ihrer IP-Adresse zum Zweck der statistischen Auswertung<br/><br/>
              Sie können dieser Datenverarbeitung jederzeit mit Wirkung für die Zukunft auch durch einen Klick auf den
              folgenden Link widersprechen:
              <CTA emphasis="none" tag="a"
                   href={'https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de'}
                   isInline={true}>
                  https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de</CTA>
          </div>
          <br/>
          <div className="legal-page-headline"> D. Ihre Rechte</div>
          <br/>
          <div className="legal-page-content">Ihre nachfolgenden Rechte können Sie gegenüber der Volkswagen AG jederzeit
              unentgeltlich geltend machen. Weitere Informationen zur Wahrnehmung Ihrer Rechte finden Sie unter
              Abschnitt E "Ihre Ansprechpartner".<br/>
              <br/><br/> <span className="legal-page-sub-definition">Auskunftsrecht: {"  "}</span>Sie haben das Recht,
              von uns Auskunft über die Verarbeitung Ihrer personenbezogenen Daten zu erhalten.
              <br/><br/><span className="legal-page-sub-definition">Berichtigungsrecht: {"  "}</span>Sie haben das
              Recht, von uns die Berichtigung Sie betreffender unrichtiger bzw. unvollständiger personenbezogener Daten
              zu verlangen.
              <br/><br/><span className="legal-page-sub-definition">Recht auf Löschung: {"  "}</span>Sie haben das
              Recht, bei Vorliegen der in Artikel 17 DSGVO genannten Voraussetzungen, die Löschung Ihrer Daten zu
              verlangen. Danach können Sie beispielsweise die Löschung Ihrer Daten verlangen, soweit diese für die
              Zwecke, für die sie erhoben wurden, nicht mehr notwendig sind. Außerdem können Sie Löschung verlangen,
              wenn wir Ihre Daten auf der Grundlage Ihrer Einwilligung verarbeiten und Sie diese Einwilligung
              widerrufen.
              <br/><br/><span
                  className="legal-page-sub-definition">Recht auf Einschränkung der Verarbeitung: {"  "}</span> Sie
              haben das Recht, die Einschränkung der Verarbeitung Ihrer Daten zu verlangen, wenn die Voraussetzungen des
              Artikel 18 DSGVO vorliegen. Dies ist beispielsweise der Fall, wenn Sie die Richtigkeit Ihrer Daten
              bestreiten. Für die Dauer der Überprüfung der Richtigkeit der Daten können Sie dann die Einschränkung der
              Verarbeitung verlangen.
              <br/><br/><span className="legal-page-sub-definition">Widerspruchsrecht: {"  "}</span>Sofern die
              Verarbeitung auf einem überwiegenden Interesse oder Ihre Daten zum Zwecke der Direktwerbung genutzt
              werden, haben Sie das Recht, der Verarbeitung Ihrer Daten zu widersprechen. Ein Widerspruch ist zulässig,
              wenn die Verarbeitung entweder im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt oder
              aufgrund eines berechtigten Interesses der Volkswagen AG oder eines Dritten erfolgt. Im Falle des
              Widerspruchs bitten wir Sie, uns Ihre Gründe mitzuteilen, aus denen Sie der Datenverarbeitung
              widersprechen. Daneben haben Sie das Recht, der Datenverarbeitung zu Zwecken der Direktwerbung zu
              widersprechen. Das gilt auch für Profiling, soweit es mit der Direktwerbung zusammenhängt.
              <br/><br/><span className="legal-page-sub-definition">Recht auf Datenübertragbarkeit: {"  "}</span>Sofern
              die Datenverarbeitung auf der Grundlage einer Einwilligung oder einer Vertragserfüllung beruht und diese
              zudem unter Einsatz einer automatisierten Verarbeitung erfolgt, haben Sie das Recht, Ihre Daten in einem
              strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese an einen anderen
              Datenverarbeiter zu übermitteln.
              <br/><br/><span className="legal-page-sub-definition">Widerrufsrecht: {"  "}</span>Sofern die
              Datenverarbeitung auf einer Einwilligung beruht, haben Sie das Recht, die Datenverarbeitung im Rahmen
              einer Einwilligung mit Wirkung für die Zukunft jederzeit kostenlos widerrufen.
              <br/><br/><span className="legal-page-sub-definition">Beschwerderecht: {"  "}</span>Sie haben außerdem das
              Recht, sich bei einer Aufsichtsbehörde (z. B. bei der Landesbeauftragten für den Datenschutz
              Niedersachsen) über unsere Verarbeitung Ihrer Daten zu beschweren.
          </div>
          <br/>
          <div className="legal-page-headline"> E. Ihre Ansprechpartner</div>
          <br/>
          <div className="legal-page-content">
              <span className="legal-page-sub-definition">Ansprechpartner für die Ausübung Ihrer Rechte</span><br/><br/>
              Die Ansprechpartner für die Ausübung Ihrer Rechte sowie weitergehende Informationen finden Sie auf der
              folgenden Webseite der Volkswagen AG:
              <CTA emphasis="none" tag="a" href="https://datenschutz.volkswagen.de"
                   isInline={true}>https://datenschutz.volkswagen.de</CTA> <br/><br/>
              <span className="legal-page-sub-definition">Datenschutzbeauftragter</span><br/><br/>
              Unser Datenschutzbeauftragter steht Ihnen als Ansprechpartner für datenschutzbezogene Anliegen zur
              Verfügung:<br/><br/>
              Datenschutzbeauftragter der Volkswagen AG<br/><br/>
              Berliner Ring 2, 38440 Wolfsburg<br/><br/>
              <CTA emphasis="none" tag="a" isInline={true}>datenschutz@volkswagen.de</CTA><br/><br/>
              Stand: Mai 2021<br/><br/>
          </div>
          <div className="imprint-copyright">© Volkswagen 2021</div>
      </>
    )
  }


  fetchPageForEN = () => {
    return (
      <>
        <div class="legal-page-title">Privacy Policy for the PR number wiki of the Volkswagen Commercial Vehicles Group Retail Portal ("GRP”)</div> <br />
        <div class="legal-page-headline">A. Controller</div> <br />
        <div class="legal-page-content">Thank you for using the PR number wiki operated by Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, Germany, entered
          in the register of companies of Braunschweig District Court under HRB 100484 ("Volkswagen AG”). In the following, we will provide you with information
          on how Volkswagen AG processes the personal data which is collected when you use the wiki.</div><br />
        <div class="legal-page-headline">B. Processing of your personal data</div> <br />
        <div class="legal-page-sub-headline">I. Processing of log files</div> <br />
        <div class="legal-page-content">When you visit our website, we process the following log files relating to you:<br /><br />
          1. An anonymous cookie ID that does not disclose information regarding your IP address<br /><br />
          2. The operating system and web browser you are using, and the screen resolution<br /><br />
          3. The date and time of your visit<br /><br />
          4. Which of our websites you have visited<br /><br />
          5. The website from which you are visiting us <br /><br />
          The processing of this data takes place in accordance with Article 6(1)(f) GDPR on the basis of our legitimate interest in being able to display
          the website properly. This log data is automatically deleted after 42 days as part of a routine. <br /><br />
          For editors, the workflow data (status change, date, time, user) in the editorial tool is also processed in addition to the above log files.</div><br />
        <div class="legal-page-sub-headline">II. Registration on the Group Retail Portal ("GRP”) and editorial tool including master data management</div> <br />
        <div class="legal-page-content">The Group Retail Portal (hereinafter "GRP”) is a cloud- and web-based application that allows registered users ("users”)
          access to Volkswagen Group applications. When you register, Volkswagen AG collects the following personal data from you: first name, surname,
          Volkswagen user ID and business email address ("master data”). After your registration is confirmed, you can log into the GRP by entering your email
          address or user ID in addition to a password of your own choosing, and use the PR number wiki. Alternatively, registered employees of the Volkswagen Group
          can log in with their PKI card. <br /><br />
          This personal data is processed for the purposes of registration on the GRP (including the storage and management of your master data) in accordance
          with Article 6(1)(b) GDPR (processing for the performance of a contract). If this data is not processed, it is not possible to register correctly and
          then use the GRP. For users who are employees of Volkswagen AG, this personal data is processed for the purposes of registration on the GRP (including
          the storage and management of master data) in accordance with section 26.1 of the Federal Data Protection Act (BDSG) on establishing the employment relationship. <br /><br />
          The aforementioned master data is also collected in the case of editors of the PR number wiki. After your registration is confirmed, you can log into
          the editorial tool by entering your email address or user ID in addition to a password of your own choosing. Alternatively, registered employees of the
          Volkswagen Group can log in with their PKI card.</div><br />
        <div class="legal-page-sub-headline">III. Use of the GRP and editorial tool and user (rights) management by work context</div><br />
        <div class="legal-page-content">After successfully registering and logging into the GRP and/or editorial tool, you can work as part of your "work context”
          according to your allocated roles (e.g. sales person, workshop manager, mechanic) and use applications for your specific roles and context. The work context
          generally corresponds to your employer, for example the dealership or workshop operator, an importer, a Volkswagen brand company or Volkswagen AG. <br /><br />
          The specific allocation of roles and rights to users (user (rights) management) is carried out by local administrators ("local admins”) on the work context
          level. To enable this context-specific user (rights) management, Volkswagen AG hands over responsibility for the data protection of your user profile in the
          GRP to your work context. <br /><br />
          This transfer takes place to enable correct use of the GRP on the basis of Article 6(1)(b) GDPR (processing for the performance of a contract). For users who
          are employees of Volkswagen AG, this transfer takes place in accordance with section 26.1 BDSG on establishing the employment relationship. <br /><br />
          As soon as you access the connected applications via the GRP, your personal data mentioned above is sent in encrypted form to the providers of these applications
          for the purposes of authentication and authorisation. These providers may be Volkswagen AG, brand companies and/or other third parties. Your work context is also
          responsible under data protection law for this transfer of your personal data.</div><br />
        <div class="legal-page-sub-headline">IV. Transfer of contact data of GRP administrators</div><br />
        <div class="legal-page-content">For users with administrative rights in the GRP (administrators), the contact data stored therein (first name, surname, business
          email address) may also be passed on when necessary to higher-level administrators (e.g. wholesale, OEM or Group administrators) within the Volkswagen sales network
          for the purpose of rolling out new versions or functions of the GRP. This transfer and processing of personal data is carried out in order to properly provide, maintain
          and, where appropriate, enhance the GRP on the basis of Article 6(1)(b) GDPR (processing for the performance of a contract). For administrators who are employees of
          Volkswagen AG, this transfer and processing takes place in accordance with section 26.1 BDSG on establishing the employment relationship.</div><br />
        <div class="legal-page-sub-headline">V. Deactivation of your user profile and deletion of your data</div><br />
        <div class="legal-page-content">
          When your user profile is deactivated in the GRP, you lose access to all functions of the GRP and its downstream applications. We will delete your data in the
          following cases, provided that further processing is not required due to statutory retention periods:<br /><br />
          -  14 days after deactivation of your user profile<br />
          - 2 days after registration, if your user profile has not been verified by then<br />
          - 14 days after registration, if no work context has been assigned to you by then<br />
          - 183 days after removal of your last work context, if no new work context has been allocated to you by then<br />
          - If you have not logged into the GRP for more than 365 days
        </div><br />
        <div class="legal-page-sub-headline">VI. Hosting of the GRP and use of processors</div><br />
        <div class="legal-page-content">The GRP is hosted for Volkswagen AG by Amazon Web Services, Inc. (AWS) in Germany and is cloud-based in Ireland. The processing
          activities of Volkswagen AG that are mentioned in this privacy policy are either carried out directly by Volkswagen AG or by the service providers commissioned
          by it IBM Deutschland GmbH, Hexad GmbH, FME AG, GOD mbH, re:think Innovations GmbH and Volkswagen Gebrauchtfahrzeughandels und Service GmbH (VGSG). The necessary
          data protection agreements have been concluded with these service providers and processors.</div><br />
        <div class="legal-page-headline"> C. Use of cookies and tracking tools</div> <br />
        <div class="legal-page-sub-headline">I. General information</div><br />
        <div class="legal-page-content">Volkswagen AG uses various cookies on its websites. Cookies are small files containing configuration information that are saved
          on your terminal device. Cookies can essentially be divided into three categories. There are cookies that are essential for the functionality of the website (functional
          cookies), cookies that make it easier to use a website and, for example by saving your language settings (convenience cookies), and cookies on the basis of which a
          pseudonymised user profile is created (tracking cookies). <br /><br />
          Only functional cookies are used on the GRP. While you are active on the website, a session cookie (functional cookie) is temporarily stored in your terminal
          device’s memory. This contains a session ID so that, for example, you do not have to log in again to go to a different page. Session cookies are deleted when
          you log off, or cease to be valid as soon as your session has automatically expired. The processing of your personal data required for this purpose is based on
          Article 6(1)(b) GDPR ("performance of a contract”). <br /><br />
          Other than functional cookies, no cookies (particularly convenience and tracking cookies) are used on the GRP. <br /><br />
          More information about our cookies can be found in the Volkswagen cookie guidelines at <CTA emphasis="none" tag="a" href={'https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html'} isInline={true} >
            https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html</CTA> and in section II below.</div><br />
        <div class="legal-page-sub-headline">II. Matomo (Piwik)</div><br />
        <div class="legal-page-content">This website uses software from the web analysis provider Matomo to anonymously evaluate user accesses to the GRP. Every time
          you access a page, your IP address is sent to a server with the Matomo web analysis software. The server is operated by the IT service provider makandra GmbH,
          Augsburg, on behalf of Volkswagen AG. Your IP address is anonymised immediately after transfer to the server with the web analysis software. To do this, your IP
          address has the last 16 bits or 2 octets removed when using Internet Protocol version 4 (IPv4) or 80 bits or 10 octets when using Internet Protocol version 6 (IPv6). <br /><br />
          This makes it impossible to identify the person behind the anonymised IP address. The web analysis software then processes the anonymised IP address for statistical
          evaluation of accesses. <br /><br />
          The anonymisation of your IP address for statistical evaluation of accesses is based on our legitimate interest in analysing the use of the website in order to optimise
          the content and enhance the website for the benefit of its users (Article 6(1)(f) GDPR). <br /><br />
          Objection to anonymisation of your IP address for statistical evaluation<br /><br />
          You can also object to this data processing at any time with future effect by clicking the following link:
          <CTA emphasis="none" tag="a" href={'https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de'} isInline={true}>
            https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=de</CTA></div><br />
        <div class="legal-page-headline"> D. Your rights</div> <br />
        <div class="legal-page-content">You may exercise the following rights vis-a-vis Volkswagen AG at any time free of charge. Further information on asserting your rights is provided in
          section E "Your contacts”.<br />
          <br /><br /> <span class="legal-page-sub-definition">Right to information: {"  "}</span>You have the right to obtain information from us about how we process your personal data.
          <br /><br /><span class="legal-page-sub-definition">Right to rectification: {"  "}</span>You have the right to request that we rectify any of your personal data that may
          be inaccurate or incomplete.
          <br /><br /><span class="legal-page-sub-definition">Right to erasure: {"  "}</span>You have the right to have your data deleted, provided the prerequisites stated in Article 17
          GDPR have been met. According to this, you can demand, for example, that your data is erased if it is no longer necessary for the purposes for which it was collected. In addition,
          you can demand erasure if we process your data on the basis of your consent and you withdraw this consent.
          <br /><br /><span class="legal-page-sub-definition">Right to restriction of processing: {"  "}</span> You have the right to request the restriction of the processing of your
          personal data if the prerequisites specified under Article 18 GDPR have been met. This is the case, for example, if you dispute the accuracy of your data. You can demand
          restriction of processing for the period during which the data is being checked.
          <br /><br /><span class="legal-page-sub-definition">Right to object: {"  "}</span>You have the right to object to the processing of your data if the processing is based on an
          overriding interest or if your data is used for the purpose of direct marketing. An objection is permitted if processing is conducted in either the public interest or for the
          exercise of official authority, or if it is conducted for a legitimate interest of Volkswagen AG or of a third party. If you object to the processing of your data, please notify
          us of the reasons for your objection. Furthermore, you have the right to object to data processing for the purposes of direct marketing. This also applies to profiling where this
          is connected to direct marketing.
          <br /><br /><span class="legal-page-sub-definition">Right to data portability: {"  "}</span>You have the right to receive your data in a structured, commonly used and
          machine-readable format and to transmit that data to another processor, provided that the data processing is based on consent or performance of a contract and an automated
          means of processing is used.
          <br /><br /><span class="legal-page-sub-definition">Right of withdrawal: {"  "}</span>Provided data processing is based on your consent, you have the right to withdraw that
          consent to the processing of your personal data free of charge at any time, and with effect for the future.
          <br /><br /><span class="legal-page-sub-definition">Right to lodge a complaint: {"  "}</span>You also have the right to lodge a complaint with a supervisory authority (e.g.
          the Data Protection Commissioner for the State of Lower Saxony) about our processing of your data.
        </div><br />
        <div class="legal-page-headline"> E. Your contacts</div> <br />
        <div class="legal-page-content">
          <span class="legal-page-sub-definition">Contact persons for exercising your rights</span><br /><br />
          The contact persons for exercising your rights and for further information can be found on the following Volkswagen AG website:
          <CTA emphasis="none" tag="a" href="https://datenschutz.volkswagen.de" isInline={true} >https://datenschutz.volkswagen.de</CTA> <br /><br />
          <span class="legal-page-sub-definition">Data Protection Officer</span><br /><br />
          Our Data Protection Officer is available to you as a contact person for any concerns related to privacy:<br /><br />
          Volkswagen AG Data Protection Officer<br /><br />
          Berliner Ring 2, 38440 Wolfsburg<br /><br />
          <CTA emphasis="none" tag="a" isInline={true} >datenschutz@volkswagen.de</CTA><br /><br />
          Version dated: May 2021<br /><br />
        </div>
        <div class="imprint-copyright">© Volkswagen 2021</div>
      </>
    )
  }


  fetchPageForFR = () => {
    return (
      <>
        <div class="legal-page-title">Politique de confidentialité du PR-NR Wiki de Group Retail Portal Volkswagen Véhicules Utilitaires (« GRP ») </div> <br />
        <div class="legal-page-headline">A. Responsable</div> <br />
        <div class="legal-page-content">Nous nous réjouissons de votre visite sur le PR-NR Wiki de Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, inscrite au registre du commerce du tribunal d’instance de Brunswick sous le numéro HRB 100484 (« Volkswagen AG »). Vous trouverez ci-après des information sur le traitement de vos données à caractère personnel par Volkswagen AG dans le cadre de votre visite du Wiki.</div><br />
        <div class="legal-page-headline">B. Traitement de vos données à caractère personnel</div> <br />
        <div class="legal-page-sub-headline">I. Traitement des fichiers journaux</div> <br />
        <div class="legal-page-content">À l’occasion de votre visite de notre site Internet, nous traitons les fichiers journaux suivants se rapportant à vous : <br /><br />
          1. un ID de cookie anonyme qui ne permet en aucun cas de tracer votre adresse IP, <br /><br />
          2. le système d’exploitation et le navigateur Web que vous avez utilisés et la résolution d’écran que vous avez définie, <br /><br />
          3. la date et l’heure de votre visite, <br /><br />
          4. les sites Internet que vous avez visités chez nous et<br /><br />
          5. le site Internet à partir duquel vous nous visitez. <br /><br />
          Le traitement de ces données se déroule conformément à l’article 6 § 1 lettre f du RGPD compte tenu de notre intérêt légitime à pouvoir vous présenter correctement le site Internet. Ces fichiers journaux sont automatiquement effacés après 42 jours dans le cadre d’une routine.
          Dans le cas des rédacteurs, nous traitons également les données de flux d’activités (changement d’état, date, heure, utilisateur) dans l’outil de rédaction, en plus des fichiers journaux ci-dessus mentionnés. </div><br />
        <div class="legal-page-sub-headline">II. Enregistrement sur le Group Retail Portal (« GRP ») et outil de rédaction, y compris gestion des données de base</div> <br />
        <div class="legal-page-content">Le Group Retail Portal (ci-après « GRP ») est une application basée sur le cloud et Internet qui permet aux utilisateurs enregistrés (ci-après « User ») d’accéder à des applications du Groupe Volkswagen. Volkswagen AG collecte les données à caractère personnel vous concernant lors de votre enregistrement : prénom, nom, User-ID Volkswagen et adresse e-mail professionnelle (« données de base »). Dès que l’enregistrement est confirmé, vous pouvez vous connecter en saisissant votre adresse e-mail ou votre User-ID, ainsi que le mot de passe que vous avez défini dans le GRP afin d’utiliser le Wiki PR-NR Wiki. Les collaborateurs enregistrés du Groupe Volkswagen peuvent également se connecter à l’aide de leur carte PKI. <br /><br />
          Le traitement de ces données à caractère personnel aux fins d’enregistrement dans le GRP (y compris du stockage et de la gestion de vos données de base) se déroule conformément à l’article 6 § 1 lettre b du RGPD (traitement obligatoire pour l’exécution d’un contrat). Sans l’enregistrement de ces données, un enregistrement en bonne et due forme dans le GRP et une utilisation de celui-ci seront impossibles. Dans le cas d’utilisateurs qui sont des collaborateurs de Volkswagen AG, le traitement de ces données à caractère personnel a lieu aux fins d’enregistrement dans le GRP (y compris de stockage et de gestion des données de base) conformément au § 26 alinéa 1 de la Loi fédérale sur la protection des données (BDSG ; pour l’exécution du contrat de travail). <br /><br />
          Les « données de base » sont également collectées pour les rédacteurs du PR-NR Wiki. Dès que l’enregistrement est confirmé, vous pouvez vous connecter dans l’outil de rédaction en saisissant votre adresse e-mail ou votre User-ID, ainsi que le mot de passe que vous avez défini. Les collaborateurs enregistrés du Groupe Volkswagen peuvent également se connecter à l’aide de leur carte PKI.</div><br />
        <div class="legal-page-sub-headline">III. Utilisation du GRP et de l’outil de rédaction et User (Rights) Management par le contexte professionnel concerné</div><br />
        <div class="legal-page-content">Après un enregistrement et une connexion réussis dans le GRP et/ou l’outil de rédaction, vous pouvez travailler dans le cadre du « contexte professionnel » qui vous est attribué selon les fonctions qui vous sont attribuées (par ex. Vendeur, Chef d’atelier, Mécanicien) et utiliser des applications spécifiques à ce contexte et à ces fonctions. Le contexte professionnel désigne en général votre employeur, par ex. le gérant d’un garage ou d’un atelier, un importateur, une concession Volkswagen, de Volkswagen AG.
          Des administrateurs locaux (« Local Admins ») procèdent à l’octroi concret de fonctions et de droits à un User (User (Rights) Management, Gestion des droits des utilisateurs) au niveau du contexte professionnel concerné. Pour permettre cette gestion (User (Rights) Management), Volkswagen AG remet votre profil d’utilisateur défini dans le GRP au responsable de la protection des données du contexte professionnel concerné.
          Cette remise a pour but de permettre une utilisation correcte du GRP conformément à l’article 6 § 1 lettre b du RGPD (traitement pour l’exécution d’un contrat). Dans le cas d’utilisateurs qui sont des collaborateurs de Volkswagen AG, cette remise a lieu conformément au § 26 paragraphe 1 de la BDSG pour l’exécution du contrat de travail.
          Dès que vous accédez à des applications associées via le GRP, vos données à caractère personnel ci-dessus mentionnées sont codées aux fins d’authentification et d’autorisation et transmises aux fournisseurs de ces applications. Ces fournisseurs peuvent être Volkswagen AG, des concessionnaires et/ou d’autres tiers. Votre contexte professionnel est également responsable en vertu du droit sur la protection des données de la transmission de vos données à caractère personnel.</div><br />
        <div class="legal-page-sub-headline">IV. Transmission de données de contact d’administrateurs GRP</div><br />
        <div class="legal-page-content">Les Users détenant des droits administratifs dans le GRP (administrateurs) peuvent en outre transmettre les coordonnées qu’ils ont saisies (prénom, nom, adresse e-mail professionnelle) à des administrateurs de niveau supérieur (par ex. administrateurs Wholesale, OEM ou du Groupe) dans le réseau commercial de Volkswagen en vue du lancement de nouvelles versions ou fonctions du GRP ou de l’élimination de problèmes techniques, si nécessaire. Cette transmission ou ce traitement des données personnes a pour objet la préparation correcte, la maintenance et le développement lié aux besoins du GRP conformément à l’article 6 § 1 lettre b du RGPD (traitement pour l’exécution d’un contrat). Dans le cas d’administrateurs qui sont des collaborateurs de Volkswagen AG, cette transmission a lieu conformément au § 26 paragraphe 1 de la BDSG pour l’exécution du contrat de travail.</div><br />
        <div class="legal-page-sub-headline">V. Désactivation de votre profil d’utilisateur et effacement de vos données</div><br />
        <div class="legal-page-content">La désactivation de votre profil d’utilisateur dans le GRP entraîne la perte d’accès à toutes les fonctions du GRP et aux applications en aval. Nous effacerons vos données dans les cas suivants, si aucun autre traitement n’est obligatoire en vertu des délais de conservation légaux : <br /><br />
          - 14 jours après la désactivation de votre profil d’utilisateur ; <br /><br />
          - 2 jours après l’enregistrement, si aucune vérification de votre profil d’utilisateur n’a eu lieu jusque-là ; <br /><br />
          - 14 jours après l’enregistrement, si aucun contexte professionnel ne vous a été attribué jusqu’à présent ; <br /><br />
          - 183 jours après le retrait de votre dernier contexte professionnel, si aucun nouveau contexte professionnel ne vous a été attribué jusque-là ; <br /><br />
          - Si vous ne vous êtes pas connecté au GRP depuis plus de 365 jours.</div><br />
        <div class="legal-page-sub-headline">VI. Hébergement du GRP et engagement de sous-traitants</div><br />
        <div class="legal-page-content">Le GRP est hébergé sur le cloud pour le compte de Volkswagen AG par Amazon Web Services, Inc. (AWS) en Allemagne et en Irlande. Les activités de traitement de Volkswagen AG énumérées dans la politique de confidentialité sont menées directement par Volkswagen AG ou par les fournisseurs de services engagés par Volkswagen AG, à savoir IBM Deutschland GmbH, Hexad GmbH, FME AG, GOD mbH, re:think Innovations GmbH et Volkswagen Gebrauchtfahrzeughandels und Service GmbH (VGSG). Les contrats de protection des données nécessaires sont conclus avec ces fournisseurs de services ou sous-traitants engagés.</div><br />
        <div class="legal-page-headline"> C. Utilisation de cookies et d’outils de suivi</div> <br />
        <div class="legal-page-sub-headline">I. Informations générales</div><br />
        <div class="legal-page-content">Volkswagen AG utilise différents cookies sur ses sites Internet. Les cookies sont des petits fichiers stockés sur votre appareil contenant des informations de configuration. Les cookies peuvent être classés dans trois catégories différentes. Il existe des cookies qui sont indispensables pour le fonctionnement du site Internet (appelés cookies fonctionnels), des cookies qui augmentent le confort de consultation du site Internet et qui, par ex., enregistrent vos paramètres de langue (appelés cookies de confort) et des cookies (appelés cookies de suivi) qui permettent de créer un profil d’utilisation pseudonymisé. <br /><br />
          Les cookies de fonctions sont exclusivement utilisés sur le GRP. Lorsque vous êtes actif sur la page Internet, un cookie dit temporaire de session (cookie de fonctions) est stocké sur votre terminal. Ce cookie comprend un identifiant de session pour éviter, par exemple, de devoir vous réenregistrer à chaque changement de page. Les cookies de session sont supprimés lors de votre déconnexion ou ne sont plus valides dès que la session expire. Le traitement de vos données à caractère personnel nécessaire à cet effet s’appuie sur l’article 6 § 1 lettre b du RGPD (« Exécution d’un contrat »). <br /><br />
          D’autres cookies (notamment de confort et de suivi) sont utilisés sur le GRP en plus des cookies de fonctions. <br /><br />
          Vous trouverez de plus amples informations sur nos cookies dans la Directive en matière de cookies de Volkswagen à l’adresse <CTA emphasis="none" tag="a" href={'https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html'} isInline={true} >https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html</CTA> ainsi que dans la Section II suivante.</div><br />
        <div class="legal-page-sub-headline">II. Matomo (Piwik)</div><br />
        <div class="legal-page-content">Ce site Internet utilise le logiciel du fournisseur d’analyse du Web Matomo pour évaluer de manière anonymisée les accès d’utilisateurs au GRP. À chaque ouverture de page, votre adresse IP est transmise à un serveur à l’aide du logiciel d’analyse du Web Matomo. Le serveur est géré par le fournisseur de services informatiques makandra GmbH, Augsbourg, pour le compte de Volkswagen AG. Votre adresse IP est anonymisée immédiatement après la transmission sur le serveur avec le logiciel d’analyse du Web. Votre adresse IP est amputée à cet effet des 16 derniers bits ou 2 octets lors de l’utilisation du protocole Internet version 4 (IPv4) ou de 80 bits ou 10 octets lors de l’utilisation du protocole Internet version 6 (IPv6). <br /><br />
          Une identification de l’adresse IP anonymisée est ainsi exclue. Le logiciel d’analyse du Web traite ensuite l’adresse IP anonymisée aux fins d’évaluation statistique des accès. <br /><br />
          L’anonymisation de votre adresse IP aux fins d’évaluation statistique de vos accès se déroule en vertu de notre intérêt légitime d’évaluer l’utilisation de la page Internet pour optimiser les contenus conformément aux demandes des utilisateurs du site Internet et de continuer à les développer (article 6 § 1 lettre f du RGPD). <br /><br />
          Opposition à l’anonymisation de votre adresse IP aux fins d’évaluation statistique <br /><br />
          Vous pouvez vous opposer à tout moment à ce traitement de données avec effet pour l’avenir en cliquant sur le lien suivant : <CTA emphasis="none" tag="a" href={'https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de'} isInline={true}> https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de.</CTA>  </div><br />
        <div class="legal-page-headline"> D. Vos droits</div> <br />
        <div class="legal-page-content">Vous pouvez à tout moment faire valoir gratuitement les droits suivants vis-à-vis de Volkswagen AG. Vous trouverez de plus amples informations sur la protection de vos droits dans la section E « Vos interlocuteurs ».<br />
          <span class="legal-page-sub-definition">Droit d’accès: {"  "}</span>vous avez le droit de nous demander des renseignements sur le traitement de vos données à caractère personnel.
          <br /><br /> <span class="legal-page-sub-definition">Droit de rectification: {"  "}</span>vous avez le droit d’exiger de nous une rectification de données à caractère personnel erronées ou incomplètes vous concernant.
          <br /><br /><span class="legal-page-sub-definition">Droit d’effacement: {"  "}</span> vous avez le droit d’exiger l’effacement de vos données lorsque les conditions prévues à l’art. 17 du RGPD sont remplies. Vous pouvez, par exemple, demander la suppression de vos données lorsqu’elles ne sont plus nécessaires aux fins pour lesquelles elles avaient été collectées. Vous pouvez également exiger l’effacement de vos données lorsque nous traitons vos données sur la base de votre consentement, et que vous le révoquez.
          <br /><br /><span class="legal-page-sub-definition">Droit de limitation du traitement: {"  "}</span>  vous avez le droit d’exiger la limitation du traitement de vos données, si les conditions de l’article 18 du RGPD sont remplies. Par exemple, si vous contestez l’exactitude de vos données. Durant la vérification de l’exactitude des données, vous pouvez exiger la limitation du traitement.
          <br /><br /><span class="legal-page-sub-definition">Droit d’opposition: {"  "}</span> lorsque le traitement s’effectue au nom d’un intérêt prépondérant ou que vos données sont utilisées à des fins de marketing direct, vous avez le droit de vous opposer au traitement de vos données. Une opposition est admissible lorsque le traitement des données s’effectue au nom de l’intérêt public, dans le cadre de l’exercice de l’autorité publique, ou dans l’intérêt justifié de Volkswagen AG ou d’un tiers. Nous vous prions de nous communiquer les raisons pour lesquelles vous vous opposez au traitement des données. En outre, vous avez le droit de vous opposer au traitement des données à des fins de marketing direct. Cela vaut aussi pour le profilage s’il est en lien avec le marketing direct.
          <br /><br /><span class="legal-page-sub-definition">Droit à la portabilité des données: {"  "}</span>lorsque le traitement des données repose sur un consentement ou sur l’exécution d’un contrat et qu’il s’effectue en outre de manière automatisée, vous avez le droit de demander que vos données vous soient remises sous un format structuré, courant et lisible par une machine et de transmettre ces données à un autre organisme de traitement de données.
          <br /><br /><span class="legal-page-sub-definition">Droit de révocation: {"  "}</span>lorsque le traitement des données repose sur un consentement, vous avez le droit de révoquer à tout moment et gratuitement votre consentement au traitement de vos données, avec effet pour l’avenir.
          <br /><br /><span class="legal-page-sub-definition">Droit d’appel: {"  "}</span>vous avez également le droit de faire appel à une autorité de contrôle (p. ex. le commissaire à la protection des données de Basse-Saxe) concernant le traitement que nous apportons à vos données.
        </div><br />
        <div class="legal-page-headline"> E. Vos interlocuteurs</div> <br />
        <div class="legal-page-content">
          <span class="legal-page-sub-definition">Interlocuteurs pour l’exercice de vos droits</span><br /><br />
          Vous trouverez les interlocuteurs pour l’exercice de vos droits et de plus amples informations sur le site Internet suivant de Volkswagen AG : <CTA emphasis="none" tag="a" href={'https://datenschutz.volkswagen.de'} isInline={true} >https://datenschutz.volkswagen.de.</CTA> <br /><br />
          <span class="legal-page-sub-definition">Délégué à la protection des données</span><br /><br />
          Notre délégué à la protection des données est à votre disposition pour répondre à toutes les questions relatives à la protection des données :<br /><br />
          Délégué à la protection des données de Volkswagen AG <br /><br />
          Berliner Ring 2, 38440 Wolfsburg<br /><br />
          <CTA emphasis="none" tag="a" isInline={true} >datenschutz@volkswagen.de</CTA><br /><br />

          Dernière modification effectuée : mai 2021<br /><br />

        </div>
        <div class="imprint-copyright">© Volkswagen 2021</div>

      </>
    )
  }

  fetchPageForES = () => {
    return (
      <>
        <div class="legal-page-title">Declaración sobre protección de datos para el sitio wiki de números PR del Group Retail Portal ("GRP") de Volkswagen Vehículos Comerciales</div> <br />
        <div class="legal-page-headline">A. Responsable</div> <br />
        <div class="legal-page-content">Nos complace que visite el sitio wiki de números PR de Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, Alemania, inscrita en el Registro Mercantil del Juzgado Municipal de Braunschweig con el número de registro 100484, sección B ("Volkswagen AG"). A continuación, le informamos sobre el tratamiento de sus datos personales por parte de Volkswagen AG en relación a su visita al sitio wiki.</div><br />
        <div class="legal-page-headline">B. Tratamiento de sus datos personales</div> <br />
        <div class="legal-page-sub-headline">I. Tratamiento de los archivos de registro</div> <br />
        <div class="legal-page-content">Cuando visita nuestro sitio web, tratamos los siguientes archivos de registro relativos a su persona: <br /><br />
          1. un identificador de cookie anónimo que no permite identificar su dirección IP,<br /><br />
          2. el sistema operativo y el navegador web que usa, así como la resolución de pantalla que tiene ajustada,<br /><br />
          3. la fecha y la hora de su visita, <br /><br />
          4. las páginas web de nuestro sitio que ha visitado y <br /><br />
          5. el sitio web desde el que nos visita. <br /><br />
          El tratamiento de estos datos se realiza conforme al artículo 6, apartado 1, letra f del RGPD en base a nuestro interés legítimo en poder mostrarle el sitio web correctamente. Estos datos de protocolo se suprimen de forma rutinaria y automatizada una vez pasados 42 días. <br /><br />
          En el caso de los redactores, además de los archivos de registro mencionados anteriormente, en la herramienta de redacción también se tratan los datos de flujo de trabajo (cambio de estado, fecha, hora, usuario).</div><br />
        <div class="legal-page-sub-headline">II. Registro en el Group Retail Portal ("GRP") y en la herramienta de redacción incluyendo la gestión de datos maestros</div> <br />
        <div class="legal-page-content">El Group Retail Portal (en adelante, "GRP") es una aplicación web basada en la nube que permite a los usuarios registrados ("usuarios") el acceso a las aplicaciones del Grupo Volkswagen. Cuando se registra, Volkswagen AG recopila los siguientes datos personales relativos a su persona: nombre, apellidos, ID de usuario de Volkswagen y dirección de correo electrónico profesional ("datos maestros"). Una vez confirmado el registro, puede iniciar sesión en el GRP introduciendo su dirección de correo electrónico o su ID de usuario y la contraseña que usted mismo haya establecido para utilizar el wiki de números PR. Los empleados del Grupo Volkswagen que estén registrados, también podrán iniciar sesión con su tarjeta PKI. <br /><br />
          El tratamiento de estos datos personales con fines de registro en el GRP (incluyendo el almacenamiento y la administración de sus datos maestros) se realiza conforme al artículo 6, apartado 1, letra b del RGPD (el tratamiento es necesario para la ejecución de un contrato). Sin el tratamiento de estos datos, no es posible registrarse correctamente en el GRP ni hacer un uso posterior del mismo. En el caso de usuarios que sean empleados de Volkswagen AG, el tratamiento de dichos datos personales se realiza con fines de registro en el GRP (incluyendo el almacenamiento y la administración de los datos maestros) conforme al artículo 26, apartado 1 de la Ley alemana de protección de datos (BDSG; para la ejecución de la relación laboral). <br /><br />
          En el caso de los redactores del sitio wiki de números PR, también se recopilan los llamados "datos maestros". Una vez confirmado el registro, puede iniciar sesión en la herramienta de redacción indicando su dirección de correo electrónico o su ID de usuario y la contraseña que usted mismo haya establecido. Los empleados del Grupo Volkswagen que estén registrados, también podrán iniciar sesión con su tarjeta PKI.</div><br />
        <div class="legal-page-sub-headline">III. Uso del GRP y de la herramienta de redacción y gestión (de derechos) de usuarios por parte del contexto laboral correspondiente</div><br />
        <div class="legal-page-content">Una vez registrado correctamente y tras haber iniciado sesión en el GRP o en la herramienta de redacción, podrá trabajar en el ámbito del "contexto laboral" que se le haya asignado según las funciones que se le hayan asignado (por ejemplo, comercial, jefe de taller, mecánico), y utilizar las aplicaciones específicas de dicho contexto o funciones. Por lo general, el contexto laboral se corresponde con su empleador, por ejemplo, el responsable de un concesionario o de un taller, un importador, una sociedad de una marca de Volkswagen, o Volkswagen AG. <br /><br />
          La asignación de funciones y derechos concretos a usuarios (gestión [de derechos] de usuarios) se lleva a cabo por parte de administradores locales ("Local Admins") a nivel del contexto laboral en cuestión. Para facilitar dicha gestión (de derechos) de usuarios, Volkswagen AG transfiere su perfil de usuario a la responsabilidad en materia de protección de datos del contexto laboral en cuestión dentro del GRP. <br /><br />
          Esta transferencia se realiza para facilitar el uso correcto del GRP conforme a lo dispuesto por el artículo 6, apartado 1, letra b del RGPD (el tratamiento es necesario para la ejecución de un contrato). Con respecto a los usuarios que son empleados de Volkswagen AG, esta transferencia se realiza conforme al artículo 26, apartado 1 de la Ley alemana de protección de datos (BDSG) para la ejecución de la relación laboral.<br /><br />
          En el momento en que usted accede a través del GRP a aplicaciones vinculadas, sus datos personales mencionados anteriormente se transferirán de forma cifrada a los proveedores de dichas aplicaciones con fines de autenticación y autorización. Dichos proveedores pueden ser Volkswagen AG,  sociedades de las marcas u otros terceros. La responsabilidad en materia de protección de datos de esta transmisión de sus datos personales también recae sobre su contexto laboral en cuestión.</div><br />
        <div class="legal-page-sub-headline">IV. Transmisión de datos de contacto de administradores de GRP</div><br />
        <div class="legal-page-content">En el caso de usuarios que tengan derechos de administración en el GRP (administradores) rige, además, que sus datos de contacto almacenados (nombre, apellidos, dirección de correo profesional) pueden ser transmitidos a administradores de niveles superiores de administración (por ejemplo, a administradores de mercados mayoristas, del OEM o del Grupo) dentro de la red comercial de Volkswagen con fines de implementación de nuevas versiones o funciones del GRP o para subsanar averías técnicas en caso de que sea necesario. Esta transmisión o tratamiento de los datos personales se realiza para la correcta puesta a disposición, mantenimiento y desarrollo adaptado a las necesidades del GRP conforme a lo dispuesto por el artículo 6, apartado 1, letra b del RGPD (el tratamiento es necesario para la ejecución de un contrato). Con respecto a los administradores que son empleados de Volkswagen AG, esta transmisión o tratamiento se realiza conforme al artículo 26, apartado 1 de la Ley alemana de protección de datos (BDSG) para la ejecución de la relación laboral.</div><br />
        <div class="legal-page-sub-headline">V. Desactivación de su perfil de usuario y supresión de sus datos</div><br />
        <div class="legal-page-content">
          Al desactivar su perfil de usuario en el GRP, perderá el acceso a todas las funciones del GRP y a las aplicaciones anexas. Siempre que los plazos de almacenamiento legales no requieran ningún tratamiento posterior, suprimiremos sus datos según los siguientes casos: <br /><br />
          - 14 días tras la desactivación de su perfil de usuario; <br /><br />
          - 2 días tras el registro si hasta ese momento no se ha verificado su perfil de usuario; <br /><br />
          - 14 días tras el registro si hasta ese momento no se le ha asignado ningún contexto laboral; <br /><br />
          - 183 días tras la retirada de su último contexto laboral si hasta ese momento no se le ha vuelto a asignar otro contexto laboral; <br /><br />
          - si han pasado más de 365 días desde su último inicio de sesión en GRP</div><br />
        <div class="legal-page-sub-headline">VI. Alojamiento del GRP y empleo de empresas encargadas del tratamiento de datos</div><br />
        <div class="legal-page-content">Por orden de Volkswagen AG, Amazon Web Services, Inc. (AWS) se encarga del alojamiento en la nube del GRP en servidores de Irlanda y Alemania. Volkswagen AG directamente o los proveedores de servicios contratados por Volkswagen AG, IBM Deutschland GmbH, Hexad GmbH, FME AG, GOD mbH, re:think Innovations GmbH y Volkswagen Gebrauchtfahrzeughandels und Service GmbH (VGSG) son los encargados de ejecutar las acciones de tratamiento de datos mencionadas en la presente Declaración sobre protección de datos. Con estos proveedores de servicio empleados o empresas encargadas del tratamiento de datos se han celebrados los contratos de confidencialidad pertinentes.</div><br />
        <div class="legal-page-headline"> C. Uso de cookies y herramientas de seguimiento</div> <br />
        <div class="legal-page-sub-headline">I. Información general</div><br />
        <div class="legal-page-content">Volkswagen AG emplea distintas cookies en sus sitios web. Las cookies son pequeños archivos que contienen información de configuración y que se guardan en su terminal. Por lo general las cookies se pueden repartir en tres categorías. Hay cookies imprescindibles para el funcionamiento de la página web (las llamadas cookies funcionales), otras que aumentan la comodidad a la hora de usar la página web y guardan sus ajustes de idioma, por ejemplo, y cookies con las se crea un perfil de uso pseudonimizado (cookies de seguimiento). <br /><br />
          En el GRP solo se emplean cookies funcionales. Durante su actividad en el sitio web, se guarda lo que se conoce como una cookie de sesión temporal (cookie funcional) en la memoria de su terminal . Dicha cookie contiene un identificador de sesión que sirve, por ejemplo, para que no tenga que volver a iniciar sesión cada vez que cambia de página. Las cookies de sesión se borran al cerrar la sesión o pierden su validez en cuanto su sesión caduca automáticamente. El tratamiento de sus datos personales que se requiere para este caso se basa en el artículo 6, apartado 1, letra b del RGPD ("el tratamiento es necesario para la ejecución de un contrato"). <br /><br />
          Aparte de las cookies funcionales, en el GRP no se emplea ningún otro tipo de cookies (especialmente cookies de personalización ni de seguimiento). <br /><br />
          Para más información sobre nuestras cookies, puede consultar la Normativa de cookies de Volkswagen en <CTA emphasis="none" tag="a" href={'https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html'} isInline={true} >https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html</CTA> así como a continuación, en el apartado II.</div><br />
        <div class="legal-page-sub-headline">II. Matomo (Piwik)</div><br />
        <div class="legal-page-content">Este sitio web emplea el programa del proveedor de analítica web Matomo para analizar de forma anonimizada los accesos de los usuarios al GRP. Cada vez que accede al sitio, su dirección IP se transmite a un servidor con el programa de analítica web Matomo. El proveedor informático makandra GmbH, de Augsburgo, Alemania, gestiona dicho servidor por encargo de Volkswagen AG. Inmediatamente tras su transmisión, su dirección IP se anonimiza en el servidor con el programa de analítica web. Para ello, su dirección IP se reduce a los últimos 16 bits o 2 octetos en el caso de usar la cuarta versión del protocolo de Internet (IPV4), o bien a 80 bits o 10 octetos en el caso de usar la sexta versión del protocolo de Internet (IPv6). <br /><br />
          De esta forma, no es posible asociar la dirección IP anonimizada con personas. A continuación, el programa de analítica web trata la dirección IP anonimizada para un análisis estadístico de los accesos al sitio. <br /><br />
          La anonimización de su dirección IP con fines de análisis estadístico de los accesos al sitio web se lleva a cabo sobre la base de nuestro interés legítimo en analizar el uso del sitio web para optimizar y continuar desarrollando los contenidos conforme a las necesidades de los usuarios del sitio web (artículo 6, apartado 1, letra f del RGPD). <br /><br />
          Oposición a la anonimización de su dirección IP con fines de análisis estadístico<br /><br />
          También puede oponerse a este tratamiento de los datos en cualquier momento con efecto futuro haciendo clic en el siguiente enlace: <CTA emphasis="none" tag="a" href={'https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de'} isInline={true}> https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de.</CTA></div><br />
        <div class="legal-page-headline"> D. Sus derechos</div> <br />
        <div class="legal-page-content">Podrá hacer valer ante Volkswagen AG sus siguientes derechos en todo momento y de forma gratuita. Para más información sobre el ejercicio de sus derechos, puede consultar el apartado E "Sus personas de contacto".
          <br /><br /> <span class="legal-page-sub-definition">Derecho de acceso: {"  "}</span>tiene derecho a que le informemos sobre el tratamiento de sus datos personales.
          <br /><br /><span class="legal-page-sub-definition">Derecho de rectificación: {"  "}</span>tiene derecho a exigirnos la rectificación de datos personales incorrectos o incompletos relativos a su persona.
          <br /><br /><span class="legal-page-sub-definition">Derecho de supresión: {"  "}</span> tiene derecho a solicitar que se supriman sus datos si se cumplen los requisitos especificados en el artículo 17 del RGPD. Según este, podrá, por ejemplo, solicitar que se supriman sus datos siempre y cuando ya no sean necesarios para los fines para los que se recopilaron. Además, podrá solicitar la supresión de sus datos si nosotros los tratamos en base a su consentimiento y usted revoca dicho consentimiento.
          <br /><br /><span class="legal-page-sub-definition">Derecho a la limitación del tratamiento: {"  "}</span>tiene derecho a solicitar la limitación del tratamiento de sus datos cuando se cumplan los requisitos estipulados en el artículo 18 del RGPD. Este sería el caso, p. ej., si impugnara la exactitud de sus datos. En tal caso, podrá solicitar que se limite el tratamiento durante el tiempo que se tarde en comprobar si los datos son correctos.
          <br /><br /><span class="legal-page-sub-definition">Derecho de oposición: {"  "}</span>siempre y cuando el tratamiento de los datos tenga un interés primordial o sus datos se usen para fines de publicidad directa, tiene derecho a oponerse al tratamiento de los mismos. Una oposición será lícita cuando el tratamiento responda a un interés público o se lleve a cabo en el ejercicio de poderes públicos o debido a un interés legítimo por parte de Volkswagen AG o de un tercero. En caso de oponerse, le rogamos nos comunique las razones por las que se opone al tratamiento de datos. Además, tiene derecho a oponerse al tratamiento de datos cuando se realice con fines de publicidad directa. Esto también es válido para la elaboración de perfiles, siempre y cuando esté relacionada con la publicidad directa.
          <br /><br /><span class="legal-page-sub-definition">Derecho a la portabilidad de los datos: {"  "}</span>si el tratamiento de datos viene dado por un consentimiento o un cumplimiento contractual y, además, se realiza de forma automatizada, usted tiene derecho a recibir sus datos en un formato estructurado, habitual y automatizado, y a transferirlos a otra empresa de tratamiento de datos.
          <br /><br /> <span class="legal-page-sub-definition">Derecho de revocación: {"  "}</span>i el tratamiento de datos viene dado por un consentimiento, usted tiene derecho a revocar el consentimiento para el tratamiento de datos en todo momento, con efecto futuro y de forma gratuita.
          <br /><br /> <span class="legal-page-sub-definition">Derecho a presentar una reclamación: {"  "}</span>también tiene derecho a presentar una reclamación sobre el tratamiento que hacemos de sus datos ante una entidad supervisora (en Alemania, p. ej., ante el delegado de protección de datos de Baja Sajonia).
        </div><br />
        <div class="legal-page-headline"> E. Sus personas de contacto</div> <br />
        <div class="legal-page-content">
          <span class="legal-page-sub-definition">Personas de contacto para ejercer sus derechos</span><br /><br />
          Si desea ejercer sus derechos y obtener información adicional, puede dirigirse a los contactos indicados en el siguiente sitio web de Volkswagen AG: <CTA emphasis="none" tag="a" href={'https://datenschutz.volkswagen.de'} isInline={true} >https://datenschutz.volkswagen.de.</CTA> <br /><br />
          <span class="legal-page-sub-definition">Responsable de protección de datos</span><br /><br />
          Puede contactar con nuestro responsable de protección de datos para cuestiones relacionadas con la protección de datos:<br /><br />
          Responsable de protección de datos de Volkswagen AG<br /><br />
          Berliner Ring 2, 38440 Wolfsburg<br /><br />
          <CTA emphasis="none" tag="a" isInline={true} >datenschutz@volkswagen.de</CTA><br /><br />

          Versión: mayo 2021<br /><br />

        </div>
        <div class="imprint-copyright">© Volkswagen 2021</div>

      </>
    )
  }


  fetchPageForPT = () => {
    return (
      <>
        <div class="legal-page-title"> Declaração de privacidade para o PR-NR Wiki do Volkswagen Nutzfahrzeuge Group Retail Portal ("GRP") </div> <br />
        <div class="legal-page-headline">A. Responsável pelo tratamento </div> <br />
        <div class="legal-page-content">Agradecemos a sua visita ao PR-Nr Wiki da Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, registada no Registo Comercial do Tribunal da Comarca de Braunschweig com o n.º HRB 100484 ("Volkswagen AG"). Em seguida, informamos sobre o tratamento dos seus dados pessoais pela Volkswagen AG no contexto da sua visita ao Wiki.</div><br />
        <div class="legal-page-headline">B. Tratamento dos seus dados pessoais</div> <br />
        <div class="legal-page-sub-headline">I. Processamento de ficheiros de registo</div> <br />
        <div class="legal-page-content">
          Quando visita no nosso website, tratamos os seus seguintes ficheiros de registo: <br /><br />
          1. uma ID de cookie anónima, que não permite quais conclusões sobre o seu endereço IO,<br /><br />
          2. o sistema operativo e o navegador que utiliza e a resolução de ecrã definida por si, <br /><br />
          3. a data e hora da sua visita, <br /><br />
          4. o nosso website que visitou e<br /><br />
          5. o website a partir do qual nos visita. <br /><br />
          O tratamento destes dados é realizado em conformidade com o artigo 6.º, n.º 1, alínea f) do RGPD com base no nosso legítimo interesse em poder apresentar-lhe o website de forma adequada. Estes dados de registo são apagados de forma automatizada após 42 dias, no âmbito de uma rotina. <br /><br />
          Para os redatores, são tratados adicionalmente os dados de fluxo de trabalho (mudança de estado, data, hora, utilizador) na ferramenta de redação, além dos ficheiros de registo mencionados.</div><br />
        <div class="legal-page-sub-headline">II. Registo no Group Retail Portal ("GRP") e na ferramenta de redação, incluindo gestão de dados mestre</div> <br />
        <div class="legal-page-content">O Group Retail Portal (doravante "GRP") é uma aplicação baseada em nuvem e na web, que permite a utilizadores registados ("Utilizador(es)") acesso a aplicações do Grupo Volkswagen. Ao efetuar o registo, a Volkswagen AG recolhe os seguintes dados pessoais a seu respeito: primeiro nome, apelido ID de utilizador Volkswagen e endereço de e-mail profissional ("dados-mestre"). Após confirmação do registo, pode iniciar sessão no GRP introduzindo o seu endereço de e-mail ou ID de utilizador e a palavra-passe definida por si para utilizar o PR-NR Wiki. Os funcionários registados do Grupo Volkswagen podem, em alternativa, iniciar sessão com o seu cartão PKI. <br /><br />
          O tratamento destes dados pessoais com a finalidade do registo no GRP (incluindo armazenamento e gestão dos seus dados-mestre) é realizado em conformidade com o artigo 6.º, n.º 1, alínea b) do RGPD (tratamento necessário para execução de um contrato). Sem o tratamento destes dados, não é possível um registo correto e a utilização subsequente do GRP. No caso de utilizadores que sejam funcionários da Volkswagen AG, o tratamento destes dados pessoais é realizado para fins de registo no GRP (incluindo armazenamento e gestão dos dados-mestre) de acordo com o § 26, parágrafo 1 da Lei Federal Sobre Proteção de Dados (BDSG; para execução da relação profissional). <br /><br />
          Para os redatores do PR-NR Wiki também são recolhidos os "dados-mestre" referidos. Após confirmação do registo, pode iniciar sessão na ferramenta de redação introduzindo o seu endereço de e-mail ou ID de utilizador e a palavra-passe definida por si. Os funcionários registados do Grupo Volkswagen podem, em alternativa, iniciar sessão com o seu cartão PKI.</div><br />
        <div class="legal-page-sub-headline">III.  Utilização do GRP e da ferramenta de redação e gestão de (direitos) do utilizador através do respetivo contexto laboral</div><br />
        <div class="legal-page-content">Após registo e início de sessão bem-sucedidos no GRP e/ou ferramenta de redação, poderá trabalhar e utilizar aplicações específicas de contexto e funções, no âmbito do "contexto de trabalho" que lhe for atribuído, de acordo com a suas funções atribuídas (p. ex., Sales Person, Workshop Manager, Mechanic). Por norma, o contexto de trabalho corresponde à sua entidade patronal, p. ex., o operador de um concessionário ou oficina, um importador, uma empresa da marca Volkswagen, a Volkswagen AG. <br /><br />
          A atribuição específica de funções e direitos aos utilizadores (User (Rights) Management) é realizada por administradores locais ("Local Admins") no nível do respectivo contexto de trabalho. Para permitir esta User (Rights) Management, a Volkswagen AG transfere o seu perfil de utilizador dentro do GRP para a responsabilidade de proteção de dados do respetivo contexto de trabalho. <br /><br />
          Esta transferência é realizada para permitir a utilização correta do GRP (com base no artigo 6.º, n.º 1, alínea b) do RGPD (tratamento para execução de um contrato). No caso de utilizadores que sejam funcionários da Volkswagen AG, esta transferência é realizada conforme o § 26, parágrafo 1 da BDSG; para execução da relação profissional. <br /><br />
          Assim que aceder às aplicações associadas através do GRP, os seus dados pessoais anteriormente indicados são transferidos encriptados para o fornecedor destas aplicações para fins de autenticação e autorização. Estes fornecedores podem ser a Volkswagen AG, empresas da marca e/ou outros terceiros. O seu respetivo contexto de trabalho também é responsável por esta transferência dos seus dados pessoais de acordo com a lei de proteção de dados.</div><br />
        <div class="legal-page-sub-headline">IV. Transmissão de dados de contacto de administradores do GRP</div><br />
        <div class="legal-page-content">Para utilizadores com direitos administrativos no GRP (administradores) os seus dados de contacto (primeiro nome, apelido, endereço de e-mail profissional) também podem ser transmitidos, na medida do necessário, a administradores de nível de administração mais elevado (p. ex., Administradores Wholesale, OEM ou Group) na rede de distribuição da Volkswagen, para fins de lançamento de novas versões ou funções do GRP ou para reparação de avarias técnicas. Esta transmissão ou tratamento dos dados pessoais ocorre para a disponibilização, manutenção ou desenvolvimento corretos do GRP, com base no artigo 6.º, n.º 1, alínea b) do RGPD (tratamento para execução de um contrato). No caso de administradores que sejam funcionários da Volkswagen AG, esta transferência ou tratamento é realizada conforme o § 26, parágrafo 1 da BDSG para execução da relação profissiona</div><br />
        <div class="legal-page-sub-headline">V. Desativação do seu perfil de utilizador e apagamento dos seus dados</div><br />
        <div class="legal-page-content">Com a desativação do seu perfil de utilizador do GRP, perde o acesso a todas as funções do GRP e às aplicações a jusante. Os seus dados são apagados nos casos seguintes, caso não seja necessário um tratamento subsequente devido a prazos legais de conservação:<br /><br />
          - 14 dias após a desativação do seu perfil de utilizador; <br /><br />
          - 2 dias após o registo, caso não tenha ocorrido qualquer verificação do seu perfil de utilizador até então;<br /><br />
          - 14 dias após o registo, caso não lhe tenha sido atribuído qualquer contexto de trabalho até então; <br /><br />
          - 183 dias após a suspensão do seu último contexto de trabalho se, até então, não lhe for novamente atribuído qualquer contexto de trabalho; <br /><br />
          - se não tiver iniciado sessão no GRP durante mais de 365 dias.</div><br />
        <div class="legal-page-sub-headline">VI. Hosting do GRP e utilização de subcontratantes</div><br />
        <div class="legal-page-content">O GRP está hospedado com base na nuvem em nome da Volkswagen AG pela Amazon Web Services, Inc. (AWS) na Alemanha e na Irlanda. As atividades de tratamento da Volkswagen AG indicadas na presente declaração de privacidade são realizadas ou diretamente pela Volkswagen AG ou por prestadores de serviços subcontratados pela Volkswagen AG IBM Deutschland GmbH, Hexad GmbH, FME AG, GOD mbH, re:think Innovations GmbH e pela Volkswagen Gebrauchtfahrzeughandels und Service GmbH (VGSG) . Foram celebrados com esses fornecedores de serviços ou subcontratantes os acordos de proteção de dados necessários.</div><br />
        <div class="legal-page-headline"> C. Utilização de cookies e ferramentas de rastreio</div> <br />
        <div class="legal-page-sub-headline">I. Informações gerais</div><br />
        <div class="legal-page-content">A Volkswagen AG utiliza vários cookies nos seus sites. Os cookies são pequenos ficheiros com informações de configuração que são armazenados no seu dispositivo terminal. Os cookies podem ser divididos em três categorias. Existem cookies que são essenciais para a funcionalidade do site (denominados cookies de funcionalidade), cookies que aumentam a comodidade de uma visita ao site e, por exemplo, guardam as suas definições de idioma (denominados cookies de conforto) e cookies com base nos quais é criado um perfil de utilização pseudonimizada (denominados cookies de rastreio). <br /><br />
          No GRP são utilizados apenas cookies de funcionalidade. Enquanto estiver ativo no website, é colocado temporariamente na memória do seu terminal um cookie de sessão (cookie de função), no qual é armazenado um identificador de sessão para que seja novamente obrigado a iniciar sessão, p. ex., a cada mudança de página. Os cookies de sessão são apagados ao terminar sessão ou perdem a sua validade assim que a sua sessão expira automaticamente. O tratamento dos seus dados pessoais necessário para este efeito tem por base o artigo 6.º, n.º 1, alínea b) do RGPD ("execução de um contrato"). <br /><br />
          Além dos cookies de funcionalidade, não são utilizados no GRP quaisquer outros cookies (nomeadamente cookies de conforto e cookies de rastreio). <br /><br />
          Na diretiva sobre cookies da Volkswagen <CTA emphasis="none" tag="a" href={'https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html'} isInline={true} >https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html</CTA> , bem como na secção II seguinte, podem ser consultadas informações adicionais sobre os nossos cookies.</div><br />
        <div class="legal-page-sub-headline">II. Matomo (Piwik)</div><br />
        <div class="legal-page-content">Este website utiliza o software do fornecedor de análise web Matomo, para avaliar de forma anonimizada os acessos de utilizadores ao GRP. Cada vez que acede ao site, o seu endereço IP é encaminhado para um servidor com o software de análise web Matomo. O servidor é operado pelo prestador de serviços de TI makandra GmbH, Augsburg, em representação da Volkswagen AG. No servidor com o software de análise web, o seu endereço IP é anonimizado imediatamente após a transmissão. Para tal, o seu endereço IP é abreviado para os últimos 16 bits ou 2 octetos ao usar o protocolo da Internet versão 4 (IPv4) ou 80 bits ou 10 octetos ao usar o protocolo da Internet versão 6 (IPv6). <br /><br />
          Desta forma, é excluída a possibilidade de referência direta à sua pessoa do endereço IP anonimizado. Em seguida, o software de análise web trata o seu endereço IP anonimizado para avaliação estatística dos acessos. <br /><br />
          A anonimização do seu endereço IP para fins de avaliação estatística dos acessos é realizada com base nos nossos interesses legítimos relacionados com a utilização do website, para otimização e desenvolvimento dos conteúdos do website de acordo com os requisitos dos utilizadores (artigo 6.º, n.º 1, alínea f) do RGPD). <br /><br />
          Objeção à anonimização do seu endereço IP para fins de avaliação estatística <br /><br />
          Pode, a qualquer momento, opor-se a este tratamento de dados com efeitos futuros, clicando também na seguinte hiperligação: <CTA emphasis="none" tag="a" href={'https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de'} isInline={true}> https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de.</CTA></div><br />
        <div class="legal-page-headline">D. Os seus direitos</div> <br />
        <div class="legal-page-content">Pode, a qualquer momento, fazer valer os seus direitos que se seguem, sem encargos, junto da Volkswagen AG. Para mais informações sobre como exercer os seus direitos, consulte a secção E "O seu parceiro de contacto".<br /><br />
          <span class="legal-page-sub-definition">Direito de informação: {"  "}</span> Tem o direito de receber, da nossa parte, informação sobre o tratamento dos seus dados pessoais.
          <br /><br /> <span class="legal-page-sub-definition">Direito de retificação: {"  "}</span>tem o direito de nos exigir a retificação de dados pessoais incorretos ou incompletos que lhe digam respeito.
          <br /><br /><span class="legal-page-sub-definition">Direito ao apagamento: {"  "}</span>estando presentes as condições referidas no artigo 17.º do RGPD, tem o direito de solicitar o apagamento dos seus dados. Assim, pode, por exemplo, exigir o apagamento dos seus dados, desde que estes já não sejam necessários para as finalidades que motivaram a sua recolha. Além disso, pode exigir o apagamento se tivermos tratado os seus dados com base no seu consentimento e retirar esse consentimento.
          <br /><br /><span class="legal-page-sub-definition">Direito à limitação do tratamento: {"  "}</span>   tem o direito de exigir a limitação do tratamento dos seus dados, quando estiverem presentes as condições do artigo 18.º do RGPD. Isto acontece, por exemplo, quando contesta a exatidão dos dados. Durante o período necessário à verificação da exatidão dos dados, pode exigir então a limitação do tratamento.
          <br /><br /><span class="legal-page-sub-definition">Direito de oposição: {"  "}</span>  se o tratamento se basear num interesse superior ou os seus dados forem utilizados para fins de publicidade direta, tem o direito de se opor ao tratamento dos seus dados. Uma contestação é permitida, se o tratamento for do interesse público ou for realizado no exercício da autoridade pública ou por motivos de interesse legítimo da Volkswagen AG ou de terceiros. Em caso de oposição, pedimos-lhe que nos indique os motivos que o levaram a opor-se ao tratamento dos dados. Tem ainda o direito de se opor ao tratamento dos dados para fins de publicidade direta. Isso também é válido para a definição de perfis, desde que esteja relacionada com a publicidade direta.
          <br /><br /><span class="legal-page-sub-definition">Direito de portabilidade dos dados: {"  "}</span>se o tratamento dos dados se basear num consentimento ou na execução de um contrato e este também for realizado recorrendo a um tratamento automatizado, tem o direito de receber os seus dados num formato estruturado, de uso corrente e de leitura automática e de os transmitir a outro processador de dados.
          <br /><br /><span class="legal-page-sub-definition">Direito de revogação: {"  "}</span>se o tratamento dos dados se basear num consentimento, tem o direito de revogar o tratamento dos dados gratuitamente, a qualquer momento, com efeitos futuros.
          <br /><br /><span class="legal-page-sub-definition">Direito de reclamação: {"  "}</span>além disso, tem o direito de apresentar reclamação junto de uma autoridade reguladora (p. ex. junto da Autoridade para a proteção de dados da Baixa Saxónia) sobre o nosso tratamento dos seus dados.
        </div><br />
        <div class="legal-page-headline"> E. O seu parceiro de contacto</div> <br />
        <div class="legal-page-content">
          <span class="legal-page-sub-definition">Parceiro de contacto para o exercício dos seus direitos </span><br /><br />
          O parceiro de contacto para o exercício dos seus direitos, bem como outras informações, pode ser encontrado no seguinte website da Volkswagen AG:  <CTA emphasis="none" tag="a" href={'https://datenschutz.volkswagen.de'} isInline={true} >https://datenschutz.volkswagen.de.</CTA> <br /><br />
          <span class="legal-page-sub-definition">Responsável pela proteção de dados</span><br /><br />
          O nosso responsável pela proteção de dados está ao seu dispor como parceiro de contacto para questões relacionadas com a proteção de dados:<br /><br />
          Responsável pela proteção de dados da Volkswagen AG <br /><br />
          Berliner Ring 2, 38440 Wolfsburg<br /><br />
          <CTA emphasis="none" tag="a" isInline={true} >datenschutz@volkswagen.de</CTA><br /><br />

          Versão: maio de 2021<br /><br />

        </div>
        <div class="imprint-copyright">© Volkswagen 2021</div>

      </>
    )
  }

  fetchPageForIT = () => {
    return (
      <>
        <div class="legal-page-title">Dichiarazione sul trattamento dei dati personali per il PR-NR Wiki di Volkswagen Veicoli Commerciali Group Retail Portal ("GRP”)</div> <br />
        <div class="legal-page-headline">A. Responsabile</div> <br />
        <div class="legal-page-content">Siamo lieti di averti come ospite sul PR-Nr Wiki della Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, iscritta nel registro delle imprese del tribunale di Braunschweig con il numero HRB 100484 ("Volkswagen AG”). Qui di seguito ti forniremo informazioni sul trattamento dei tuoi dati personali da parte della Volkswagen AG in relazione alla tua visita nel Wiki.</div><br />
        <div class="legal-page-headline">B. Trattamento dei tuoi dati personali</div> <br />
        <div class="legal-page-sub-headline">I. Trattamento dei file di protocollo</div> <br />
        <div class="legal-page-content">Nel corso della tua visita sul nostro sito web elaboreremo i seguenti tuoi file di protocollo:<br /><br />
          1. Un cookie-ID anonimo che non consente di risalire al tuo indirizzo IP<br /><br />
          2. Il sistema operativo da te utilizzato, il browser da te utilizzato e la risoluzione impostata per lo schermo<br /><br />
          3. La data e l’ora della visita<br /><br />
          4. Le pagine web che hai visitato nel nostro sito<br /><br />
          5. Il sito web dal quale ci stai visitando. <br /><br />
          Il trattamento di questi dati si svolge secondo quanto previsto dall’art. 6, comma 1, lett. a del GDPR sulla base del nostro interesse legittimo di poter visualizzare correttamente il sito web. Questi dati di protocollo saranno eliminati automaticamente dopo 42 giorni nell’ambito di un’operazione di routine. <br /><br />
          Per i redattori vengono elaborati inoltre i dati del workflow (cambio di stato, data, ora, utente) nello strumento redazionale, oltre ai file di protocollo.</div><br />
        <div class="legal-page-sub-headline">II. Registrazione al Group Retail Portal ("GRP”) e strumento redazionale, compresa la gestione dei dati principali</div> <br />
        <div class="legal-page-content">Il Group Retail Portal (d’ora in avanti "GRP”) è un’applicazione basata sull’uso di un cloud e del web, con la quale gli utenti registrati hanno accesso ad applicazioni del Gruppo Volkswagen. In fase di registrazione la Volkswagen AG raccoglie i seguenti tuoi dati personali: nome, cognome, ID utente Volkswagen e indirizzo e-mail aziendale ("dati principali”). Dopo aver confermato la tua registrazione, puoi accedere al GRP inserendo il tuo indirizzo e-mail o l’ID utente e la password, da te scelta, per poter utilizzare il PR-NR Wiki. I dipendenti registrati del Gruppo Volkswagen possono in alternativa accedere con la loro scheda PKI. <br /><br />
          Il trattamento di questi dati personali ai fini della registrazione al GRP (compresa la memorizzazione e la gestione dei tuoi dati principali) ha luogo in conformità all’articolo 6, comma 1, lettera b del GDPR (trattamento necessario all'adempimento di un contratto). Senza l’elaborazione di questi dati non è possibile registrarsi correttamente e utilizzare successivamente il GRP. Nel caso in cui l’utente sia un dipendente della Volkswagen AG, questi dati personali vengono elaborati ai fini della registrazione sul GRP (compresa la conservazione e la gestione dei dati principali) in conformità all’articolo 26, comma 1, della legge tedesca sulla protezione dei dati (BDSG; sullo svolgimento del rapporto di lavoro). <br /><br />
          Per i redattori del PR-NR Wiki vengono raccolti anche i suddetti "dati principali”. Dopo aver confermato la tua registrazione, puoi accedere allo strumento redazionale inserendo il tuo indirizzo e-mail o l’ID utente e la password, da te scelta. I dipendenti registrati del Gruppo Volkswagen possono in alternativa accedere con la loro scheda PKI.</div><br />
        <div class="legal-page-sub-headline">III. Uso del GRP e dello strumento redazionale e gestione dei diritti dell’utente da parte del relativo contesto lavorativo</div><br />
        <div class="legal-page-content">Una volta completata la registrazione con esito positivo ed effettuato il login al GRP e/o allo strumento redazionale, potrai lavorare nel quadro del "contesto lavorativo” assegnato e secondo i ruoli assegnati (per es. Sales Person, Workshop Manager, Mechanic) e utilizzare le applicazioni in base al contesto e ai ruoli. Il contesto lavorativo corrisponde quasi sempre al datore di lavoro, per es. il gestore di una concessionaria o di un’officina, un importatore, una marca del Gruppo Volkswagen, la Volkswagen AG. <br /><br />
          L’assegnazione effettiva di ruoli e diritti all’utente (User (Rights) Management) viene effettuata dagli amministratori locali ("Local Admin”) a livello del relativo contesto di lavoro. Per rendere possibile questa gestione (dei diritti) dell’utente specifica per il contesto, la Volkswagen AG trasferisce al tuo contesto lavorativo la responsabilità della protezione dei dati relativi al tuo profilo utente all’interno del GRP. <br /><br />
          Tale trasferimento ha luogo per permettere l’uso corretto del GRP sulla base dell’articolo 6, comma 1, lettera b, del GDPR (trattamento per l’adempimento di un contratto). Nel caso in cui l’utente sia un dipendente della Volkswagen AG, tale trasferimento ha luogo sulla base dell’articolo 26, comma 1, della legge tedesca sulla protezione dei dati (BDSG; sullo svolgimento del rapporto di lavoro). <br /><br />
          Non appena avrai accesso tramite il GRP alle applicazioni collegate, i tuoi dati personali di cui sopra saranno trasmessi in forma criptata ai fornitori di queste applicazioni ai fini dell'autenticazione e dell'autorizzazione. Tali fornitori possono anche essere la Volkswagen AG, società del Gruppo e/o altri. Anche di questa diffusione di dati è responsabile il corrispondente contesto lavorativo, ai sensi della legge sulla protezione dei dati.</div><br />
        <div class="legal-page-sub-headline">IV. Diffusione di dati di contatto di amministratori GRP</div><br />
        <div class="legal-page-content">Per gli utenti con diritti di amministratore del GRP vale inoltre quanto segue: i loro dati di contatto registrati (nome, cognome, indirizzo e-mail di lavoro) potranno essere trasmessi anche ad amministratori di livello superiore (ad esempio, amministratori di grossisti, di OEM o del Gruppo) all’interno della rete di vendita Volkswagen, allo scopo di rilasciare nuove versioni o funzioni del GRP o, se necessario, per eliminare problemi tecnici. Tale trasferimento o trattamento di dati personali ha luogo per la corretta messa a disposizione e la manutenzione nonché per il necessario perfezionamento del GRP sulla base dell’articolo 6, comma 1, lettera b, del GDPR (trattamento per l’adempimento di un contratto). Nel caso in cui l’amministratore sia un dipendente della Volkswagen AG, tale diffusione o trattamento di dati ha luogo sulla base dell’articolo 26, comma 1, della legge tedesca sulla protezione dei dati (BDSG; sullo svolgimento del rapporto di lavoro).</div><br />
        <div class="legal-page-sub-headline">V. Disattivazione del tuo profilo utente e cancellazione dei tuoi dati</div><br />
        <div class="legal-page-content">Disattivando il proprio profilo utente nel GRP, si perde l’accesso a tutte le funzioni del GRP e alle applicazioni correlate. I tuoi dati saranno cancellati nei seguenti casi, a condizione che non sia necessario un ulteriore trattamento per motivi legati ai termini legali di conservazione: <br /><br />
          - 14 giorni dopo la disattivazione del tuo profilo utente<br /><br />
          - 2 giorni dopo la registrazione, se entro questo periodo di tempo tu non avrai fatto accertare la tua identità<br /><br />
          - 14 giorni dopo la registrazione, se in questo periodo di tempo non ti è stato assegnato nessun contesto lavorativo<br /><br />
          - 183 giorni dopo la revoca del tuo ultimo contesto lavorativo senza che te ne sia stato assegnato un altro<br /><br />
          - Se non hai effettuato il login al GRP per più di 365 giorni.</div><br />
        <div class="legal-page-sub-headline">VI. Hosting del GRP e impiego di incaricati del trattamento</div><br />
        <div class="legal-page-content">La società Amazon Web Services, Inc. (AWS) è stata incaricata a curare l’hosting del GRP in Germania e Irlanda per conto della Volkswagen AG. Le attività di elaborazione della Volkswagen AG, menzionate nella presente dichiarazione sul trattamento dei dati personali, sono svolte direttamente dalla Volkswagen AG o dai fornitori di servizi IBM Deutschland GmbH, Hexad GmbH, FME AG, GOD mbH, re:think Innovations GmbH e Volkswagen Gebrauchtfahrzeughandels und Service GmbH (VGSG) incaricati dalla Volkswagen AG. Con questi fornitori o incaricati del trattamento sono stati stipulati i necessari contratti relativi alla protezione dei dati.</div><br />
        <div class="legal-page-headline"> C. Utilizzo di cookie e strumenti di tracciamento</div> <br />
        <div class="legal-page-sub-headline">I. Informazioni general</div><br />
        <div class="legal-page-content">La Volkswagen AG utilizza diversi cookie nelle sue pagine web. I cookie sono dei file di piccole dimensioni contenenti informazioni di configurazione che vengono salvati sul dispositivo dell'utente. I cookie si suddividono fondamentalmente in tre categorie. Esistono cookie che sono indispensabili per la funzionalità del sito web (i cosiddetti cookie funzionali), cookie che aumentano il comfort della visita di un sito web e, per esempio, salvano le impostazioni della lingua dell’utente (i cosiddetti cookie di comfort) e cookie che vengono utilizzati per creare un profilo utente pseudonimizzato (i cosiddetti cookie di tracciamento). <br /><br />
          Sul GRP si utilizzano solamente cookie di funzionalità. Durante la navigazione nel sito web, nella memoria del tuo dispositivo viene salvato temporaneamente un cosiddetto "cookie di sessione”, nel quale è memorizzato l’identificativo di sessione, in modo che, per esempio, tu non debba ripetere il login ogni volta che cambi pagina. I cookie di sessione vengono eliminati quando esegui il logout o scadono non appena la sessione si conclude automaticamente. Il trattamento dei tuoi dati personali, necessario a tale scopo, si fonda sull’articolo 6, comma 1, lettera b, del GDPR ("adempimento di un contratto”). <br /><br />
          Oltre ai cookie di funzionalità non si utilizzano altri cookie (soprattutto cookie comfort e cookie di tracciamento). <br /><br />
          Per ulteriori informazioni sui nostri cookie si veda la direttiva sui cookie di Volkswagen all'indirizzo <CTA emphasis="none" tag="a" href={'https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html'} isInline={true} >https://www.volkswagen.de/de/mehr/rechtliches/cookie-richtlinie.html</CTA> e la seguente sezione II.</div><br />
        <div class="legal-page-sub-headline">II. Matomo (Piwik)</div><br />
        <div class="legal-page-content">Questo sito utilizza il software del fornitore di analisi web Matomo per analizzare in modo anonimo l'accesso degli utenti al GRP. Ogni volta che apri una pagina, il tuo indirizzo IP viene inoltrato a un server sul quale è installato il software Matomo per l’analisi web. Il server è gestito dal fornitore di servizi IT makandra GmbH, Augsburg, per conto della Volkswagen AG. Sul server con il software di analisi web, il tuo indirizzo IP è reso anonimo subito dopo la sua trasmissione. A questo scopo il tuo indirizzo IP viene accorciato degli ultimi 16 bit o 2 ottetti se si usa il protocollo internet versione 4 (IPv4) o di 80 bit ovvero 10 ottetti se si usa il protocollo internet versione 6 (IPv6). <br /><br />
          In questo modo attraverso l’indirizzo IP anonimizzato non si può risalire alla persona. Il software di analisi web elabora quindi l’indirizzo IP anonimizzato per l’analisi statistica degli accessi. <br /><br />
          L’anonimizzazione del tuo indirizzo IP ai fini dell’analisi statistica degli accessi ha luogo in funzione del nostro legittimo interesse ad analizzare l’uso del sito web al fine di migliorare e perfezionare i contenuti sulla base delle esigenze degli utenti del sito (articolo 6, comma 1, lettera f, del GDPR). <br /><br />
          Opposizione all’anonimizzazione del tuo indirizzo IP ai fini dell’analisi statistica.<br /><br />
          Puoi opporti a questo trattamento dei dati in qualsiasi momento con effetto per il futuro cliccando sul seguente link: <CTA emphasis="none" tag="a" href={'https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de'} isInline={true}> https://grp-info.volkswagenag.com/piwik/index.php?module=CoreAdminHome&action=optOut&language=de.</CTA> </div><br />
        <div class="legal-page-headline"> D. I diritti dell'utente</div> <br />
        <div class="legal-page-content">L'utente ha inoltre la possibilità di fare valere nei confronti della Volkswagen AG i diritti esposti più avanti. Per maggiori informazioni sull’esercizio dei diritti dell’utente, si consulti il paragrafo E "I referenti”.<br />
          <span class="legal-page-sub-definition">Diritto di informazione: {"  "}</span>hai il diritto di ottenere informazioni sul trattamento dei tuoi dati personali.
          <br /><br /> <span class="legal-page-sub-definition">Diritto di rettifica: {"  "}</span>hai il diritto di pretendere la rettifica da parte nostra dei dati personali errati o incompleti che ti riguardano.
          <br /><br /><span class="legal-page-sub-definition">Diritto di cancellazione: {"  "}</span>hai il diritto di pretendere la cancellazione dei tuoi dati personali se sussistono le condizioni di cui all'art. 17 del GDPR. In seguito puoi quindi richiedere, ad esempio, la cancellazione dei dati se questi non sono più necessari per gli scopi per cui erano stati raccolti. Inoltre, puoi richiederne la cancellazione se i dati vengono trattati sulla base del consenso da te dato e poi revochi tale consenso.
          <br /><br /><span class="legal-page-sub-definition">Diritto di limitazione del trattamento: {"  "}</span> hai il diritto di pretendere una limitazione del trattamento dei suoi dati se sussistono i requisiti di cui all’articolo 18 del GDPR. Questo si verifica, ad esempio, se contesti l’esattezza dei tuoi dati. Per tutta la durata della verifica dell’esattezza dei dati è quindi possibile richiedere la limitazione del trattamento.
          <br /><br /><span class="legal-page-sub-definition">Diritto di opposizione: {"  "}</span> se il trattamento dei dati risulta essere basato su un interesse prevalente o se i dati dell'utente vengono utilizzati a scopo di marketing diretto, questi ha il diritto di opporsi al trattamento dei suoi dati. È consentito opporsi se il trattamento dei dati avviene nell'interesse pubblico, nell'esercizio di pubblici poteri o sulla base di un interesse legittimo della Volkswagen AG o di terzi. In caso di opposizione l'utente è pregato di comunicarci i motivi alla base dell'opposizione al trattamento dei dati personali. Inoltre, l'utente ha il diritto di opporsi al trattamento dei dati per scopi di marketing diretto. Questo vale anche per le attività di profiling connesse al marketing diretto.
          <br /><br /><span class="legal-page-sub-definition">Diritto di trasferibilità dei dati: {"  "}</span>se il trattamento dei dati dell'utente si basa sul consenso o sull'adempimento del contratto e questi dati vengono anche trattati in modo automatizzato, l'utente ha il diritto di ricevere i dati in un formato strutturato, d’uso comune e leggibile da una macchina e di trasmetterli a un altro incaricato del trattamento.
          <br /><br /><span class="legal-page-sub-definition">Diritto di recesso: {"  "}</span>se il trattamento dei dati si basa sul consenso dato, l'utente ha il diritto di opporsi al trattamento dei suoi dati con effetto per il futuro, in qualsiasi momento e gratuitamente.
          <br /><br /><span class="legal-page-sub-definition">Diritto di appello: {"  "}</span>l'utente ha anche il diritto di sporgere reclamo presso un'autorità di vigilanza (ad es. il commissario di stato per la protezione dei dati in Bassa Sassonia) in merito al trattamento dei propri dati.
        </div><br />
        <div class="legal-page-headline"> E. I referenti</div> <br />
        <div class="legal-page-content">
          <span class="legal-page-sub-definition">Referenti per l’esercizio dei diritti dell’utente</span><br /><br />
          Per sapere chi sono i referenti per l’esercizio dei diritti dell’utente e per maggiori informazioni, si consulti il seguente sito web della Volkswagen AG: <CTA emphasis="none" tag="a" href={'https://datenschutz.volkswagen.de'} isInline={true} >https://datenschutz.volkswagen.de.</CTA> <br /><br />
          <span class="legal-page-sub-definition">Garante per la protezione dei dati personali</span><br /><br />
          Il nostro garante per la protezione dei dati personali è a disposizione per tutte le questioni relative alla protezione dei dati:<br /><br />
          Datenschutzbeauftragter der Volkswagen AG<br /><br />
          Berliner Ring 2, 38440 Wolfsburg<br /><br />
          <CTA emphasis="none" tag="a" isInline={true} >datenschutz@volkswagen.de</CTA><br /><br />

          Ultimo aggiornamento: maggio 2021<br /><br />

        </div>
        <div class="imprint-copyright">© Volkswagen 2021</div>

      </>
    )
  }


  render() {

    var languagePref = localStorage.getItem(LocalStorage.SELECTED_LANGUAGE);
    var privacyPolicyCurrentLanguageContent;

    if (languagePref.toUpperCase() === 'EN') {
      privacyPolicyCurrentLanguageContent = this.fetchPageForEN()
    }
    else if (languagePref.toUpperCase() === 'ES') {
      privacyPolicyCurrentLanguageContent = this.fetchPageForES()
    }
    else if (languagePref.toUpperCase() === 'PT') {
      privacyPolicyCurrentLanguageContent = this.fetchPageForPT()
    }
    else if (languagePref.toUpperCase() === 'IT') {
      privacyPolicyCurrentLanguageContent = this.fetchPageForIT()
    }
    else if (languagePref.toUpperCase() === 'FR') {
      privacyPolicyCurrentLanguageContent = this.fetchPageForFR()
    }
    else {
      privacyPolicyCurrentLanguageContent = this.fetchPageForDE()
    }

    return (
      <ThemeProvider theme="main">
        <div className="container">
          <div className="legal-page">
            {privacyPolicyCurrentLanguageContent}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default DataProtectionStatement
