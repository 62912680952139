import React, {Component} from 'react';
import {ApplicationContextConsumer} from '../../../contextProvider/ApplicationContextProvider';
import translate from "../../../i18n/translate";
import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    Layout,
    Spinner,
    styled,
    Text,
    TextAlignment,
    TextAppearance
} from "@volkswagen-onehub/components-core";
import Pagination from "../Pagination/Pagination";
import {Link} from "react-router-dom";
import {LanguageMap} from "../../../constants/PRNConstants";
import DownloadPdfButton from '../../../common_components/DownloadPDFButton/DownloadPdfButton';

require('./OverviewTableElement.css')
export const StyledRow = styled.div`
    min-height: 76px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 17px 4px rgba(237,237,237,0.5);
`;

export const StyledRowWithBottomBorder = styled.div`
    min-height: 76px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 17px 4px rgba(237,237,237,0.5);
    border-bottom: 1px solid;
`;

const ColumnContentPadding = {
    top:ContainerPadding.static100
};

export class OverviewTableElement extends Component {
    static contextType = ApplicationContextConsumer;

    showTableElements() {
        const prnElements = [];
        if (this.props.prNumbers !== null && this.props.prNumbers !== undefined) {

            const totalPRCount = this.props.prNumbers.length;
            for (let i = 0; i < totalPRCount; i++) {
                prnElements.push(
                    <ApplicationContextConsumer>
                        {({setPageNumber, appLanguage}) => (
                    <Link onClick={()=>setPageNumber(1)} to={`/prn-form/${this.props.prNumbers[i].prNumber}/${this.props.prNumbers[i].modelName}/${this.props.prNumbers[i].modelYear}`} style={{ textDecoration: 'none' }}>
                        <StyledRow className="row align-items-center">
                            <div className="col">
                    <Layout appearance={{
                        [Breakpoints.default]: [{
                            name: 'prNo',
                            columns: 2
                        }, {
                            name: 'title',
                            columns: 7
                        }, {
                            name: 'model',
                            columns: 3
                        }, {
                            name: 'modelYear',
                            columns: 3
                        }, {
                            name: 'category',
                            columns: 3
                        }, {
                            name: 'conversion',
                            columns: 3
                        },{
                            name: 'download',
                            columns: 2
                        }
                    ]
                    }}>
                        <Text textAlign={TextAlignment.center} bold appearance={TextAppearance.label0300}>{this.props.prNumbers[i].prNumber}</Text>
                        <Container padding={ColumnContentPadding}><Text appearance={TextAppearance.label0150}>{this.props.prNumbers[i].title[LanguageMap[appLanguage]] ? this.props.prNumbers[i].title[LanguageMap[appLanguage]] : '   '}&nbsp;</Text></Container>
                        <Container padding={ColumnContentPadding}><Text bold appearance={TextAppearance.label0150}>{this.props.prNumbers[i].modelName}</Text></Container>
                        <Container padding={ColumnContentPadding}><Text bold appearance={TextAppearance.label0150}>{this.props.prNumbers[i].modelYear}</Text></Container>
                        <Container padding={ColumnContentPadding}><Text bold appearance={TextAppearance.label0150}>{this.props.prNumbers[i].category[LanguageMap[appLanguage]]}</Text></Container>
                        <Container padding={ColumnContentPadding}><Text>{this.props.prNumbers[i].abh ? <div className="conversion-flag">{translate('BUILDANDCONV')}</div> : ''}</Text></Container>
                        <DownloadPdfButton prNumber ={this.props.prNumbers[i].prNumber} modelName = {this.props.prNumbers[i].modelName} modelYear = {this.props.prNumbers[i].modelYear}></DownloadPdfButton>
                    </Layout>
                            </div>
                    </StyledRow>
                    </Link>
                            )}
                    </ApplicationContextConsumer>
                );
            }
        }
        return prnElements;
    }

    render() {
        return (
            <Container wrap={ContainerWrap.always} stretchContent gutter={ContainerGutter.static200}>
                {!this.props.isLoading ? (
                    this.showTableElements()
                ) : (
                    <Container stretchContent horizontalAlign={ContainerHorizontalAlignment.center}>
                        <Text textAlign={TextAlignment.center}><Spinner variant={"x-large"}/></Text>
                    </Container>
                )}
                {!this.props.hasOnlyLatest?(
                    <Pagination
                        defaultPageSize={10}
                        pageSizes={[10,20,30,40]}
                        totalCount={this.props.totalCount}
                        currentPage={this.props.currentPage}
                        changePage={this.props.changePage}
                        changeTotalResults={this.props.changeTotalResults}
                    />
                ):<></>}

            </Container>
        )
    }
}

export default OverviewTableElement
