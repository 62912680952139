import Cookies from "js-cookie";
import {UserAuthCookies} from "../constants/PRNConstants";

export const getUserDetailsObjectFromCookie  = (cookieName)=> {

    if (UserAuthCookies.USERNAME === (cookieName) && Cookies.get(UserAuthCookies.USERNAME) != undefined) {
        let buff = new Buffer(Cookies.get(UserAuthCookies.USERNAME), 'base64');
        let base64ToStringNew = buff.toString('utf8');
        const userName = JSON.parse(base64ToStringNew);
        return userName;
    }

    if (UserAuthCookies.ACCESS_TOKEN === cookieName && Cookies.get(UserAuthCookies.ACCESS_TOKEN) != undefined) {
        let buff = new Buffer(Cookies.get(UserAuthCookies.ACCESS_TOKEN), 'base64');
        let base64ToStringNew = buff.toString('utf8');
        const authToken = JSON.parse(base64ToStringNew);
        return authToken;
    }
}

export const setUserNameCookie = (data) => {
    Cookies.set(UserAuthCookies.USERNAME,btoa(unescape(encodeURI(JSON.stringify(data)))));
}

export const removeUserDetailsFromCookie = () => {
    Cookies.remove(UserAuthCookies.USERNAME);
    Cookies.remove(UserAuthCookies.ACCESS_TOKEN);
}

