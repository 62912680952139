import {store} from 'react-notifications-component';
import React from "react";
import 'animate.css/animate.min.css';

require('./AlertNotification.css')

export const AlertNotification = (title, message, type, timeout) => {
    store.addNotification({
        container: "top-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        content: MyNotification(message,type),
        dismiss: {
            duration: timeout
        }
    });
}

function MyNotification(message,type) {
    return (
        <div style={{
            backgroundColor: type === 'success'?'#029640':'#E4002C',
        }} className="alert-message-container alert-message">
            {message}
        </div>
    )
}
