import React, {Component} from "react";
import {ThemeProvider,} from "@volkswagen-onehub/components-core";
import translate from '../../../i18n/translate'

require('../LegalPage.css')

export class CookiePolicy extends Component {

  render() {
    return (
      <ThemeProvider theme="main">
        <div className="container">
          <div className="legal-page">
            <div class="legal-page-title">{translate("COOKIEPOLICY")}</div>
            <br />
            <div class="legal-page-content">
              Volkswagen AG uses various cookies on its websites. Cookies are
              small files containing configuration information and are saved on
              your device. Cookies can essentially be divided into three
              categories. There are cookies that are essential for the
              functionality of the website (referred to as{" "}
              <b>functional cookies</b>), cookies that make it easier to use a
              website and, for example, save your language settings (referred to
              as <b>convenience cookies</b>), and cookies on the basis of which
              a pseudonymised user profile is created (referred to as{" "}
              <b>tracking cookies</b>).
              <br />
              <br />
              Functional cookies have to be processed to allow you to visit the
              website (see Article 6 (1) (b) GDPR).
              <br />
              <br />
              The legal basis for the use of convenience cookies in the sense of
              Article 6 (1) (f) GDPR is the existence of legitimate interest.
              The provision of a convenience when using a website constitutes
              the legitimate interest in this regard. You may object to the data
              processing with future effect at any time. Further information can
              be found in the Privacy Policy..
              <br />
              <br />
              Tracking cookies are only used if the website visitor has
              consented to this (see Article 6 (1) (a) GDPR). Consent is
              provided via the cookie banner, which has to be actively clicked
              to do this. 
              <br />
              <br />
              You can also adjust your browser settings in such a way that
              cookies are displayed before being saved, only certain cookies are
              accepted or declined, or cookies are declined as standard.
              <br />
              <br />
              We would like to remind you that changes to the settings always
              only relate to the browser you change them in. If you use various
              browsers or change your end device, you will have to change the
              settings again. You can also delete cookies from your data storage
              device at any time. Please refer to your web browser’s help
              function for information on cookie settings, how to change these
              settings and how to delete cookies.
              <br />
              <br />
              Within the categories described, there are also various types of
              cookies that serve different purposes, the lifespan of which is
              determined by various actions or which are set by third parties.
              Below are the descriptions of each of these cookies, clarifying
              the differences between them:
            </div>
            <br />
            <div class="legal-page-headline">1. Session Cookies</div> <br />
            <div class="legal-page-content">
              While you are active on a web page, a session cookie is
              temporarily stored in your computer’s memory. This contains a
              session ID so that, for example, you do not have to log in again
              each time you change websites. Session cookies are deleted when
              you log off, or cease to be valid as soon as your session has
              automatically expired.
            </div>
            <br />
            <div class="legal-page-headline">
              2. Persistent or stored cookies
            </div>{" "}
            <br />
            <div class="legal-page-content">
              Persistent or stored cookies save a file on your computer until
              the scheduled expiration date. These cookies enable web pages to
              remember your information and settings the next time you visit.
              This makes it easier and quicker to access the web pages; for
              example, there is no need to re-adjust the language settings for
              our portal each time you visit. When the expiry date elapses, the
              cookies are automatically deleted when you visit the web page that
              created them.
            </div>
            <br />
            <div class="legal-page-headline">3. Third-party cookies</div> <br />
            <div class="legal-page-content">
              Third-party cookies come from providers other than the operator of
              the web page. They may be used for example to collect information
              for advertising or generate custom content and web statistics.
            </div>
            <br />
            <div class="legal-page-headline">4. Flash-Cookies</div> <br />
            <div class="legal-page-content">
              Flash-Cookies are stored on your computer as data elements of
              websites that use Adobe Flash Player. Flash cookies are not
              time-limited.
            </div>
            <br />
            <div class="legal-page-headline">5. Overview of cookies</div> <br />
            <div class="legal-page-content">
              The following cookies are used on this website:
            </div>
            <br />
          </div>
        </div>
        <div className="legal-table">
          <table class="table">
            <thead>
              <tr>
                <th class="legal-table-col"></th>
                <th class="legal-table-col"></th>
                <th class="legal-table-col"></th>
                <th class="legal-table-col"></th>
              </tr>
            </thead>
            <tbody>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>login-token</b></td>
                <td className="legal-table-row-element">Stores an anonymous login ID to identify the current login session</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>role</b></td>
                <td className="legal-table-row-element">Stores the user role within the PR number Wiki.</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>userId</b></td>
                <td className="legal-table-row-element">Stores the user ID transferred by the Identity Provider (IDP) after login.</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>Idprole</b></td>
                <td className="legal-table-row-element">Stores the role assigned by the Identity Provider (IDP) in connection with the user.</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>user</b></td>
                <td className="legal-table-row-element">Stores the name of the user who is logged in.</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>productLines</b></td>
                <td className="legal-table-row-element">Stores the name of product lines managed by the user if they are the product line manager.</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
              <tr className="legal-table-row">
                <td className="legal-table-col-1"><b>loggedInStatus</b></td>
                <td className="legal-table-row-element">Stores the status of the session (logged_in oder logged_out)</td>
                <td className="legal-table-row-element">Session/functional</td>
                <td>session</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ThemeProvider>
    );
  }
}

export default CookiePolicy;
