import {LOCALES} from '../locales'

export default {
    [LOCALES.FRENCH] : {
        'LOGOUT' : 'Déconnexion',
        "LOGIN": "Connexion",
        "ALLPRNUMBERS" : 'Tous les numéros PR',
        "SEARCH": "Parcourir les numéros PR et les titres…",
        "SHOW":"Affichages",
        "ENTRIES": "Afficher les entrées",
        "SORT": "Classer",
        "RECENTLYADDED": "Derniers numéros PR ajoutés",
        "CATALOGS": 'Catalogues',
        'DOWNLOADOPTIONS' : 'Télécharger des options',
        "CUSTOMCATALOG": 'Catalogues personnalisés créés par vous',
        "COOKIEPOLICY": 'Politique d’utilisation des cookies',
        "MODEL": 'Modèle',
        "MODELYEAR": 'Millésime',
        "CATEGORY": 'Catégorie',
        "AUFUND": "Superstructures et transformations",
        "TERMSOFUSE" : 'Conditions d’utilisation',
        "PRIVACYPOLICY": "Politique de confidentialité",
        "SITENOTICE":"Mentions légales",
        "THIRDPARTY":"Licences tiers",
        "SPRACHE":"Langue",
        "PREVIEW": "Aperçu",
        "FNF": "Fonction et caractéristiques",
        "ADVANTAGES": "Advantages",
        "NOTE": "Remarque",
        "STARTNEWPRN": '',
        "PRNUMBER": '',
        "MBVTEXT": '',
        "TITLE": '',
        'TARGETGROUP': "",
        "ENTWURF": "",
        "IN_FREIGABE_DE": "",
        "INUBERSETZUNG": "",
        "UBERSETZT": "",
        "VEROFFENLICHT": "",
        "ARCHIVIERT": "",
        "SPEICHERN": "",
        "GENINFO": "",
        "PREVIOUS": "",
        "ADDDESC": "",
        "SUMMARY": "",
        "HOMEPAGE":"Page d’accueil",
        "PRLIST": "Liste des numéros de relations publiques",
        "ADMINISTRATION": "",
        "AUTH":"",
        "CHANGEMODELYEAR": "",
        "BACK": "",
        "CLOSEMENU":"Fermer le menu",
        "DESCRIPTION": "",
        "ADDIMAGES": "",
        "REMOVEIMAGE": "",
        "SHOWALL": "Afficher tout",
        "CLICKON": "Cliquer sur",
        "BUTTONTOCREATE": "bouton pour créer un nouveau numéro PR",
        "NOTIFICATIONS": "Notifications",
        "QUICKLINKS": "Liens rapides",
        "ADMINROLE": "",
        "MEDIALIBRARY": "",
        "CLICKONMODELYEAR":"",
        "ASSIGNSOURCES":"",
        "ADDEDIT": "",
        "RECEIVEDNEWPRN": "",
        "SAVEFORMYSELF": "",
        "REJECT": "",
        "SENDDEAPP": "",
        "SENDDETRANS": "",
        "SENDTRANSAPP": "",
        "RELEASE": "",
        "RESETDRAFT": "",
        "DOWLOADZIPFILE": "",
        "UPLOADTRANSLATEDFILES": "",
        "DRAGANDDROP": "",
        "VIEWPDF": "Afficher en PDF",
        "ASSIGNROLE": "",
        "INSTRUCTIONS": "",
        "ROLE": "",
        "USER": "",
        "PRODUCTLINERESPONSIBLE": "",
        "KEYUSER": "",
        "EDITOR": "",
        "TRANSLATOR": "",
        "USEROVERVIEW": "",
        "ADDUSER": "",
        "DISCARD": "",
        "DOWNLOADSELECTEDCATALOG": "Sélectionnez la (s) langue (s) pour télécharger ",
        "DOWNLOADCATALOG": "Sélection Télécharger",
        "SENDREQUEST": "",
        "REQUESTMODELYEARCHANGE": "",
        "RELATEDMBVDATA": "",
        "I_ACCEPT_THE": "J’accepte les",
        "AND": "et la",
        "AGREE_AND_CONTINUE": "Accepter et continuer",
        "We are here to help you": "Comment est-ce que vous êtes là ?",
        "HEADERTITLE": "Véhicule utilitaire",
        "CONTACTUS": "Contactez-nous",
        "COMPLETECATALOG": "Catalogue complet",
        "CONVERSIONCATALOG": "Constructeur de carrosserie",
        "PRNO": "PR-NR",
        "within 24 hours": "Dans les 24 heures",
        "1 week": "1 Semaine",
        "1 month": "1 Mois",
        "1 year": "1 An",
        "DATE": "Date",
        "BUILDANDCONV": "Sur - et reconstruis",
        "ENTRIES_PER_PAGE": "Entrées par page",
        "DOWNLOAD_SELECTED": "Sélection Télécharger",
        "COMPLETE_CATALOG": "Catalogue complet",
        "CATALOGUE_NOT_CREATED": "Aucun catalogue pour {model_name} a été créé en {language}",
        "CONSTRUCTION_CONVERSION_NOT_CREATED":"Aucun catalogue disponible pour les carrosseries et les transformations",
        "REQ_NOT_PROCESSED": "En raison d’une erreur, la requête n’a pas pu être exécutée",
        "DISCLAIMER": "This software product contains software components that are subject to open source licenses.\n" +
            "              Listed below, you will find the open source software components that are used together with the copyright\n" +
            "              notices, the applicable licenses and the original license texts, which may include warranty disclaimers\n" +
            "              and an exclusion of liability (third party license documentation). The source code and other required\n" +
            "              materials for particular open source software components incorporated in this software product may be\n" +
            "              obtained from the software supplier.\n" +
            "              The software supplier will provide you with the respective source code and materials for a charge covering\n" +
            "              the cost of performing such distribution (such as the cost of media, and shipping) in accordance with the\n" +
            "              terms and conditions of the applicable licenses. You will receive more information from the\n" +
            "              [PRN-Wiki: VWAG R: HAN, PR-NR Wiki pr-nr.wiki.vwag.r.han@volkswagen.de] provided above.",
        "NO_PAGE_FOUND" : "Page non disponible",
        "NOT_AUTHORIZED": "Vous n’êtes pas autorisé à accéder à cette application",
     }
}
