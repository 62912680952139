import React, {Component} from 'react'
import {ArrowDown} from '@volkswagen-onehub/icons-core'
import {CTA, RadioButton} from '@volkswagen-onehub/components-core'
import {FormattedMessage} from 'react-intl';

require('./SortPRNumbers.css')

class SortPRNumbers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: 'prnumber-a-z'
    }
    this.handleOptionChange = this.handleOptionChange.bind(this)
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const sortOption = urlParams.get('sortOption');
    if(sortOption) {
      this.setState( {selectedOption:sortOption});
      this.props.changeSortOption(sortOption);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption !== this.state.selectedOption) {
      this.updateUrl(this.state.selectedOption);
    }
  }

  change() {
    var element = document.getElementById('sort-block')
    if (element.style.display === 'block') {
      element.style.display = 'none'
    } else {
      element.style.display = 'block'
    }
  }

  handleOptionChange(changeEvent) {
    const option = changeEvent.target.value
    this.setState({selectedOption: option}, () => {
      this.props.changeSortOption(option);
      this.updateUrl(option);
    });

  }

  updateUrl(selectedOption) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('sortOption',selectedOption);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, '', newUrl);

  }

  render() {
    return (
      <div className="outer float-end" id="sort-funtionality-prnumbers">
        <span onClick={this.change} className="sort-by">
          <FormattedMessage id="SORT">
            { translated=>
                <CTA
                    tag="button"
                    emphasis="secondary"
                    size="small"
                    onClick={() => undefined}
                    ariaLabel="aria label"
                    icon={<ArrowDown variant="small" />}
                >
                  {translated}
                </CTA>
            }
          </FormattedMessage>
        </span>
        <div id="sort-block" className="up-arrow">
          <ul className="unordered-list" onChange={this.handleOptionChange}>
            {this.props.radioList.map((element, idx) => {
              return (
                <li key={idx}>
                  <RadioButton value={element.id} checked={(element.id === this.props.option)} id={element.id} label={element.label} name="sorting-options" />
                </li>
              )
            }
            )}
          </ul>
        </div>
      </div>
    )
  }
}
SortPRNumbers.defaultProps = {
  label: "Sort by",
  radioList: [
    {
      id: "prnumber-a-z",
      label: "PR-No A-Z"
    },
    {
      id: "prnumber-z-a",
      label: "PR-No Z-A"
    },
    {
      id: "model-a-z",
      label: "Model A-Z"
    },
    {
      id: "model-z-a",
      label: "Model Z-A"
    }
  ]
}

export default SortPRNumbers
