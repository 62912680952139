import React, {Component} from 'react';
import {ApplicationContextConsumer} from "../../contextProvider/ApplicationContextProvider";
import Preview from "../Preview/Preview.component"
import LanguageEdit from '../LanguageEdit/LanguageEdit'
import {Container, ContainerPadding, ContainerWrap} from "@volkswagen-onehub/components-core";

require('./Form.css')

const urlParams = new URLSearchParams(window.location.search);
var pageParam = decodeURIComponent(urlParams.get('page'));

class Form extends Component {

  render() {
    return (
        <Container  wrap={ContainerWrap.always} padding={{left: ContainerPadding.dynamic0150,
            right: ContainerPadding.dynamic0150}}>
            <ApplicationContextConsumer>
              {({ pageNumber, totalResults, setSavedPRNDraft, status, appLanguage, setAppLanguage, setLanguageVersion, languageVersion}) =>
                <>
                    <LanguageEdit appLanguage={appLanguage} setAppLanguage={setAppLanguage} setLanguageVersion={setLanguageVersion} languageVersion={languageVersion}/>
                    <Preview match={this.props.match} pageNumber={pageNumber}
                      pageName = {pageParam}
                      totalResults={totalResults} status ={status}
                    setSavedPRNDraft ={setSavedPRNDraft}/>
                </>
              }
            </ApplicationContextConsumer>
        </Container>
    );
  }
}

export default Form;
