import {LOCALES} from '../locales'

export default {
    [LOCALES.GERMAN] : {
        'LOGOUT' : 'Abmelden',
        'LOGIN' : 'Anmeldung',
        "ALLPRNUMBERS" : 'Alle PR-Nummern',
        "SEARCH": "PR-Nummer und Titel durchsuchen…",
        "SHOW":"Anzeigen",
        "ENTRIES": "Einträge",
        "SORT": "Sortieren",
        "RECENTLYADDED": "Zuletzt hinzugefügte PR Nummern",
        "CATALOGS": 'Kataloge',
        'DOWNLOADOPTIONS' : 'Downloadoptionen',
        "CUSTOMCATALOG": 'Von Ihnen erstellte eigene Kataloge',
        "COOKIEPOLICY": 'Cookie-Richtlinie',
        "MODEL": 'Modell',
        "MODELYEAR": 'Modelljahr',
        "CATEGORY": 'Kategorie',
        "AUFUND": "Auf- und Umbauten",
        "TERMSOFUSE" : 'Nutzungsbedingungen',
        "PRIVACYPOLICY": "Datenschutzerklärung",
        "SITENOTICE":"Impressum",
        "THIRDPARTY":"Lizenzen Dritter",
        "SPRACHE": "Sprache",
        "PREVIEW": "Vorschau",
        "FNF": "Funktion und Merkmale",
        "ADVANTAGES": "Vorteile",
        "NOTE": "Hinweis",
        "STARTNEWPRN": 'Start / Neue PR-Number',
        "PRNUMBER": 'PR Nummer',
        "MBVTEXT": 'MBV-Text',
        "TITLE": 'Titel',
        'TARGETGROUP': "Zielgruppe",
        "ENTWURF": "Entwurf",
        "IN_FREIGABE_DE": "In Freigabe DE",
        "INUBERSETZUNG": "In Übersetzung",
        "UBERSETZT": "Übersetzt",
        "VEROFFENLICHT": "Veröffentlicht",
        "ARCHIVIERT": "Archiviert",
        "SPEICHERN": "Speichern",
        "GENINFO": "Allgemeine Informationen",
        "PREVIOUS": "Bisherige",
        "ADDDESC": "Beschreibung hinzufügen",
        "SUMMARY": "Zusammenfassung",
        "HOMEPAGE":"Startseite",
        "PRLIST": "PR-Nummernliste",
        "ADMINISTRATION": "Administration",
        "AUTH":"Berechtigungen",
        "CHANGEMODELYEAR": "Modelljahr ändern",
        "BACK": "Zurück",
        "CLOSEMENU":"Menü schließen",
        "DESCRIPTION": "Beschreibung",
        "ADDIMAGES": "Bilder hinzufügen",
        "REMOVEIMAGE": "Bild entfernen",
        "SHOWALL": "Alle anzeigen",
        "CLICKON": "Klicke auf",
        "BUTTONTOCREATE": "Schaltfläche zum Erstellen einer neuen PR-Nummer",
        "NOTIFICATIONS": "Benachrichtigungen",
        "QUICKLINKS": "Quicklinks",
        "ADMINROLE": "Administratorenrolle",
        "MEDIALIBRARY": "Medienbibliothek",
        "CLICKONMODELYEAR":"• Unten auf Modelljahr ändern klicken \n• Modell auswählen, an dem Sie Änderungen vornehmen möchten \n• Vorhandenes Modelljahr auf das gewünschte ändern",
        "ASSIGNSOURCES":"• Den verschiedenen Rollen und Fahrzeugreihen Ressourcen zuweisen \n• Ändern und Löschen der bestehenden Rollen durch Klicken",
        "ADDEDIT": "• Bilder und Videos hinzufügen, bearbeiten, löschen und benennen, die anschließend einer PR-Nummer hinzugefügt werden können",
        "RECEIVEDNEWPRN": "Sie haben neue PR-Nummern von erhalten: ",
        "SAVEFORMYSELF": "Speichern",
        "REJECT": "Ablehnen",
        "SENDDEAPP": "Freigabe anfordern",
        "SENDDETRANS": "Übersetzung anfordern",
        "SENDTRANSAPP": "Übersetzungen zur Genehmigung senden",
        "RELEASE": "Freigeben",
        "RESETDRAFT": "Auf Entwurf zurücksetzen",
        "DOWLOADZIPFILE": "Laden Sie die Zip-Datei zur Übersetzung herunter",
        "UPLOADTRANSLATEDFILES": "Laden Sie übersetzte Dateien hoch",
        "DRAGANDDROP": "Ihre übersetzten Dateien ihr",
        "VIEWPDF": "Als PDF anzeigen",
        "ASSIGNROLE": "Rolle zuweisen",
        "INSTRUCTIONS": "Produktlinienverantwortliche können PR-Nummern selbst erstellen und deutsche Versionen von PR-Nummern freigeben.",
        "ROLE": "Rolle",
        "USER": "Benutzer",
        "PRODUCTLINERESPONSIBLE": "Produktlinienverantwortliche",
        "KEYUSER": "Key User",
        "EDITOR": "Redakteure",
        "TRANSLATOR": "Übersetzer",
        "USEROVERVIEW": "Übersicht Benutzerrollen",
        "ADDUSER": "Hinzufügen",
        "DISCARD": "Abbrechen",
        "DOWNLOADSELECTEDCATALOG": "Wählen Sie die Sprache(n) zum Herunterladen aus",
        "DOWNLOADCATALOG": "Auswahl Herunterladen",
        "SENDREQUEST": "Anfrage senden",
        "REQUESTMODELYEARCHANGE": "Änderung des Modelljahres anfordern",
        "RELATEDMBVDATA": "Diese Modelljahresänderungsanforderung ist spezifisch für MBV-Daten",
        "I_ACCEPT_THE": "Ich akzeptiere die",
        "AND": "und die",
        "AGREE_AND_CONTINUE": "Zustimmen und weitermachen",
        "We are here to help you": "Wir sind für Sie da.",
        "HEADERTITLE": "Nutzfahrzeuge",
        "CONTACTUS": "Kontaktieren Sie uns",
        "COMPLETECATALOG": "Komplettkatalog",
        "CONVERSIONCATALOG": "Aufbauhersteller",
        "PRNO": "PR-NR",
        "within 24 hours": "Innerhalb von 24 Stunden",
        "1 week": "1 Woche",
        "1 month": "1 Monat",
        "1 year": "1 Jahr",
        "DATE": "Datum",
        "BUILDANDCONV": "Auf - und Umbauten",
        "ENTRIES_PER_PAGE": "Einträge pro Seite",
        "DOWNLOAD_SELECTED": "Ausgewählte Herunterladen",
        "COMPLETE_CATALOG": "Komplettkatalog",
        "CATALOGUE_NOT_CREATED": "Kein Katalog für {model_name} in {language} erstellt",
        "CONSTRUCTION_CONVERSION_NOT_CREATED":"Kein Katalog für Auf- und Umbauten verfügbar",
        "REQ_NOT_PROCESSED": "Aufgrund eines Fehlers konnte die Anfrage nicht ausgeführt werden",
        "DISCLAIMER": "Die vorliegende Software enthält Softwarekomponenten, die unter Open Source Lizenzen stehen.\n" +
            "Eine Auflistung der verwendeten Open Source Software-Komponenten inklusive Urheberrechtshinweise, der jeweils einschlägigen Lizenzen sowie der Lizenztexte, findet sich nachfolgend (Lizenzdokumentation). Die Lizenztexte können Gewährleistungs- und Haftungsausschlüsse beinhalten; die entsprechenden Ansprüche gegenüber dem Softwarelieferanten bleiben unberührt. Der Quellcode sowie weitere notwendige Materialien bestimmter Open Source Software-Komponenten können bei dem Softwarelieferanten der vorliegenden Software angefordert werden.\n" +
            "Der Softwarelieferant wird den Quellcode sowie die Materialien nach Maßgabe der jeweils einschlägigen Lizenzbedingungen auf Anfrage zur Verfügung stellen, wobei hierfür nur die Kosten der Bereitstellung (wie zum Beispiel die Kosten für Datenträger und die Versandkosten) berechnet werden. Unter der oben genannten [PRN-Wiki: VWAG R: HAN, PR-NR Wiki pr-nr.wiki.vwag.r.han@volkswagen.de] erhalten Sie weitere Informationen.",
        "NO_PAGE_FOUND": "Seite nicht verfügbar",
        "NOT_AUTHORIZED": "Sie sind nicht berechtigt, auf diese Anwendung zuzugreifen",
    }
}
