import React, {Component} from "react";
import {
    Breakpoints,
    Checkbox,
    CTA,
    Layout,
    Text,
    TextAlignment,
    TextAppearance,
} from "@volkswagen-onehub/components-core";
import {Bin,} from "@volkswagen-onehub/icons-core";
import {API, filterByDate, LocalStorage, titleCase, TranslatedByCategory} from "../../constants/PRNConstants";
import FilterSelectOption from "../FilterSelectOption/FilterSelectOption";
import {FormattedMessage} from 'react-intl';
import translate from "../../i18n/translate";

require("./FilterPRNumbers.css");

export class FilterPRNumbers extends Component {
    constructor(props) {
        super(props);

        const urlParams = new URLSearchParams(window.location.search);
        this.state = {
            showMenu: false,
            filterSortDate: urlParams.get('sortDate') || "",
            filterModel: urlParams.get('model') || "",
            filterModelYear: urlParams.get('modelYear') || "",
            filterCategory: urlParams.get('category') || "",
            filterStatus: urlParams.get('status') || "",
            filterAbh: urlParams.get('filterAbh') === 'true' || false,
            isDeleteFilterDisabled: true,
            isFilterStatusSelected: false,
            isFilterDateSelected: false,
            isFilterModelSelected: false,
            isFilterModelYearSelected: false,
            isFilterCategorySelected: false,
            isModelLoading: false
        };

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleConversionChange = this.handleConversionChange.bind(this);
        this.removeAllFilters = this.removeAllFilters.bind(this);
    }

    change(e) {
        this.fileInput.onInputChange();
    }

    componentDidMount () {
        this.fetchFilterOptionsForModelName()
        this.fetchFilterOptionsForModelYear()
        const { filterSortDate, filterModel, filterModelYear, filterCategory, filterStatus, filterAbh } = this.state;
        this.props.changeFilterDropdowns("filterSortDate", filterSortDate);
        this.props.changeFilterDropdowns("filterModel", filterModel);
        this.props.changeFilterDropdowns("filterModelYear", filterModelYear);
        this.props.changeFilterDropdowns("filterCategory", filterCategory);
        this.props.changeFilterDropdowns("filterStatus", filterStatus);
        this.props.changeFilterDropdowns("filterAbh", filterAbh);
    }

    setSelectedFilterState(name){
        switch(name){
            case "filterStatus":
                this.setState({
                    isFilterStatusSelected: true
                });
                break;
            case "filterSortDate":
                this.setState({
                    isFilterDateSelected: true
                });
                break;
            case "filterModel":
                this.setState({
                    isFilterModelSelected: true
                });
                break;
            case "filterModelYear":
                this.setState({
                    isFilterModelYearSelected: true
                });
                break;
            case "filterCategory":
                this.setState({
                    isFilterCategorySelected: true
                });
                break;
        }
    }

    handleDropdownChange(data, name) {
        this.setSelectedFilterState(name);
        this.setState({
            [name]: data,
            isDeleteFilterDisabled: false
        }, () => {
            this.props.changeFilterDropdowns(name, data);
            this.updateUrl();
        });
    }

    updateUrl() {
        const { filterSortDate, filterModel, filterModelYear, filterCategory, filterStatus, filterAbh } = this.state;
        const urlParams = new URLSearchParams(window.location.search);

        if (filterSortDate) urlParams.set('sortDate', filterSortDate);
        if (filterModel) urlParams.set('model', filterModel);
        if (filterModelYear) urlParams.set('modelYear', filterModelYear);
        if (filterCategory) urlParams.set('category', filterCategory);
        if (filterStatus) urlParams.set('status', filterStatus);
        urlParams.set('filterAbh', filterAbh.toString());
        const sortOption = urlParams.get('sortOption');
        if(sortOption) {
            urlParams.set('sortOption',sortOption);
        }

        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
    }
    generateDropdownOptions(option) {
        return option.map((category, key) => {
            return ({
                label: category,
                value: category
            })
        });
    }

    fetchFilterOptionsForModelYear = () => {
        fetch(API.EDITOR_SERVICE_ENDPOINT + `/api/prn-editor/distinct-model-years-fe`)
            .then(res =>
            {
                if(res.ok){
                    res.json().then(data=>{
                        this.setState({modelYearOptions: data});
                    });
                }
            });
    }

    fetchFilterOptionsForModelName() {
        this.setState({
            isModelLoading: true
        },()=>{
            fetch(API.EDITOR_SERVICE_ENDPOINT + `/api/prn-editor/distinct-model-names-fe`)
                .then(res => {
                    if (res.ok) {
                        res.json().then(data => {
                            this.setState({modelNameOptions: data});
                        });
                    }
                }).finally(()=>{
                this.setState({
                    isModelLoading: false
                });
            });
        });
    }

    handleConversionChange() {
        const abhValue = !this.state.filterAbh;
        this.setState(
            {
                filterAbh: abhValue,
                isDeleteFilterDisabled: !abhValue
            },
            () => {
                this.props.changeFilterDropdowns("filterAbh", this.state.filterAbh);
                this.updateUrl();
            }
        );
    }
    removeAllFilters() {
        this.setState(
            {
                filterSortDate: "",
                filterModel: "",
                filterModelYear: "",
                filterCategory: "",
                filterStatus: "",
                filterAbh: false,
                isDeleteFilterDisabled: true,
                isFilterStatusSelected: false,
                isFilterDateSelected: false,
                isFilterModelSelected: false,
                isFilterModelYearSelected: false,
                isFilterCategorySelected: false
            },
            () => {
                this.props.removeAllFilters();
            }
        );
    }
    render() {
        return (
            <Layout allowOverflowingContent appearance={{
                [Breakpoints.default]: [{
                    name: 'date',
                    columns: 9
                }, {
                    name: 'model',
                    columns: 3
                }, {
                    name: 'modelYear',
                    columns: 3
                }, {
                    name: 'category',
                    columns: 3
                }, {
                    name: 'conversion',
                    columns: 2
                }, {
                    name: 'delete',
                    columns: 2
                },
                    {
                        name: 'download',
                        columns: 2
                    }
                ]
            }} style={{paddingBottom:5}}>
                <FormattedMessage id="DATE">
                    {label=>
                        <FilterSelectOption
                            label={label}
                            value="filterSortDate"
                            isSelected={this.state.isFilterDateSelected}
                            filterValue={this.state.filterSortDate}
                            options={filterByDate}
                            handleDropdownChange={this.handleDropdownChange}
                        />
                    }
                </FormattedMessage>
                <FormattedMessage id="MODEL">
                    {label =>
                        <FilterSelectOption
                            label={label}
                            value="filterModel"
                            isLoading={this.state.isModelLoading}
                            filterValue={this.state.filterModel}
                            isSelected={this.state.isFilterModelSelected}
                            options={this.state.modelNameOptions ? this.generateDropdownOptions(this.state.modelNameOptions) : null}
                            handleDropdownChange={this.handleDropdownChange}
                        />
                    }
                </FormattedMessage>
                <FormattedMessage id="MODELYEAR">
                    {label =>
                        <FilterSelectOption
                            label={label}
                            value="filterModelYear"
                            isSelected={this.state.isFilterModelYearSelected}
                            filterValue={this.state.filterModelYear}
                            options={this.state.modelYearOptions ? this.generateDropdownOptions(this.state.modelYearOptions) : null}
                            handleDropdownChange={this.handleDropdownChange}
                        />
                    }
                </FormattedMessage>
                <FormattedMessage id="CATEGORY">
                    {label =>
                        <FilterSelectOption
                            label={label}
                            value="filterCategory"
                            isSelected={this.state.isFilterCategorySelected}
                            filterValue={this.state.filterCategory}
                            options={TranslatedByCategory[localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)]}
                            handleDropdownChange={this.handleDropdownChange}
                        />
                    }
                </FormattedMessage>
                <FormattedMessage id="BUILDANDCONV">
                    {label =>
                        <Checkbox
                            label={<Text appearance={TextAppearance.label0150}>{label}</Text>}
                            onChange={this.handleConversionChange}
                            className="dropdown-label-select"
                            checked={this.state.filterAbh}
                            value="required"/>
                    }
                </FormattedMessage>
                <Text textAlign={TextAlignment.right}>
                    <CTA
                        tag="button"
                        emphasis="secondary"
                        size="small"
                        disabled={this.state.isDeleteFilterDisabled}
                        onClick={() => this.removeAllFilters()}
                        ariaLabel="aria label"
                        icon={<Bin variant="small" />}
                    />
                </Text>
            </Layout>
        );
    }
}

export default FilterPRNumbers;
