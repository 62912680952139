import React, {Component} from 'react'
import {Language, LanguageMap, LocalStorage} from '../../constants/PRNConstants'
import Dropdown from '../../common_components/Dropdown/Dropdown';
import {ApplicationContextConsumer} from '../../contextProvider/ApplicationContextProvider'
import translate from "../../i18n/translate";
import {TextAppearance, TextColor} from "@volkswagen-onehub/components-core";

require('./LanguageEdit.css')

export class LanguageEdit extends Component {
    static contextType = ApplicationContextConsumer;

    retrieveLanguageVersion(languageVersion, languages) {
        return languages.find(Language => Language.value === languageVersion);
    }

    componentDidMount() {
        this.props.setLanguageVersion(LanguageMap[localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)]);
        if(!this.props.appLanguage){
            this.props.setAppLanguage(localStorage.getItem(LocalStorage.SELECTED_LANGUAGE));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.appLanguage !== this.props.appLanguage){
            this.props.setLanguageVersion(LanguageMap[this.props.appLanguage]);
        }
    }

    render() {
        return (
            <div className="language-edit-version">
                <Dropdown
                    Model={Language}
                    placeholder={translate("SPRACHE")}
                    labelAppearance={TextAppearance.headline200}
                    labelBold={true}
                    labelColor={TextColor.secondary}
                    handleDropdownChange={(data) => {
                        this.props.setLanguageVersion(data.value);
                    }}
                    name='Sprache'
                    dropdownValue={this.retrieveLanguageVersion(this.props.languageVersion, Language)}
                />
            </div>
        )
    }
}

export default LanguageEdit
