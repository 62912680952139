import React, {Component} from "react";
import {ThemeProvider} from '@volkswagen-onehub/components-core'
import translate from "../../../i18n/translate";
import {LocalStorage} from "../../../constants/PRNConstants";

require('../LegalPage.css')

export class TermsOfUse extends Component {

  fetchPageForDE = () => {
    return (
      <>
        <div class="legal-page-title"> {translate("TERMSOFUSE")} </div> <br />
        <div class="legal-page-content">
          Das PR-Nummern-Wiki (nachfolgend „Wiki“) wird von der Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, eingetragen im Handelsregister des Amtsgerichts Braunschweig unter der Nr. HRB 100484 (Volkswagen AG) angeboten (nachfolgend „Volkswagen AG“). <br /><br />
          Diese Nutzungsbedingungen regeln die Rechte und Pflichten während der Nutzung des Wikis.<br /><br />
          Bei dem Wiki handelt es sich um ein Vertriebs-Tool der Marke Volkswagen Nutzfahrzeuge (nachfolgend „VW Nutzfahrzeuge“) um Informationen und Dokumente der Marke Volkswagen Nutzfahrzeuge zu Modellen, Modellausstattungen und weiteres den Importeuren und Händlern zur Verfügung zu stellen.<br /><br />
          Die Nutzung des Wiki ist kostenlos und erfolgt nur nach Maßgabe und im Rahmen der nachfolgenden Bestimmungen.<br /><br />
          Soweit personenbezogene Daten erhoben und verarbeitet werden, geschieht dies ausschließlich im Rahmen der Datenschutzerklärung (Bitte Link einfügen).<br /><br />
        </div>
        <div class="legal-page-headline">1. Nutzungsberechtigte und Zugangsberechtigung</div><br /><br />
        <div class="legal-page-content">
          1.1       Nutzungsberechtigt sind ausschließlich registrierte Nutzer (nachfolgend „Nutzer“). Diese müssen bereits über einen Nutzeraccount im Group Retail Portal verfügen. Die Beantragung eines Nutzeraccounts erfolgt über das Group Retail Portal der Volkswagen AG.<br /><br />
          1.2       Die Nutzung des Wikis ist Personen vorbehalten, die in einem Vertragsverhältnis mit Volkswagen Nutzfahrzeuge stehen.<br /><br />
          1.3       Die Nutzer verpflichten sich, im Rahmen der Registrierung wahrheitsgemäße Angaben zu machen.<br /><br />
          1.4       Die Zugangsberechtigung für das Wiki ist nicht übertragbar. Der Nutzer darf seine Zugangsdaten nicht an andere Mitarbeiter oder Dritte weiterleiten oder diesen zugänglich machen.<br /><br />
          1.5        Die Nutzung des Wikis erfolgt freiwillig, kostenfrei und ausschließlich zu geschäftlichen Zwecken.<br /><br />
          1.6       Die Volkswagen AG ist jederzeit berechtigt, dem Nutzer die Zugangsberechtigung zu entziehen. Dies ist insbesondere der Fall, wenn der Nutzer gegen diese Nutzungsbedingungen verstößt. Mit Entziehung der Zugangsberechtigung darf der Nutzer das Wiki nicht mehr nutzen.<br /><br />
        </div>
        <div class="legal-page-headline">2. Funktionen und Zweck des Wikis</div><br /><br />
        <div class="legal-page-content">2.1        Nach dem sich der Nutzer in dem Group Retail Portal eingeloggt hat, hat er die Möglichkeit das Wiki zu nutzen. Der Nutzer hat die Möglichkeit durch die verschiedenen Ausstattungsvarianten zu navigieren und alle für ihn relevanten Dokumente herunterzuladen um sie für seine geschäftlichen Zwecke zu verwenden. Die Inhalte werden von Mitarbeitern von VW Nutzfahrzeuge erstellt und zu diesem Zwecke für die Nutzer zur Verfügung gestellt. </div><br />
        <div class="legal-page-headline">3. Nutzungsrecht und Verhaltenspflichten des Nutzers</div><br /><br />
        <div class="legal-page-content">3.1       Der Nutzer erhält das nicht-ausschließliche, nicht-übertragbare und nicht-unterlizenzierbare Recht, das Wiki oder Teile davon nach Maßgabe dieser Nutzungsbedingungen zu nutzen. <br /><br />
          3.2       Der Nutzer hat sicherzustellen, dass er nicht gegen geltende Rechtsvorschriften verstößt oder Rechte Dritter verletzt.<br /><br />
          3.3       Bei der Nutzung des Wikis sind Urheber-, Namens- und Markenrechte sowie sonstige Schutzrechte der Volkswagen AG sowie Rechte Dritter zu beachten. Geschützt sind unter anderem die von der Volkswagen AG präsentierten Bilder, Musik und Marken wie „Volkswagen“ und „Golf“. Durch die Abrufbarkeit der Webseite wird keine Lizenz oder ein sonstiges Nutzungsrecht hieran gewährt.<br /><br />
          3.4       Jede missbräuchliche Nutzung des Wikis ist zu unterlassen, insbesondere dürfen<br /><br />
          ·       keine Sicherheitsvorkehrungen umgangen werden,<br /><br />
          ·       keine Einrichtungen genutzt oder Anwendungen ausgeführt werden, die zu einer Beschädigung der Einrichtungen oder zu einem Funktionsausfall, insbesondere durch Veränderungen an der physikalischen oder logischen Struktur der Server oder des Netzes der Volkswagen AG oder anderer Netze, führen können,<br /><br />
          ·       Teile der Webseite nicht in andere Webangebote, weder private noch kommerzielle, integriert werden.</div><br /><br />
        <div class="legal-page-headline">4. Keine Übernahme einer Gewähr durch die Volkswagen AG für Inhalte der Website</div><br /><br />
        <div class="legal-page-content">Alle Angaben der Volkswagen AG auf der Webseite wurden sorgfältig geprüft. Eine Gewähr für die Vollständigkeit, Richtigkeit, Aktualität und ständige Verfügbarkeit der Webseite kann allerdings nicht übernommen werden. Sofern ein Rat oder eine Empfehlung auf der Webseite erteilt wird, ist die Volkswagen AG unbeschadet der sich aus einem Vertrag, einer unerlaubten Handlung oder einer sonstigen Bestimmung ergebenden Verantwortlichkeit zum Ersatz des aus der Befolgung des Rates oder der Empfehlung entstehenden Schadens nicht verpflichtet.</div><br /><br />
        <div class="legal-page-headline">5. Verantwortlichkeit der Volkswagen AG, Haftung</div><br /><br />
        <div class="legal-page-content">
          5.1     Die Volkswagen AG haftet nicht für den Inhalt von Webseiten Dritter. Die Volkswagen AG übernimmt daher keine Verantwortung für die Inhalte und die Verfügbarkeit von Webseiten Dritter, die über Links erreicht werden können. Die Volkswagen AG distanziert sich ausdrücklich von Inhalten, die möglicherweise straf- oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstoßen. Sobald die Volkswagen AG Kenntnis von rechtswidrigen Inhalten auf Webseiten Dritter erhält, wird sie den entsprechenden Link entfernen.<br /><br />
          5.2     Die Volkswagen AG ist nicht verantwortlich für Schäden, die dadurch entstehen, dass der Nutzer für das Benutzerkonto ein ungeeignetes Passwort gewählt oder das Passwort nicht sicher verwahrt hat oder für Schäden, die durch einen Dritten verursacht werden, dem der Nutzer den Zugriff auf das Wiki ermöglicht hat.<br /><br />
          5.3     Im Übrigen haftet die Volkswagen AG nach den folgenden Bestimmungen:<br /><br />
          a)       Hat die Volkswagen AG aufgrund der gesetzlichen Bestimmungen für einen Schaden aufzukommen, der leicht fahrlässig verursacht wurde, so haftet die Volkswagen AG beschränkt. Die Haftung besteht in diesem Fall nur bei Verletzung vertragswesentlicher Pflichten. Diese Haftung ist zudem auf den bei Vertragsabschluss vorhersehbaren typischen Schadens begrenzt.<br /><br />
          b)       Ausgeschlossen ist die persönliche Haftung der gesetzlichen Vertreter, Erfüllungsgehilfen und Betriebsangehörigen der Volkswagen AG für von ihnen durch leichte Fahrlässigkeit verursachte Schäden. Für von ihnen mit Ausnahme der gesetzlichen Vertreter und leitenden Angestellten durch grobe Fahrlässigkeit verursachte Schäden gilt die diesbezüglich für die Volkswagen AG in dieser Ziffer vorgesehene Haftungsbeschränkung entsprechend.<br /><br />
          c)        Die Haftungsbeschränkungen dieser Ziffer gelten nicht bei Verletzung von Leben, Kör-per oder Gesundheit. Unabhängig von einem Verschulden der Volkswagen AG bleibt eine etwaige Haftung bei arglistigem Verschweigen eines Mangels, aus der Übernahme einer Garantie oder eines Beschaffungsrisikos und nach dem Produkthaftungsgesetz unberührt.<br /><br />

        </div><br /><br />
        <div class="legal-page-headline">6. Änderungen am Wiki, Änderung dieser Bedingungen</div><br /><br />
        <div class="legal-page-content">
          6.1     Die Weiterentwicklung internetbasierter Dienstleistungen erfordern die Anpassung und Weiterentwicklung der betreffenden Dienste an neue technische Möglichkeiten sowie an geändertes Nutzerverhalten. Die Volkswagen AG behält sich das Recht vor, das Wiki technisch weiterzuentwickeln und dessen Funktionsumfang anzupassen. <br /><br />
          6.2     Die Volkswagen AG behält sich vor, diese Nutzungsbedingungen in einer für den Nutzer zumutbaren Weise und nur mit Wirkung für die Zukunft zu ändern.  Auf Änderungen der Nutzungsbedingungen werden die Nutzer ausdrücklich hingewiesen und um Zustimmung zu den geänderten Bedingungen gebeten. Die aktuell geltende Fassung der Nutzungsbedingungen muss von den Nutzern eingehalten werden.
        </div><br /><br />
        <div class="legal-page-headline">7. Schlussbestimmungen</div><br /><br />
        <div class="legal-page-content">
          7.1     Es gilt das Recht der Bundesrepublik Deutschland. Die Anwendung des Übereinkommens der Vereinten Nationen über Verträge über den Internationalen Warenkauf (CISG) ist ausgeschlossen.<br /><br />
          7.2     Ausschließlicher Gerichtsstand für sämtliche Ansprüche von Kaufleuten und juristischen Personen des öffentlichen Rechts aus oder im Zusammenhang mit dem Wiki ist das für die Volkswagen AG zuständige Gericht.<br /><br />
          7.3     Der Nutzer erklärt sich durch Nutzung des Wiki mit den vorstehenden Bestimmungen einverstanden.
        </div><br /><br />
        <div class="legal-page-headline">8. Streitbeilegung</div><br /><br />
        <div class="legal-page-content">
          Volkswagen ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch dazu verpflichtet.<br /><br />
          Stand: [05.01.2021]
        </div><br /><br />
      </>
    )
  }


  fetchPageForEN = () => {
    return (
      <>
        <div class="legal-page-title"> Terms of Use </div> <br />
        <div class="legal-page-content">
          "The PR number wiki (hereinafter “wiki”) is operated by Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, Germany, entered in the register of companies at Braunschweig District Court under HRB 100484 (“Volkswagen AG”). These Terms of Use govern rights and obligations when using the portal. <br /><br />
          The wiki is a sales tool for the Volkswagen Commercial Vehicles brand (“VW Commercial Vehicles”) to provide documents and information from Volkswagen Commercial Vehicles on vehicle models and equipment to importers, dealerships and employees of VW AG.<br /><br />
          The wiki is free to use in accordance with and subject to the following provisions.<br /><br />
          Any collection and processing of personal data occurs solely in line with the Privacy Policy.<br /><br />
        </div>
        <div class="legal-page-headline">1. Authorised users and access rights</div><br /><br />
        <div class="legal-page-content">
          1.1 Authorised users (hereinafter “users”) are those who are registered in the Group Retail Portal and assigned an appropriate role. User accounts are requested and activated via the Volkswagen AG Group Retail Portal. Employees of Volkswagen AG can also log in using a PKI card.  <br /><br />
          1.2 Access rights to the portal are not transferable. Users may not share their access data with other employees or third parties in any way.  <br /><br />
          1.3  The use of the portal is voluntary, free of charge and exclusively for business purposes<br /><br />
          1.4  Volkswagen AG reserves the right to revoke a user’s access rights at any time. This applies in particular if a user infringes these Terms of Use. Users may no longer use the portal once their access rights have been revoked.<br /><br />
        </div>
        <div class="legal-page-headline">2. Functions and purpose of the portal </div><br /><br />
        <div class="legal-page-content">2.1 The portal presents different equipment variants for vehicles, which users can download to their terminal devices as a PDF catalogue.  This information is intended to explain the functions and advantages of the equipment variants to users; in particular it can be used as an argumentation aid in sales talks.
          <br /><br />2.2  Information from the PR number wiki must not be passed on to end customers. </div><br />
        <div class="legal-page-headline">3. Rights of use and rules of conduct for users</div><br /><br />
        <div class="legal-page-content">3.1 The user is granted the non-exclusive, non-transferable and non-sublicensable right to use the portal or parts of it in accordance with these Terms of Use. In particular, the user undertakes not to copy or duplicate the content presented on the portal, nor to reproduce it in any other way or distribute it to third parties.<br /><br />
          3.2    Users must ensure that they do not violate any applicable laws or infringe the rights of third parties.  <br /><br />
          3.3   The copyright, names, trademarks and other property rights of Volkswagen AG and third parties must be observed when using the portal. Other protected elements include images, music and brand names such as “Volkswagen” and “Caddy” that are presented by Volkswagen AG. The accessibility of the website does not constitute the granting of a licence or other right of use.<br /><br />
          3.4   Any misuse of the portal is prohibited; in particular: <br /><br />
          No security measures may be circumvented <br /><br />
          No equipment may be used or programs run that could result in damage to or failure of the equipment, in particular as a result of modifications to the physical or logical structure of the servers or the network of Volkswagen AG or of other networks <br /><br />
          Parts of the website may not be integrated into other private or commercial websites " </div><br /><br />
        <div class="legal-page-headline">4. No acceptance of liability by Volkswagen AG</div><br /><br />
        <div class="legal-page-content"> All of the information provided on the website by Volkswagen AG has been carefully reviewed. However, the company cannot guarantee that the website is always accessible or constantly available. Volkswagen AG shall not be liable for any loss or damage incurred as a result of following advice or recommendations published on the website, without prejudice to responsibilities arising from an agreement, a tort or delict, or any other requirement. </div><br /><br />
        <div class="legal-page-headline">5. Responsibility of Volkswagen AG, liability</div><br /><br />
        <div class="legal-page-content">
          5.1   Volkswagen AG shall not be liable for the content of third-party websites.   Volkswagen AG expressly dissociates itself from content which may infringe on criminal or liability-related laws, or which could be considered contrary to accepted standards of public decency. If Volkswagen AG becomes aware of illegal content on third-party websites, it will remove the relevant link immediately.<br /><br />
          5.2   Volkswagen AG shall not be responsible for damage incurred as a result of the user having selected an unsuitable password for the user account or failing to protect the password securely, nor for damage caused by a third party whom the user has permitted to access the portal.  <br /><br />
          5.3   In other respects, Volkswagen AG shall be liable in accordance with the following provisions:<br /><br />
          a)   Volkswagen AG’s liability is limited in the event that Volkswagen AG is liable by law for damage or losses caused by ordinary negligence. In this case, liability only applies in the event of a breach of material contractual obligations. This liability shall also be limited to damage or losses which are foreseeable and typical at the time that the contract is concluded.<br /><br />
          b)   Personal liability of the legal representatives, vicarious agents and employees of Volkswagen AG for damage or losses caused by them through ordinary negligence is excluded. Volkswagen AG’s limitations of liability set out in this section apply accordingly to damage or losses caused by said persons, with the exception of the legal representatives and managers, by gross negligence.<br /><br />
          c)   The liability limitations in this section do not apply in the case of death, physical injury or damage to health. Irrespective of culpability on the part of Volkswagen AG, any liability due to fraudulent concealment of a fault, due to the giving of a guarantee or the assumption of a procurement risk, or in accordance with product liability law is unaffected. <br /><br />

        </div><br /><br />
        <div class="legal-page-headline">6. Changes to the portal, modification of these Terms of Use </div><br /><br />
        <div class="legal-page-content">
          6.1   Enhancements to web-based services require the relevant services to be adapted and enhanced from time to time in order to reflect new forms of technology or changes to user behaviour. Volkswagen AG reserves the right to make technical enhancements to the portal and to adapt its range of functions.<br /><br />
          6.2     Volkswagen AG reserves the right to make changes to these Terms of Use within reason for the user and only with future effect.  Users will be explicitly informed of changes to the Terms of Use and are requested to accept the modified conditions. Users must comply with whichever version of the Terms of Use is current and applicable.
        </div><br /><br />
        <div class="legal-page-headline">7. Final provisions </div><br /><br />
        <div class="legal-page-content">
          7.1 The law of the Federal Republic of Germany is applicable. The UN Convention on Contracts for the International Sale of Goods (CISG) shall not apply.<br /><br />
          7.2  The sole place of jurisdiction for the settlement of all claims of business people and legal entities under public law arising from or in relation to the portal is the court of jurisdiction for Volkswagen AG.<br /><br />
          7.3 By using the portal, the user confirms that they agree to the above provisions.
        </div><br /><br />
      </>
    )
  }

  fetchPageForES = () => {
    return (
      <>
        <div class="legal-page-title"> Condiciones de uso del sitio wiki de números PR</div> <br />
        <div class="legal-page-content">
          "El wiki de números PR (en adelante, ""wiki"") es un portal ofrecido por Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, inscrita en el Registro Mercantil del Juzgado de Primera Instancia de Braunschweig con número HRB 100484 (en adelante, ""Volkswagen AG""). Estas condiciones de uso regulan los derechos y las obligaciones durante el uso del portal.  <br /><br />
          El wiki es una herramienta de ventas de la marca Volkswagen Vehículos Comerciales (en adelante, ""VW Vehículos Comerciales"") que sirve para facilitar a los importadores, concesionarios y empleados de VW AG información y documentos de la marca Volkswagen Vehículos Comerciales relativos a modelos de vehículos y equipamientos de modelos de vehículos.  <br /><br />
          El uso del wiki es gratuito y solamente se realizará en conformidad y en el marco de las siguientes disposiciones. <br /><br />
          En el caso de que se recopilen y se traten datos personales, esto se efectuará exclusivamente en el marco de la Declaración sobre protección de datos.  "<br /><br />
        </div>
        <div class="legal-page-headline">1. Usuarios autorizados y derecho de acceso</div><br /><br />
        <div class="legal-page-content">
          1.1 Solo tendrán derecho a usar el wiki aquellos usuarios registrados en el Group Retail Portal que dispongan de una función adecuada para ello (en adelante, "usuarios"). La solicitud de una cuenta de usuario y su activación se lleva a cabo a través del portal Group Retail Portal de Volkswagen AG. Los empleados del Grupo Volkswagen también podrán iniciar sesión con una tarjeta PKI. <br /><br />
          1.2 El derecho de acceso al portal no es transferible. El usuario no puede transmitir sus datos de acceso a otros empleados o terceros ni facilitarles el acceso a los mismos.   <br /><br />
          1.3 El uso del portal es voluntario, gratuito y únicamente para fines profesionales. <br /><br />
          1.4 Volkswagen AG está autorizada en todo momento a privar al usuario del derecho de acceso. Esto ocurrirá, especialmente, si el usuario infringe las presentes condiciones de uso. Si se le priva del derecho de acceso, el usuario no podrá volver a utilizar el portal. <br /><br />
        </div>
        <div class="legal-page-headline">2. Funciones y finalidad del portal</div><br /><br />
        <div class="legal-page-content">2.1 En el portal se le muestran al usuario diversas variantes de equipamiento de los vehículos, que el usuario puede descargar en su terminal en forma de catálogo PDF.  Estos datos sirven para instruir a los usuarios sobre las funciones y las ventajas de las variantes de equipamiento. Especialmente, pueden usarse como apoyo de la argumentación en las entrevistas de venta.<br /><br />2.2 Está prohibido transmitir la información del wiki de números PR a los clientes finales.</div><br />
        <div class="legal-page-headline">3. Derecho de uso y obligaciones de comportamiento del usuario</div><br /><br />
        <div class="legal-page-content">
          3.1 Se concede al usuario el derecho gratuito, no exclusivo, no transmisible y no sublicenciable de usar el portal o partes del mismo conforme a las presentes condiciones de uso. El usuario se compromete, especialmente, a no copiar, duplicar, ni reproducir de cualquier otra forma, y a no transmitir, remitir ni distribuir a terceros los contenidos mostrados en el portal. <br /><br />
          3.2 El usuario debe asegurarse de no infringir las disposiciones legales aplicables ni los derechos de terceros.  <br /><br />
          3.3 Si se usa el portal, deben respetarse los derechos de autor, los derechos de denominación y de marca, así como otros derechos de protección de Volkswagen y de terceros. Están protegidas, entre otras, las imágenes presentadas por Volkswagen, la música y las marcas como "Volkswagen" y "Caddy". Que se pueda acceder al sitio web no implica la concesión de una licencia o de cualquier otro derecho de uso sobre el mismo.  <br /><br />
          "3.4 Todo uso indebido del portal deberá evitarse, en concreto,   <br /><br />
          no se eludirán las medidas de seguridad,  <br /><br />
          no se utilizarán equipos ni se ejecutarán aplicaciones que puedan ocasionar daños en las instalaciones o un fallo funcional, especialmente, debido a modificaciones de la estructura física o lógica de los servidores o de la red de Volkswagen AG u otras redes, <br /><br />
          no se integrarán partes del sitio web en otras plataformas web personales ni comerciales."</div><br /><br />
        <div class="legal-page-headline">4. Exención de responsabilidad por parte de Volkswagen AG </div><br /><br />
        <div class="legal-page-content"> Todos los datos de Volkswagen AG publicados en el sitio web han sido comprobados minuciosamente. Sin embargo, no es posible garantizar la accesibilidad y la disponibilidad permanente del sitio web. En el caso de que se exprese un consejo o una recomendación en el sitio web, Volkswagen AG no estará obligada a compensar por daños y perjuicios que resulten de haber seguido dicho consejo o recomendación, a pesar de la responsabilidad derivada de una relación contractual, de una actuación prohibida o de cualquier otra disposición.  </div><br /><br />
        <div class="legal-page-headline">5. Responsabilidad de Volkswagen AG</div><br /><br />
        <div class="legal-page-content">
          5.1 Volkswagen AG no se hace responsable del contenido de los sitios web de terceros.   Volkswagen AG se distancia expresamente de los contenidos que puedan tener relevancia penal, que afecten a la responsabilidad civil o atenten contra las buenas costumbres. En el momento en que Volkswagen AG tenga conocimiento de que se ha publicado contenido ilegal en el sitio web de un tercero, procederá a retirar el enlace en cuestión.  <br /><br />
          5.2 Volkswagen AG no se hace responsable de ningún daño producido por el hecho de que el usuario no elija una contraseña adecuada para la cuenta de usuario o no haya guardado la contraseña de forma segura, ni tampoco de los daños causados por terceros a los que el usuario haya facilitado el acceso al portal. <br /><br />
          5.3 Por lo demás, la responsabilidad de Volkswagen AG se rige por las disposiciones siguientes: <br /><br />
          a) Si en virtud de las disposiciones legales, Volkswagen AG debe responder por daños causados por negligencia leve, Volkswagen AG asumirá una responsabilidad limitada. En este caso, la responsabilidad se dará solo si se incumplen obligaciones fundamentales del contrato. Dicha responsabilidad está, además, limitada a los casos típicos previsibles en el momento de la celebración del contrato. <br /><br />
          b) Se excluye la responsabilidad personal de los representantes legales, auxiliares ejecutivos y miembros de la empresa de Volkswagen AG en caso de daños producidos por negligencia leve de dichas personas. En el caso de daños producidos por imprudencia temeraria por dichas personas, a excepción de por los representantes legales y el personal directivo, se aplicará, según corresponda, la limitación de responsabilidad prevista para Volkswagen AG en este apartado. <br /><br />
          c) Las limitaciones de responsabilidad de este apartado no son válidas en caso de daños o perjuicios que afecten a la vida, la integridad física o la salud. Independientemente de la posible culpabilidad de Volkswagen AG, su responsabilidad permanecerá inalterada en caso de ocultación intencionada de un desperfecto, ya sea por la asunción de una garantía o de un riesgo de adquisición y conforme a la Ley de responsabilidad por productos defectuosos alemana (Produkthaftungsgesetz).
        </div><br /><br />
        <div class="legal-page-headline">6. Modificaciones en el portal, modificaciones de las presentes condiciones</div><br /><br />
        <div class="legal-page-content">
          6.1    El desarrollo de servicios basados en Internet requiere que dichos servicios se adapten y se desarrollen conforme a las nuevas posibilidades técnicas y a los cambios de comportamiento de los usuarios. Volkswagen AG se reserva el derecho de continuar desarrollando técnicamente el portal y de adaptar el alcance de sus funcionalidades.  <br /><br />
          6.2     Volkswagen AG se reserva el derecho de modificar las presentes condiciones de uso de una forma razonable para el usuario y únicamente con efecto futuro.  En caso de modificar las condiciones de uso, se informará expresamente a los usuarios y se les solicitará la aceptación de las condiciones que hayan sido modificadas. Los usuarios deben respetar la versión vigente de las condiciones de uso.
        </div><br /><br />
        <div class="legal-page-headline">7. Disposiciones finales</div><br /><br />
        <div class="legal-page-content">
          7.1 Las presentes condiciones se rigen por el derecho de la República Federal de Alemania. Queda excluida la aplicación de la Convención de las Naciones Unidas sobre los contratos de compraventa internacional de mercancías (CISG). <br /><br />
          7.2 El fuero exclusivo para todas las reclamaciones de empresarios y personas jurídicas derivadas de y en relación con el uso del portal es el tribunal bajo cuya jurisdicción se encuentra Volkswagen AG. <br /><br />
          7.3 El usuario declara su conformidad con el uso del portal conforme a las disposiciones anteriores.
        </div><br /><br />
      </>
    )
  }


  fetchPageForFR = () => {
    return (
      <>
        <div class="legal-page-title"> Conditions d’utilisation du PR-NR Wiki </div> <br />
        <div class="legal-page-content">
          "Le PR-NR Wiki (ci-après « Wiki ») est proposé par Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg (ci-après « Volkswagen AG »), inscrite au registre du commerce du tribunal de Brunswick sous le numéro HRB 100484. Les présentes conditions d’utilisation régissent les droits et obligations pendant l’utilisation du portail.  <br /><br />
          Wiki est un outil de commercialisation de la marque Volkswagen Véhicules Utilitaires (ci-après « VW Véhicules Utilitaires ») destiné à fournir des informations et documents de la marque Volkswagen Véhicules Utilitaires sur des modèles de véhicules et équipements de modèles de véhicules aux importateurs, concessionnaires et collaborateurs de VW AG.<br /><br />
          L’utilisation du Wiki est gratuite et n’a lieu que conformément aux dispositions suivantes et dans le cadre de celles-ci. <br /><br />
          La collecte et le traitement éventuels de données à caractère personnel se déroulent exclusivement dans le cadre de la politique de confidentialité.  "<br /><br />
        </div>
        <div class="legal-page-headline"> Bénéficiaires et droit d’accès</div><br /><br />
        <div class="legal-page-content">
          1.1 Les bénéficiaires sont exclusivement les utilisateurs enregistrés dans le Group Retail Portal qui assument une fonction appropriée (ci-après « Utilisateur »). La demande de compte d’utilisateur et son activation s’effectuent via le Group Retail Portal de Volkswagen AG. Les collaborateurs de Volkswagen AG peuvent également se connecter à l’aide d’une carte PKI. <br /><br />
          1.2 L’autorisation d’accès au portail n’est pas transférable. L’utilisateur n’a pas le droit divulguer ses données d’accès à d’autres collaborateurs ou à des tiers ou de rendre celles-ci accessibles.   <br /><br />
          1.3 L’utilisation du portail est volontaire, gratuite et réservée à des fins exclusivement professionnelles. <br /><br />
          1.4 Volkswagen AG a le droit à tout moment de retirer l’autorisation d’accès à l’utilisateur. Ce sera notamment le cas si l’utilisateur ne respecte pas les présentes conditions d’utilisation. Le retrait de l’autorisation d’accès signifie que l’utilisateur n’a plus le droit d’utiliser le portail. <br /><br />
        </div>
        <div class="legal-page-headline">2. Fonctions et objet du portail</div><br /><br />
        <div class="legal-page-content">2.1Le portail présente à l’utilisateur diverses variantes d’équipement des véhicules que l’utilisateur peut télécharger sur son terminal sous la forme de catalogue PDF.  Ces informations permettent aux utilisateurs de comprendre les fonctions et avantages des variantes d’équipement et peuvent notamment servir d’argumentaire dans le cadre d’entretiens de vente.
          <br /><br />2.2 La transmission des informations du PR-NR Wiki à des clients finaux est interdite.</div><br />
        <div class="legal-page-headline">3. Droit d’utilisation et règles de conduite de l’utilisateur </div><br /><br />
        <div class="legal-page-content">
          3.1 L’utilisateur obtient le droit non-exclusif, non-transférable et non-cessible sous licence d’utiliser le portail ou des parties de celui-ci conformément aux présentes conditions d’utilisation. L’utilisateur s’engage en particulier à ne pas copier, dupliquer, reproduire autrement, divulguer ou transmettre ou diffuser les contenus montrés dans le portail. <br /><br />
          3.2 L’utilisateur doit s’assurer qu’il n’enfreint pas des prescriptions légales en vigueur ou des droits de tiers.  <br /><br />
          3.3 Le portail doit être utilisé dans le respect des droits d’auteur, des droits attachés aux noms commerciaux et aux marques commerciales, ainsi que d’autres droits de propriété de Volkswagen AG et de droits de tiers. Les images, musiques et marques présentées par Volkswagen AG, comme « Volkswagen » et « Caddy » entre autres sont également protégées. La disponibilité du site Internet ne permet d’octroyer aucune licence ni aucun autre droit d’utilisation.  <br /><br />
          "3.4 Toute utilisation abusive du portail doit être empêchée ; il est en particulier interdit  <br /><br />
          de prendre des mesures de sécurité,  <br /><br />
          d’utiliser des installations ou des applications susceptibles d’endommager les installations ou d’occasionner un dysfonctionnement, en particulier par des modifications de la structure physique ou logique du serveur ou du réseau de Volkswagen AG ou d’autres réseaux,  <br /><br />
          d’intégrer des parties du site Internet dans d’autres offres en ligne, à titre privé ou commercial. "<br /><br />
        </div><br /><br />
        <div class="legal-page-headline">4. Pas de reprise d’une garantie par Volkswagen AG</div><br /><br />
        <div class="legal-page-content">Toutes les informations de Volkswagen AG sur le site Internet ont été soigneusement vérifiées. Il est toutefois impossible de garantir l’accessibilité et la disponibilité permanente du site Internet. En cas de publication d’un conseil ou d’une recommandation sur le site Internet, la société Volkswagen AG n’est pas tenue de réparer les dommages dus à l’exécution du conseil ou de la recommandation, sans préjudice de la responsabilité résultant d’un contrat, d’une manipulation illicite ou d’une autre disposition. </div><br /><br />
        <div class="legal-page-headline">5. Responsabilité de Volkswagen AG</div><br /><br />
        <div class="legal-page-content">
          5.1 Volkswagen AG décline toute responsabilité quant au contenu de sites Internet de tiers.   Volkswagen AG se désolidarise expressément de contenus susceptibles d’avoir des conséquences légales ou pénales ou d’être contraires aux bonnes mœurs. Dès que Volkswagen AG prendra connaissance de contenus illicites sur des sites Internet de tiers, elle supprimera le lien correspondant.  <br /><br />
          5.2 Volkswagen AG décline toute responsabilité pour des dommages dus au fait que l’utilisateur a choisi un mot de passe inapproprié pour son compte d’utilisateur ou n’a pas conservé le mot de passe de manière sécurisée ou pour des dommages causés à un tiers que l’utilisateur avait autorisé à accéder au portail. <br /><br />
          5.3 Volkswagen AG est par ailleurs responsable conformément aux dispositions suivantes : <br /><br />
          a) Si Volkswagen AG doit répondre, en vertu des dispositions légales, d’un dommage dû à une négligence légère, sa responsabilité est limitée. La responsabilité n’existe dans ce cas qu’en raison du non-respect d’obligations essentielles au contrat. Cette responsabilité se limite en outre aux dommages typiques prévisibles lors de la conclusion du contrat. <br /><br />
          b) La responsabilité personnelle des représentants légaux, agents d’exécution et employés de Volkswagen AG est exclue pour des dommages occasionnés par une négligence légère de leur part. La limitation de responsabilité prévue dans ce paragraphe pour Volkswagen AG à cet égard s’applique en conséquence à des dommages occasionnés par une négligence grave des personnes énumérées ci-dessus, à l’exception des représentants légaux et des cadres supérieurs. <br /><br />
          c) Les limitations de responsabilité de ce paragraphe ne sont pas applicables en cas d’atteinte à la vie, à l’intégrité corporelle ou à la santé. Indépendamment d’une faute de Volkswagen AG, une responsabilité éventuelle demeure inchangée en cas de dissimulation frauduleuse d’un vice, résultant de la reprise d’une garantie ou d’un risque d’acquisition et en vertu de la loi sur la responsabilité des produits. <br /><br />
        </div><br /><br />
        <div class="legal-page-headline">6. Modifications du portail, amendement de ces conditions</div><br /><br />
        <div class="legal-page-content">
          6.1  Le développement continu de services basés sur Internet nécessite l’adaptation et le développement continu de ces services aux nouveautés techniques, ainsi qu’à un comportement modifié des utilisateurs. Volkswagen AG se réserve le droit de continuer à développer le portail du point de vue technique et d’en adapter les fonctions.<br /><br />
          6.2  Volkswagen AG se réserve le droit d’amender les conditions d’utilisation d’une manière acceptable pour l’utilisateur et uniquement avec effet pour l’avenir.  Les utilisateurs sont expressément informés d’amendements des conditions d’utilisation et priés d’approuver les conditions amendées. Les utilisateurs doivent respecter la version actuellement en vigueur des conditions d’utilisation.
        </div><br /><br />
        <div class="legal-page-headline">7. Dispositions finales</div><br /><br />
        <div class="legal-page-content">
          7.1 Le droit de la République fédérale d’Allemagne est applicable. L’application de la Convention des Nations unies sur les contrats de vente internationale de marchandises (CVIM) est exclue. <br /><br />
          7.2 Le lieu de juridiction exclusif pour l’ensemble des revendications de commerçants et de personnes morales de droit public résultant du portail ou en relation avec celui-ci est le tribunal compétent pour Volkswagen AG. <br /><br />
          7.3 En utilisant le portail, l’utilisateur accepte les présentes dispositions.
        </div><br /><br />
      </>
    )
  }

  fetchPageForPT = () => {
    return (
      <>
        <div class="legal-page-title"> Condições de utilização para o PR-NR Wiki </div> <br />
        <div class="legal-page-content">
          "O PR-NR Wiki (doravante ""Wiki"") é disponibilizado pela Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, registada no Registo Comercial do Tribunal da Comarca de Braunschweig com o n.º HRB 100484 (Volkswagen AG) (doravante ""Volkswagen AG""). As presentes condições de utilização regulam os direitos e deveres durante a utilização do portal.  <br /><br />
          O Wiki consiste numa ferramenta de distribuição da marca Volkswagen Nutzfahrzeuge (doravante ""VW Nutzfahrzeuge"") para disponibilização de informações e documentos da marca Volkswagen Nutzfahrzeuge relativos a modelos de veículos e equipamentos de modelos de veículos aos importadores, concessionários e funcionários da VW AG.  <br /><br />
          A utilização do Wiki é gratuita e apenas de acordo com as seguintes disposições. <br /><br />
          Caso sejam recolhidos e tratados dados pessoais, tal é realizado exclusivamente no âmbito da Declaração de Privacidade.  "<br /><br />
        </div>
        <div class="legal-page-headline">1. Utilizadores autorizados e autorização de acesso </div><br /><br />
        <div class="legal-page-content">
          1.1 Os utilizadores autorizados são exclusivamente utilizadores registados no Group Retail Portal que dispõem de uma função adequada (doravante "Utilizador(es)") O pedido de uma conta de utilizador e a sua disponibilização é realizado através do Group Retail Portal da Volkswagen AG. Além disso, os funcionários da Volkswagen AG podem iniciar sessão com um cartão PKI. <br /><br />
          1.2 A autorização de acesso ao portal não é transmissível. O utilizador não pode disponibilizar ou tornar acessíveis os seus dados de acesso a outros funcionários ou a terceiros.   <br /><br />
          1.3 A utilização do portal é voluntária, gratuita e apenas para fins comerciais. <br /><br />
          1.4 A Volkswagen AG tem o direito de, a qualquer altura, revogar os direitos de acesso do utilizador. Isto acontece, sobretudo, quando o utilizador infringe as presentes condições de utilização. Com a revogação dos direitos de acesso, o utilizador deixa de poder continuar a utilizar o portal.<br /><br />
        </div>
        <div class="legal-page-headline">2. Funções e finalidade do portal</div><br /><br />
        <div class="legal-page-content">2.1 No portal, são apresentadas ao utilizador diferentes variantes de equipamentos dos veículos, que o utilizador poderá descarregar como catálogo PDF no seu dispositivo terminal.  Estas informações destinam-se a explicar aos utilizadores as funções e vantagens das variantes de equipamento e, nomeadamente, podem ser utilizadas como auxiliar de argumentação em diálogos de vendas.
          <br /><br />2.2  A transmissão das informações do PR-NR-Wiki a clientes finais é proibida. </div><br />
        <div class="legal-page-headline">3. Direito de utilização e normas de conduta do utilizador</div><br /><br />
        <div class="legal-page-content">3.1 O utilizador recebe o direito não exclusivo, intransferível e não sublicenciável de utilizar o portal ou partes do mesmo, de acordo com as presentes condições de utilização. O utilizador obriga-se, em particular, a não copiar, duplicar, reproduzir de qualquer outra forma, a não ceder, transmitir ou divulgar os conteúdos apresentados. <br /><br />
          3.2 O utilizador deve assegurar que não viola disposições legais aplicáveis ou os direitos de terceiros.  <br /><br />
          3.3 Ao utilizador o portal, devem ser respeitados os direitos de autor, direitos de nome e de marcas, outros direitos de propriedade da Volkswagen AG, bem como os direitos de terceiros. Entre outros, estão protegidas as imagens, músicas e marcas da Volkswagen AG, como "Volkswagen" e "Caddy". A acessibilidade do website não confere qualquer licença ou outro direito de utilização.  <br /><br />
          "3.4 Deve ser proibida qualquer utilização indevida do portal. Em especial, não é permitido<br /><br />
          contornar quaisquer medidas de segurança,  <br /><br />
          utilizar dispositivos ou aplicações que possam causar danos aos dispositivos ou avarias funcionais, nomeadamente devido a alterações na estrutura física ou lógica do servidor, da rede da Volkswagen AG ou outras redes,  <br /><br />
          integrar partes do website em outras ofertas online, privadas ou comerciais. "<br /><br /></div>
        <div class="legal-page-headline">4. A Volkswagen AG não assume qualquer garantia</div><br /><br />
        <div class="legal-page-content"> Todas as informações da Volkswagen AG no website foram cuidadosamente verificadas. No entanto, não é possível garantir a acessibilidade e disponibilidade permanente do website. Se for dado um conselho ou recomendação no website, a Volkswagen AG não é obrigada a compensar por danos resultantes do cumprimento do conselho ou recomendação, independentemente da responsabilidade decorrente de um contrato, de um ato não permitido ou qualquer outra disposição. </div><br /><br />
        <div class="legal-page-headline">5. Responsabilidade da Volkswagen AG </div><br /><br />
        <div class="legal-page-content">
          5.1 A Volkswagen AG não se responsabiliza pelo conteúdo de websites de terceiros.   A Volkswagen AG distancia-se expressamente de conteúdos que possam ser relevantes em termos da legislação penal ou em matéria de responsabilidade ou que sejam contrários aos bons costumes. Assim que a Volkswagen AG tomar conhecimento de conteúdos ilegais em websites de terceiros, procederá à remoção da hiperligação correspondente.  <br /><br />
          5.2 A Volkswagen AG não se responsabiliza por danos causados pelo facto de o utilizador ter escolhido uma palavra-passe inadequada para a conta de utilizador, por não ter guardado a palavra-passe de forma segura ou por danos causados​por terceiros a quem o utilizador tenha permitido acesso ao portal. <br /><br />
          5.3 Além disso, a Volkswagen AG é responsável de acordo com as seguintes disposições: <br /><br />
          a) Se a Volkswagen AG for responsável por danos causados​por negligência ligeira com base nas disposições legais, a responsabilidade da Volkswagen AG é limitada. Neste caso, só existe responsabilidade em caso de violação das obrigações contratuais essenciais. Além disso, esta responsabilidade é limitada pelos danos típicos previsíveis aquando da celebração do contrato. <br /><br />
          b) Está excluída a responsabilidade pessoal dos representantes legais, agentes indiretos e funcionários da Volkswagen AG por danos causados pelos mesmos por negligência ligeira. Aos danos causados por negligência grave, com exceção dos representantes legais e dirigentes, aplica-se a limitação de responsabilidade da Volkswagen AG prevista no presente ponto. <br /><br />
          c) As limitações de responsabilidade do presente ponto não se aplicam a danos à vida, corpo ou saúde. Independentemente de qualquer responsabilidade da Volkswagen AG, não é afetada uma eventual responsabilidade em caso de ocultação fraudulenta de um defeito, da assunção de uma garantia ou de um risco de aquisição e no âmbito do previsto na Lei de Responsabilidade do Produto.<br /><br />
        </div><br /><br />
        <div class="legal-page-headline">6. Alterações no portal, alterações das presentes condições</div><br /><br />
        <div class="legal-page-content">
          6.1  O desenvolvimento de serviços com base na internet requer a adaptação e desenvolvimento dos respetivos serviços a novas possibilidades técnicas e de acordo com as alterações no comportamento dos utilizadores. A Volkswagen AG reserva-se o direito de desenvolver tecnicamente o portal e a adaptar o respetivo âmbito funcional. <br /><br />
          6.2  A Volkswagen AG reserva-se o direito de alterar as presentes condições de utilização de forma razoável para o utilizador e apenas com efeitos futuros.  Os utilizadores serão expressamente advertidos para alterações nas condições de utilização e ser-lhes-á solicitado o seu consentimento das condições alteradas. Os utilizadores devem respeitar a versão atualmente válida das condições de utilização.
        </div><br /><br />
        <div class="legal-page-headline">7. Disposições finais</div><br /><br />
        <div class="legal-page-content">
          7.1 A legislação aplicável é a da República Federal da Alemanha. Está excluída a aplicação das disposições da convenção das Nações Unidas para a venda internacional de mercadorias (CISG). <br /><br />
          7.2 A jurisdição exclusiva para todas as reivindicações de comerciantes e entidades jurídicas de direito público decorrentes ou relacionadas com o portal é o tribunal responsável pela Volkswagen AG. <br /><br />
          7.3 Com a utilização do portal, o utilizador declara concordar com as disposições anteriores.
        </div><br /><br />
      </>
    )
  }

  fetchPageForIT = () => {
    return (
      <>
        <div class="legal-page-title"> Condizioni di utilizzo del PR-NR Wiki </div> <br />
        <div class="legal-page-content">
          "Il PR-NR Wiki (d’ora in avanti “Wiki”) è una proposta della Volkswagen AG, Berliner Ring 2, 38440 Wolfsburg, iscritta nel registro delle imprese del tribunale di Braunschweig con il numero HRB 100484 (Volkswagen AG) (d’ora in avanti “Volkswagen AG”). Le presenti condizioni di utilizzo regolamentano i diritti e i doveri di chi utilizza il portale.  <br /><br />
          Il Wiki rappresenta uno strumento di commercializzazione della marca Volkswagen Veicoli Commerciali (d’ora in avanti “VW Veicoli Commerciali”) volto a mettere informazioni e documenti sui modelli e gli allestimenti della marca Volkswagen Veicoli Commerciali a disposizione di importatori, concessionarie e dipendenti della Volkswagen AG.  <br /><br />
          L’uso del Wiki è gratuito ed è regolamentato dalle seguenti disposizioni. <br /><br />
          L’eventuale raccolta e il trattamento di dati personali si svolgono esclusivamente in conformità alla dichiarazione sul trattamento dei dati personali.  "<br /><br />
        </div>
        <div class="legal-page-headline">1.  Utenti autorizzati e diritto di accesso </div><br /><br />
        <div class="legal-page-content">
          1.1 Gli utenti autorizzati sono esclusivamente le persone registrate nel Group Retail Portal che dispongono di un determinato ruolo (d’ora in avanti “utenti”). La richiesta di apertura di un account e l’abilitazione hanno luogo attraverso il Group Retail Portal della Volkswagen AG. I dipendenti della Volkswagen AG possono inoltre accedere con una scheda PKI. <br /><br />
          1.2 Il diritto di accesso al portale non è trasferibile. All’utente non è permesso mettere i suoi dati a disposizione di altri dipendenti o di terzi né renderli loro accessibili.   <br /><br />
          1.3 L’uso del portale ha luogo su base volontaria, è gratuito e destinato a scopi esclusivamente professionali. <br /><br />
          1.4 La Volkswagen AG ha in qualsiasi momento la facoltà di revocare all’utente il diritto di accesso. Questo caso si ha in particolare quando l’utente non rispetta le condizioni di utilizzo. Una volta che all’utente sia stato revocato il diritto di accesso, non gli sarà più consentito utilizzare il portale. <br /><br />
        </div>
        <div class="legal-page-headline">2. Funzioni e finalità del portale </div><br /><br />
        <div class="legal-page-content">2.1 Nel portale vengono mostrati all’utente diversi allestimenti dei veicoli che egli può scaricare come catalogo PDF sul suo dispositivo mobile.  Queste informazioni mirano a spiegare agli utenti le funzioni e i vantaggi degli allestimenti e, in particolare, possono essere impiegate come argomenti nei colloqui di vendita.<br /><br />2.2 Non è consentito mettere a disposizione di clienti finali le informazioni disponibili sul PR-NR-Wiki.</div><br />
        <div class="legal-page-headline">3. Diritto di utilizzo e obblighi di comportamento dell’utente</div><br /><br />
        <div class="legal-page-content">3.1 L’utente acquisisce il diritto non esclusivo, non trasferibile e non concedibile in sublicenza, di utilizzare il portale o parti di esso conformemente alle presenti condizioni di utilizzo. In particolare l’utente si impegna a non copiare, duplicare, riprodurre in altro modo, cedere a terzi, inoltrare o diffondere i contenuti presentati sul portale. <br /><br />
          3.2 L’utente deve assicurarsi di non violare le norme di legge vigenti né ledere i diritti di terzi.  <br /><br />
          3.3 Quando si utilizza il portale, si devono rispettare i diritti d'autore, i diritti su nomi e marchi nonché tutti gli altri diritti propri della Volkswagen AG e di terzi. Sono protetti, tra le altre cose, immagini, musica e marchi come "Volkswagen" e "Caddy" presentati dalla Volkswagen AG. Data l’accessibilità del sito web, non viene concessa nessuna licenza né altri diritti di utilizzo.  <br /><br />
          "3.4 È vietato qualsiasi uso illecito del portale, in particolare non è consentito<br /><br />
          eludere le misure di sicurezza,<br /><br />
          utilizzare dispositivi o applicazioni che possano causare danni ai dispositivi o guasti, in particolare in seguito a modifiche alla struttura fisica o logica dei server o della rete della Volkswagen AG o di altre reti,<br /><br />
          integrare parti del sito web in altre offerte web, siano esse di carattere privato o commerciale. "</div><br /><br />
        <div class="legal-page-headline">4. Nessuna garanzia da parte della Volkswagen AG</div><br /><br />
        <div class="legal-page-content"> Tutti i dati pubblicati dalla Volkswagen AG sul sito web sono stati verificati accuratamente. Tuttavia non è possibile garantire che il sito web sia sempre accessibile e disponibile. Se viene dato un consiglio o una raccomandazione sul sito web, la Volkswagen AG non è obbligata a risarcire i danni che eventualmente possono derivare dal fatto che l’utente abbia seguito uno di tali consigli o una di tali raccomandazioni, indipendentemente dalla responsabilità derivante da un contratto, un'azione non consentita o una norma di legge.</div><br /><br />
        <div class="legal-page-headline">5. Responsabilità della Volkswagen AG, responsabilità civile </div><br /><br />
        <div class="legal-page-content">
          5.1 La Volkswagen AG declina qualsiasi responsabilità per contenuti di siti web di terzi.   La Volkswagen AG prende espressamente le distanze da quei contenuti che potrebbero essere rilevanti in termini di responsabilità civile o perseguibili penalmente oppure contrari al buon costume. Nel momento in cui la Volkswagen AG verrà a conoscenza di contenuti illegali su siti web di terzi, rimuoverà il link in questione.
          5.2 La Volkswagen AG non risponde dei danni derivanti dal fatto che l’utente abbia scelto per l’account una password inadeguata o abbia conservato la password in modo non sicuro né dei danni causati da terzi, ai quali l’utente abbia reso possibile l’accesso al portale.
          5.3 Nei rimanenti casi la Volkswagen AG si assume la responsabilità secondo le seguenti disposizioni:
          a) La responsabilità della Volkswagen AG è limitata, nella misura prevista dalla legge per danni dovuti a lieve negligenza. La responsabilità della Volkswagen AG sussiste in questo caso solo se è riscontrabile una violazione di importanti obblighi contrattuali. Tale responsabilità si limita inoltre ai danni tipici e prevedibili alla stipula del contratto.
          b) È esclusa la responsabilità personale dei rappresentanti legali, dei collaboratori e di altri ausiliari della Volkswagen AG per danni da loro causati per lieve negligenza. Per danni da loro provocati per negligenza grave, ad esclusione dei rappresentanti legali e dei dirigenti, è valida la limitazione della responsabilità della Volkswagen AG prevista in questo punto.
          c) Le limitazioni della responsabilità previste in questo punto non si applicano in caso di aggressioni contro la vita, il corpo o la salute. Indipendentemente dall’esistenza di eventuali colpe della Volkswagen AG, la responsabilità di questa resta salva anche quando si sottaccia deliberatamente un difetto, in caso di assunzione di una garanzia o di un rischio di acquisto e nei casi contemplati dalla legge sulla garanzia del prodotto.

        </div><br /><br />
        <div class="legal-page-headline">6.  Modifiche al portale, modifica delle presenti condizioni</div><br /><br />
        <div class="legal-page-content">
          6.1 L’evoluzione dei servizi basati su internet richiedono l’adeguamento e lo sviluppo dei servizi interessati per sfruttare le nuove opportunità tecniche e andare incontro al mutato comportamento degli utenti. La Volkswagen AG si riserva il diritto di sviluppare il portale dal punto di vista tecnico e di adeguare la gamma delle funzioni. <br /><br />
          6.2 La Volkswagen AG si riserva la facoltà di modificare le condizioni di utilizzo in modo accettabile per gli utenti e solo con effetto per il futuro.  Le modifiche delle condizioni di utilizzo saranno espressamente segnalate agli utenti, ai quali si chiederà di accettare le nuove condizioni. La versione attualmente in vigore delle condizioni di utilizzo deve essere rispettata dagli utenti.
        </div><br /><br />
        <div class="legal-page-headline">7. Disposizioni finali </div><br /><br />
        <div class="legal-page-content">
          7.1 Si applica il diritto della Repubblica Federale Tedesca. È esclusa l'applicazione della convenzione delle Nazioni Unite sui contratti di compravendita internazionale di merci (CISG). <br /><br />
          7.2 Il foro competente per qualsiasi rivendicazione di commercianti e persone giuridiche di diritto pubblico in relazione al portale è il tribunale competente per la Volkswagen AG. <br /><br />
          7.3 Utilizzando il portale, l’utente accetta implicitamente le suddette disposizioni.   <br /><br />
        </div><br /><br />
      </>
    )
  }


  render() {
    var languagePref = localStorage.getItem(LocalStorage.SELECTED_LANGUAGE);
    var termsOfUseCurrentLanguageContent;

    if (languagePref.toUpperCase() === 'EN') {
      termsOfUseCurrentLanguageContent = this.fetchPageForEN()
    }
    else if (languagePref.toUpperCase() === 'ES') {
      termsOfUseCurrentLanguageContent = this.fetchPageForES()
    }
    else if (languagePref.toUpperCase() === 'PT') {
      termsOfUseCurrentLanguageContent = this.fetchPageForPT()
    }
    else if (languagePref.toUpperCase() === 'IT') {
      termsOfUseCurrentLanguageContent = this.fetchPageForIT()
    }
    else if (languagePref.toUpperCase() === 'FR') {
      termsOfUseCurrentLanguageContent = this.fetchPageForFR()
    }
    else {
      termsOfUseCurrentLanguageContent = this.fetchPageForDE()
    }

    return (
      <ThemeProvider theme="main">
        <div className="container">
          <div className="legal-page">
            {termsOfUseCurrentLanguageContent}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default TermsOfUse;
