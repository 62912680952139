import {LOCALES} from '../locales'

export default {
    [LOCALES.ITALIAN] : {
        'LOGOUT' : 'Logout',
        'LOGIN' : 'Accedere',
        "ALLPRNUMBERS" : 'Tutti i numeri PR',
        "SEARCH": "Ricerca numero PR e titolo in corso...",
        "SHOW":"Indicazioni",
        "ENTRIES": "Visualizza voci",
        "SORT": "Ordina",
        "RECENTLYADDED": "Numeri PR aggiunti per ultimo",
        "CATALOGS": 'Cataloghi',
        'DOWNLOADOPTIONS' : 'Opzioni di download',
        "CUSTOMCATALOG": 'Cataloghi personalizzati creati da te',
        "COOKIEPOLICY": 'Direttiva sui cookie',
        "MODEL": 'Modello',
        "MODELYEAR": 'Anno modello',
        "CATEGORY": 'Categoria',
        "AUFUND": "Sovrastrutture e trasformazioni",
        "TERMSOFUSE" : 'Condizioni di utilizzo',
        "PRIVACYPOLICY": "Dichiarazione sul trattamento dei dati personali",
        "SITENOTICE":"Indicazioni redazionali",
        "THIRDPARTY":"Licenze conto terzi",
        "SPRACHE": "Lingua",
        "PREVIEW": "Anteprima",
        "FNF": "Funzione e caratteristiche",
        "ADVANTAGES": "Vantaggi",
        "NOTE": "Nota",
        "STARTNEWPRN": '',
        "PRNUMBER": '',
        "MBVTEXT": '',
        "TITLE": '',
        'TARGETGROUP': "",
        "ENTWURF": "",
        "IN_FREIGABE_DE": "",
        "INUBERSETZUNG": "",
        "UBERSETZT": "",
        "VEROFFENLICHT": "",
        "ARCHIVIERT": "",
        "SPEICHERN": "",
        "GENINFO": "",
        "PREVIOUS": "",
        "ADDDESC": "",
        "SUMMARY": "",
        "HOMEPAGE":"home page",
        "PRLIST": "Elenco numeri PR",
        "ADMINISTRATION": "",
        "AUTH":"",
        "CHANGEMODELYEAR": "",
        "BACK": "",
        "CLOSEMENU":"Menu Chiudi",
        "DESCRIPTION": "",
        "ADDIMAGES": "",
        "REMOVEIMAGE": "",
        "SHOWALL": "Visualizza tutto",
        "CLICKON": "Clicca su",
        "BUTTONTOCREATE": "pulsante per creare un nuovo numero PR",
        "NOTIFICATIONS": "Notifiche",
        "QUICKLINKS": "Link rapidi",
        "ADMINROLE": "",
        "MEDIALIBRARY": "",
        "CLICKONMODELYEAR":"",
        "ASSIGNSOURCES":"",
        "ADDEDIT": "",
        "RECEIVEDNEWPRN": "",
        "SAVEFORMYSELF": "",
        "REJECT": "",
        "SENDDEAPP": "",
        "SENDDETRANS": "",
        "SENDTRANSAPP": "",
        "RELEASE": "",
        "RESETDRAFT": "",
        "DOWLOADZIPFILE": "",
        "UPLOADTRANSLATEDFILES": "",
        "DRAGANDDROP": "",
        "VIEWPDF": "Visualizza come PDF",
        "ASSIGNROLE": "",
        "INSTRUCTIONS": "",
        "ROLE": "",
        "USER": "",
        "PRODUCTLINERESPONSIBLE": "",
        "KEYUSER": "",
        "EDITOR": "",
        "TRANSLATOR": "",
        "USEROVERVIEW": "",
        "ADDUSER": "",
        "DISCARD": "",
        "DOWNLOADSELECTEDCATALOG": "Selezionare le lingue da scaricare",
        "DOWNLOADCATALOG": "Scarica selezione",
        "SENDREQUEST": "",
        "REQUESTMODELYEARCHANGE": "",
        "RELATEDMBVDATA": "",
        "I_ACCEPT_THE": "Accetto le",
        "AND": "e l'",
        "AGREE_AND_CONTINUE": "Concordare e andare avanti",
        "We are here to help you": "Siamo lì per te.",
        "HEADERTITLE": "veicolo commerciale",
        "CONTACTUS": "contattaci",
        "COMPLETECATALOG": "Catalogo completo",
        "CONVERSIONCATALOG": "Culturisti",
        "PRNO": "Pr-NR",
        "within 24 hours": "Entro 24 ore",
        "1 week": "1 Settimana",
        "1 month": "1 Mese",
        "1 year": "1 Anno",
        "DATE": "Dattero",
        "BUILDANDCONV": "Costruzioni e conversioni",
        "ENTRIES_PER_PAGE": "Voci per pagina",
        "DOWNLOAD_SELECTED": "Scarica selezione",
        "COMPLETE_CATALOG": "Catalogo completo",
        "CATALOGUE_NOT_CREATED": "Nessun catalogo creato per {model_name} in {language}",
        "CONSTRUCTION_CONVERSION_NOT_CREATED":"Nessun catalogo disponibile per carrozzerie e modifiche",
        "REQ_NOT_PROCESSED": "En raison d’une erreur, la requête n’a pas pu être exécutée",
        "DISCLAIMER": "This software product contains software components that are subject to open source licenses.\n" +
            "              Listed below, you will find the open source software components that are used together with the copyright\n" +
            "              notices, the applicable licenses and the original license texts, which may include warranty disclaimers\n" +
            "              and an exclusion of liability (third party license documentation). The source code and other required\n" +
            "              materials for particular open source software components incorporated in this software product may be\n" +
            "              obtained from the software supplier.\n" +
            "              The software supplier will provide you with the respective source code and materials for a charge covering\n" +
            "              the cost of performing such distribution (such as the cost of media, and shipping) in accordance with the\n" +
            "              terms and conditions of the applicable licenses. You will receive more information from the\n" +
            "              [PRN-Wiki: VWAG R: HAN, PR-NR Wiki pr-nr.wiki.vwag.r.han@volkswagen.de] provided above.",
        "NO_PAGE_FOUND" : "Pagina non disponibile",
        "NOT_AUTHORIZED": "Non sei autorizzato ad accedere a questa applicazione",
    }
}
