import {
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerVerticalAlignment,
    ContainerWrap,
    CTA,
    Text,
    TextAppearance
} from "@volkswagen-onehub/components-core";
import React, {Component} from "react";
import {API, LocalStorage} from "../../constants/PRNConstants";
import translate from '../../i18n/translate';
import Catalog from "../Catalog/Catalog";
import OverviewTableElement from "../PRNOverviewTable/OverviewTableElement/OverviewTableElement";
import {Link} from "react-router-dom";
import {mainPagePaddingLeftRight} from "../../App";
import ContactUs from "../../common_components/ContactUs/ContactUs";
import SelectLoginLanguagePopup from "../../common_components/SelectLoginLanguagePopup/SelectLoginLanguagePopup";

export class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
        totalResults: 5,
        sortingOption: "lastModified",
        status: "VERÖFFENTLICHT",
        isPrNumbersLoading: false
    };
  }

    getPRNumbers() {
        this.setState({
            isPrNumbersLoading: true
        },()=>{
            const filterParams = {
                sortBy : this.state.sortingOption,
                status : this.state.status,
                currentPage : "1",
                currentPageCount : "5"
            };
            const filterString = this.encodeQueryData(filterParams);
            fetch(
                API.EDITOR_SERVICE_ENDPOINT +
                `/api/prn-editor/published-pr-numbers/?${filterString}`,
                {
                    method: "GET",
                }
            )
                .then((res) => {
                    if(res.ok){
                        res.json().then(data=>{
                            this.setState({
                                prNumbers: data.prnWikiListDTO
                            });
                        });
                    }
                })
                .finally(()=>{
                    this.setState({
                        isPrNumbersLoading: false
                    });
                });
        });
    }

  componentDidMount() {
    this.getPRNumbers();
  }
    encodeQueryData(data) {
        const ret = []
        for (let d in data) {
            if (data[d]) {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
            }
        }
        return ret.join('&')
    }

  render() {
    return (
          <Container wrap={ContainerWrap.always} gutter={ContainerGutter.dynamic0150}>
              {localStorage.getItem(LocalStorage.IS_LOGIN_LANG_NEEDED) === "true" ? <SelectLoginLanguagePopup/> : <></>}
              <Container gutter={ContainerGutter.dynamic0100} wrap={ContainerWrap.always} padding={{left: ContainerPadding.dynamic0150,right: ContainerPadding.dynamic0150}}>
              <Text bold appearance={TextAppearance.headline0400}>{translate("RECENTLYADDED")}</Text>
              <OverviewTableElement isLoading={this.state.isPrNumbersLoading} currentPage={1} totalResults={this.state.totalResults} prNumbers={this.state.prNumbers} hasOnlyLatest={true} />
              <Container
                gutter={ContainerGutter.static400}
                wrap={ContainerWrap.always}
              >
                <Link to="/pr-numbers">
                  <CTA tag="a" emphasis="primary">
                  {translate("SHOWALL")}
                </CTA>
                </Link>
              </Container>
              </Container>
              <Container stretchContent gutter={ContainerGutter.dynamic0100} wrap={ContainerWrap.always}>
                  <Container stretchContent verticalAlign={ContainerVerticalAlignment.center} padding={mainPagePaddingLeftRight}>
                      <Text bold appearance={TextAppearance.headline0350}>{translate("CATALOGS")}</Text>
                      <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                          <Link style={{textDecoration: "none"}} to='/catalog' >
                              <Text appearance={TextAppearance.label0150}><CTA emphasis="none" tag="a">{translate("SHOWALL")} -&gt;</CTA></Text>
                          </Link>
                      </Container>
                  </Container>
                  <Container padding={mainPagePaddingLeftRight}>
                      <Catalog isHomePage={true}/>
                  </Container>
              </Container>
              <ContactUs/>
          </Container>
    );
  }
}

export default HomePage;
