import {API, Category, Language, LocalStorage} from "../../constants/PRNConstants";

export function DownloadFile(response, fileName, fileType){
    response.blob().then((blob) => {
        let url = window.URL.createObjectURL(new Blob([blob], { type: fileType }));
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        a.remove();
    });
}
export async function download(stream,filename){
    const url = window.URL.createObjectURL(stream);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = filename+'.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export function Capitalize(value: string){
    if (!value) return value;
    let capitalizedValue = "";
    const valueArray = value.split(' ');
    valueArray.forEach(word=>{
        capitalizedValue = capitalizedValue.concat(word[0].toUpperCase()).concat(word.substr(1).toLowerCase()).concat(" ");
    })
    return capitalizedValue.trim();
}

export function GetCurrentLanguagePRNCategory(){
    return Category[Language.find(e => e.label.toLowerCase()===localStorage.getItem(LocalStorage.SELECTED_LANGUAGE)).value];
}

export function generateImageURL(key){
    return API.IMAGE_SERVICE_ENDPOINT + `/api/prn-wiki-image/get-image` + '?key=' + key;
}
