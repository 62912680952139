import React from 'react'

import {
    Container,
    ContainerGutter,
    ContainerVerticalAlignment,
    CTA,
    LayerWrapper,
    Text,
    TextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core'

import {Logo, Logout} from '@volkswagen-onehub/icons-core'
import styled from 'styled-components'
import {ApplicationContextConsumer} from '../../contextProvider/ApplicationContextProvider'
import {
    API,
    CloseNavigationMenu,
    FirstLevelNavigation,
    LegalLinks,
    LocalStorage,
    NavigationBackLevel, UserAuthCookies
} from "../../constants/PRNConstants";
import ReactNotification from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'
import {
    Direction,
    FlyoutFirstLevelMenuWithContext,
    MenuLink,
    NavigationLevelListLinkV2,
    OneHubLanguageSwitcher,
    OneHubLanguageSwitcherItem,
    OneHubLogoClaim,
    OneHubMenuButton,
    OneHubNavigationFlyout,
    OneHubNavigationTopBar
} from '@vwa/components-cms'
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {getUserDetailsObjectFromCookie} from "../../utils/UserDetailsAuthUtil";

const TopBarWrapper = styled.div`
  position: relative;
  z-index : 2;
`;
TopBarWrapper.displayName = 'TopBarWrapper';

class Header extends React.Component {
    static contextType = ApplicationContextConsumer;

    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
            mainNavigation: [],
            secondLevelNavigation: [],
            importantLinks: [],
            footerLinks: [],
            menu: [],
            selectedInnerIndex: undefined,
            selectedLanguage: this.props.appLanguage
        }

        this.renderMenu = this.renderMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.populateFooterLinks = this.populateFooterLinks.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.onClickInnerItem = this.onClickInnerItem.bind(this);
        this.languageSwitch = this.languageSwitch.bind(this);
    }

    componentDidMount() {
        this.populateMainNavigation();
        this.props.setAppLanguage(localStorage.getItem(LocalStorage.SELECTED_LANGUAGE));
    }

    renderMenu() {
        return [(
            <OneHubMenuButton onClick={this.handleOpen} />
        )];
    }

    mapToLink(menuItem) {
        return (
            <NavigationLevelListLinkV2
                key={menuItem.name}
                children={
                    <Link style={{textDecoration:'none',color:'black'}} to={menuItem.url} onClick={this.handleClose}>{menuItem.name}</Link>
                }
            >
            </NavigationLevelListLinkV2>

        )
    }

    onClickInnerItem(index) {
        if (index === this.state.selectedInnerIndex) {
            this.setState({
                selectedInnerIndex: undefined
            })
        } else {
            this.setState({
                selectedInnerIndex: index
            })
        }
    }

    renderRightSideItems() {
        return (
            <Container gutter={ContainerGutter.static200} verticalAlign={ContainerVerticalAlignment.center}>
                <CTA
                    tag="a"
                    emphasis="none"
                    disabled={!this.props.isLoggedIn}
                    onClick={this.props.handleLogout}>
                    <Logout/>
                </CTA>
                <Text appearance={TextAppearance.headline0200} bold>
                    {getUserDetailsObjectFromCookie(UserAuthCookies.USERNAME) ? getUserDetailsObjectFromCookie(UserAuthCookies.USERNAME).split(' ')[0] : ''}
                </Text>
            </Container>
        )
    }

    languageSwitch(language) {
        localStorage.setItem(LocalStorage.SELECTED_LANGUAGE, language);
        fetch(API.USER_SERVICE_ENDPOINT
            + `/api/prn-user/user-language-fe/${language}`,
            {method: 'PUT'}
        ).then((res) => {
                if(res.ok){
                    this.setState({
                        selectedLanguage: language
                    }, () => {
                        this.props.onLanguageChange(language);
                        this.props.setAppLanguage(language);
                    });
                    this.handleClose();
                }
            }
        );
    }

    renderLanguageSwitcher() {
        return (
            <OneHubLanguageSwitcher children={
                this.props.languageNavigation.map(language=>
                    <OneHubLanguageSwitcherItem isActive={language.key === this.state.selectedLanguage}>
                        <Text tag={TextTag.a} appearance={TextAppearance.label0150}>
                            <CTA tag="none" emphasis="none" onClick={() => this.languageSwitch(language.key)}>
                                {language.value}
                            </CTA>
                        </Text>
                    </OneHubLanguageSwitcherItem>
                )
            }/>
        )
    }

    handleOpen(e) {
        this.setState({
            isVisible: true
        })
    }

    handleClose(e) {
        this.setState({
            isVisible: false
        })
    }

    renderNavigationView() {
        return (
            <LayerWrapper>
                {this.state.isVisible && (
                    <OneHubNavigationFlyout
                        closeButtonLabel={CloseNavigationMenu}
                        backButtonLabel={NavigationBackLevel}
                        mainNavigationAreaContent={this.populateMainNavigation()}
                        imprintLinks={this.populateFooterLinks()}
                        languageSwitcher={this.renderLanguageSwitcher()}
                        onClose={this.handleClose}
                        direction={Direction.LTR}
                    />
                )}
            </LayerWrapper>
        )
    }

    populateMainNavigation() {
        return (
            <FlyoutFirstLevelMenuWithContext>
                {FirstLevelNavigation.map((item, index) => {
                            return (
                                <Link to={item.url} onClick={this.handleClose}>
                                    <MenuLink id={(index + 1)} key={(index + 1)}>
                                        {item.name}
                                    </MenuLink>
                                </Link>
                            )
                })}
            </FlyoutFirstLevelMenuWithContext>
        )
    }

    populateFooterLinks() {
        return [(
            <OneHubLanguageSwitcher>
                {LegalLinks.map((legalLink) =>
                    <OneHubLanguageSwitcherItem children={
                        <Link style={{ textDecoration: 'none'}} to={legalLink.href} onClick={this.handleClose}>
                            <Text tag={TextTag.a} appearance={TextAppearance.label0150}>
                                <CTA tag="none" emphasis="none">
                                    {legalLink.title}
                                </CTA>
                            </Text>
                        </Link>
                    }>
                    </OneHubLanguageSwitcherItem>
                )}
            </OneHubLanguageSwitcher>
        )]
    }

    render() {
        return (
            <TopBarWrapper>
                <LayerWrapper>
                    <Text bold appearance={TextAppearance.label0100}><ReactNotification/></Text>
                    <FormattedMessage id={"HEADERTITLE"}>
                        {headerTitle =>
                            <OneHubNavigationTopBar
                                menuButton={this.renderMenu()}
                                logo={<Logo variant="default"/>}
                                claim={<OneHubLogoClaim>{headerTitle.toString()}</OneHubLogoClaim>}
                                visibleRightItems={this.renderRightSideItems()}
                                hasTransparentBackground={false}
                                direction={Direction.LTR}
                                isExpanded={true}
                            />
                        }
                    </FormattedMessage>
                </LayerWrapper>
                {this.renderNavigationView()}
            </TopBarWrapper>
        )
    }
}

Header.defaultProps = {
    siteNavigation: [],
    quickLinks: [],
    headerLegalLinks: [],
    translations: {
        header: {
            LOGIN: "",
        }
    },
    languageNavigation: [{ key: 'de', value: 'Deutsch' }, { key: 'en', value: 'English' }, { key: 'es', value: 'Spanish' }, { key: 'it', value: 'Italian' },
        { key: 'pt', value: 'Portuguese' }, { key: 'fr', value: 'French' }
    ],

}

export default (Header)
