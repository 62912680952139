import React, {Component} from "react";
import {
    Breakpoints,
    Checkbox,
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    CTA,
    FixedWidthContainer,
    Image,
    Layout,
    RadioButton,
    Spinner,
    Text,
    TextAppearance,
    WhiteSpace
} from "@volkswagen-onehub/components-core";
import {API,} from "../../constants/PRNConstants";
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import translate from "../../i18n/translate";
import {ChevronDown, ChevronUp, Download} from "@volkswagen-onehub/icons-core";
import {download} from "../../common_components/Helper/Helper";

export class Catalog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            language: [],
            catalogType: [],
            downloadState: [],
            newDownloadState:[],
            isLoading: [],
            isHomePage: false,
            isCollapsed: [],
            catalogProductLineAndImages:[]
        }
    }
    componentDidMount(){
        this.getCatalogProductLineAndImage();

    }

    getCatalogProductLineAndImage=()=>{
        fetch(API.EDITOR_SERVICE_ENDPOINT + '/api/prn-editor/catalog-model-fe',{
            method:"GET"
        }
        ).then((res)=>{
            if(res.ok){
                res.json().then(data=>{
                    this.setState({
                        catalogProductLineAndImages:data
                    })                
                })
            }
        })
    }

    async downloadCatalog(model) {
        const isLoading = this.state.isLoading;
        const lang = this.state.language[model];
        const prnCatalog = {
            model: model,
            type: this.state.catalogType[model]
        }
        for (let i = 0; i < lang.length; i++) {
            isLoading[model] = true;
            this.setState(isLoading);
            const fileName = this.generateFileName(model,lang[i]);
            await fetch(API.IMAGE_SERVICE_ENDPOINT + `/api/prn-wiki-image/get-catalog-is/`+prnCatalog.model+`/`+prnCatalog.type + `/` + lang[i] + '?dealerAppFlag='+true, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response=>{return response.blob()})
                .then(blob=> download(blob,fileName))
                .finally(()=>{
                isLoading[model] = false;
                this.setState(isLoading);
            });
        }
    }

    generateFileName(model,language) {
        return "Report_"+model+"_"+language;
    }

    handleCatalogTypeChange(model, type) {
        let { catalogType } = this.state;
        catalogType[model] = type;
        this.setState({
            catalogType: catalogType
        });
        this.updateDownloadState(model);
    }

    handleLanguageChange(model, value) {
        const { language } = this.state;
            if (!language[model])
                language[model] = [];

            if (!language[model].includes(value)) {
                language[model].push(value);
                this.setState({
                    language: language
                })
            } else if (language[model].length > 0) {
                let index = language[model].indexOf(value)
                if (index !== -1) {
                    language[model].splice(index, 1);
                    this.setState({ language: language });
                }
            }
            this.updateDownloadState(model);
    }

    updateDownloadState(model) {
        const { language, catalogType, downloadState } = this.state;
        if (language[model] && language[model].length > 0 && catalogType[model] && catalogType[model] !== "") {
            downloadState[model] = true;
            this.setState({ downloadState: downloadState })
        } else if ((language[model] && language[model].length === 0) || !catalogType[model]) {
            downloadState[model] = false;
            this.setState({ downloadState: downloadState })
        }
    }

    onCollapse(model,isEnter){
        const isCollapsed = this.state.isCollapsed;
        isCollapsed[model] = isEnter;
        this.setState({
           isCollapsed
        });
    }

    renderCatalogs() {
        const catalogCard = [];
        this.state.catalogProductLineAndImages.forEach(model => {
            catalogCard.push(
                    <FixedWidthContainer columns={this.props.isHomePage?4:5}>
                        <Container gutter={ContainerGutter.dynamic0100} wrap={ContainerWrap.always}>
                        <Text bold appearance={TextAppearance.headline0250}>{model.productLineName}</Text>
                        <Image src={model.productLineImageUrl} alt={model.productLineName}/>
                        </Container>
                    <Accordion>
                        <Card style={{all:'revert', border:'1px solid'}}>
                            <Card.Header style={{all:'revert', height: '56px'}}>
                                <Accordion.Toggle eventKey="0" style={{all:'revert', width:'100%', height:'100%', border: 0, backgroundColor: 'transparent', textAlign: 'left', cursor: 'pointer'}}>
                                    <Container stretchContent padding={{left: ContainerPadding.static200, right: ContainerPadding.static200}}>
                                        <Text bold appearance={TextAppearance.headline0200}>{translate("DOWNLOADOPTIONS")}</Text>
                                        <Container horizontalAlign={ContainerHorizontalAlignment.flexEnd}>
                                        {this.state.isCollapsed[model.productLineName]?<ChevronUp/>:<ChevronDown/>}
                                        </Container>
                                    </Container>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse onExit={()=>this.onCollapse(model,false)} onEnter={()=>this.onCollapse(model,true)} eventKey="0">
                                <Card.Body>
                                    <Container wrap={ContainerWrap.always} gutter={ContainerGutter.static250}>
                                        <Container gutter={ContainerGutter.static200} wrap={ContainerWrap.always}>
                                        <RadioButton value="complete" label={translate("COMPLETE_CATALOG")} onClick={()=>this.handleCatalogTypeChange(model.productLineName,"complete")} name={model.productLineName}/>
                                            <RadioButton value="conversion" label={translate("BUILDANDCONV")} onClick={()=>this.handleCatalogTypeChange(model.productLineName,"conversion")} name={model.productLineName}/>
                                        </Container>
                                        <Container wrap={ContainerWrap.always} gutter={ContainerGutter.static200}>
                                            <Text bold appearance={TextAppearance.label0100} whiteSpace={this.props.isHomePage?WhiteSpace.preLine:WhiteSpace.normal}>{translate("DOWNLOADSELECTEDCATALOG")}</Text>
                                            <Layout appearance={{
                                                [Breakpoints.default]: [{
                                                    name: 'a',
                                                    columns: 8
                                                }, {
                                                    name: 'b',
                                                    columns: 8
                                                }, {
                                                    name: 'c',
                                                    columns: 8
                                                }]}}>
                                                                          <Checkbox value="DE" onClick={()=>this.handleLanguageChange(model.productLineName,"DE")} label="DE"/>
                                                <Checkbox value="EN" onClick={()=>this.handleLanguageChange(model.productLineName,"EN")} label="EN"/>
                                                <Checkbox value="ES" onClick={()=>this.handleLanguageChange(model.productLineName,"ES")} label="ES"/>
                                            </Layout>
                                            <Layout appearance={{
                                                [Breakpoints.default]: [{
                                                    name: 'a',
                                                    columns: 8
                                                }, {
                                                    name: 'b',
                                                    columns: 8
                                                }, {
                                                    name: 'c',
                                                    columns: 8
                                                }]}}>
                                                                    <Checkbox value="FR" onClick={()=>this.handleLanguageChange(model.productLineName,"FR")} label="FR"/>
                                                <Checkbox value="PT" onClick={()=>this.handleLanguageChange(model.productLineName,"PT")} label="PT"/>
                                                <Checkbox value="IT" onClick={()=>this.handleLanguageChange(model.productLineName,"IT")} label="IT"/>
                                            </Layout>
                                        </Container>
                                        <Container>
                                            <CTA emphasis="primary"
                                                 icon={this.state.isLoading[model]?<Spinner/>:<Download/>}
                                                 size={this.props.isHomePage?"small":"large"}
                                                 disabled={!this.state.downloadState[model.productLineName]}
                                                 onClick={()=>this.downloadCatalog(model.productLineName)}>
                                                {translate('DOWNLOAD_SELECTED')}
                                            </CTA>
                                        </Container>
                                    </Container>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    </FixedWidthContainer>
            );
        });
        return catalogCard;
    }

    render() {
        return (
            <Container gutter={ContainerGutter.dynamic0100}>
                {this.renderCatalogs()}
            </Container>
        );
    }
}

export default Catalog;
