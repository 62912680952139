import React from 'react';
import PreviewData from './PreviewDataComponent';
import {ApplicationContextConsumer} from '../../contextProvider/ApplicationContextProvider';
import {API, Language, LocalStorage} from '../../constants/PRNConstants'
import 'bootstrap/dist/css/bootstrap.css';
import './Preview.css'
import {Container} from "@volkswagen-onehub/components-core";

class Preview extends React.Component {
  static contextType = ApplicationContextConsumer;

  constructor(props) {
      super(props);
      this.state = {
          isDataLoading: false,
          packagePrNumbers:[]
      }
  }
  componentDidMount() {
      window.scrollTo({ top: 0, behavior: "auto" });
      this.getPRNData();
      this.getPRNumbersInsidePackage();
  }
  getPRNumbersInsidePackage() {
    const { prNumber, modelName, modelYear } = this.props.match.params;
  fetch(
    API.EDITOR_SERVICE_ENDPOINT + '/api/prn-editor/pr-numbers-in-package/' + prNumber + '/' + modelName + '/' + modelYear
  )
    .then((res) => {
      if(res.ok){
        res.json().then(data=>{
          this.setState({ packagePrNumbers: data });
        });
      }
    });
}

  getPRNData(){
      const languageVersion = this.getLanguageVersion();
      this.setState({
          isDataLoading: true
      },()=>{
          if (this.props.match !== undefined) {
              const { prNumber, modelName, modelYear } = this.props.match.params;
              const { setContextAPI } = this.context;
              if (prNumber && modelName && modelYear) {
                  fetch(API.EDITOR_SERVICE_ENDPOINT
                      + `/api/prn-editor/pr-number/published/${prNumber}/${modelName}/${modelYear}`+ `?languageVersion=`+languageVersion +`&dealerAppFlag=`+true,
                      { method: 'GET' }
                  ).then((res) => {
                          if (res.ok) {
                              res.json().then(data=>setContextAPI(data));
                          }
                      }).finally(()=>{
                      this.setState({
                          isDataLoading: false
                      });
                  });
              }
          }
      });
  }

    getLanguageVersion() {
        const val = Language.filter((l) => {
            return l.label
                .toLocaleUpperCase()
                .includes(
                    localStorage.getItem(LocalStorage.SELECTED_LANGUAGE).toUpperCase()
                );
        });
        return val[0].value;
    }

  render() {
    return (
        <Container stretchContent>
            <div>
        <ApplicationContextConsumer>
          {({ prNumber, modelYear, model, category, title, functions, languageVersion, advantages, notes, prnImages, status, setContentHtml }) =>
              <PreviewData
                prNumber={prNumber}
                model={model}
                modelYear={modelYear}
                category={category}
                functions={functions}
                notes={notes}
                advantages={advantages}
                prnImages={prnImages}
                title={title}
                languageVersion={languageVersion}
                setContentHtml={setContentHtml}
                status={status}
                packagePrNumbers = {this.state.packagePrNumbers} />
          
          
     }
        </ApplicationContextConsumer>
        
      </div>
        </Container>
    );
  }
}

export default Preview;
